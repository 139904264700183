import {
  archOptions,
  closedOptionsX,
  completeOptionsX,
  declrOptions,
  inmovingOptionsX,
  inprOptionsX,
  prepOptionsX,
} from "../../../Map/Common/Styles";
import {dataAPI} from "../../../../../api/api";
import L from "leaflet";
import {refresh as refreshFilters} from "../../../Map/Filters/FiltersReducer.js";
import {getDrawLayerGroup, getMap1} from "../../../Map/GlobalObjects";
import {refreshSearchResults, setCASearchResults,} from "./SearchResultsReducer";
import {setPlotDataAttr} from "../../../../../redux/reducers/plotDataReducer";
import {setSnack} from "../../../Map/Common/Dialog/Snack/snackReducer";
import {
  caCreatedButNotVisibleString,
  caCreatedString,
  coordinatesChangeSuccess,
  declarationString,
} from "../../../Map/Common/Strings_RU";
import {elementIsInViewport, hideAllOverlayWindows,} from "../../../Map/Common/MiscFunction";
import {showCAPopup, showSumCAPopup} from "./getCAInfo";
import {showCAProperiesDialog} from "./CAProperties/caPropertiesReducer";
import {
  addCALayerToSelection,
  caSearchLayerInSelection,
  caSelectionBorderColor,
  removeCALayerFromSelection,
} from "./Selections/caSelectionsCommon";
import {caSelectionRefresh} from "./Selections/caSelectionsReducer";
import {cannotIDo, getSpecies, getTariff} from "../../../Map/Common/tariffs";
import {closeMapPopup} from "../../../Map/MapPopup/mapPopup";
import {prepareToOpenPopup} from "../../../Map/MapPopup/popupFunctions";
import {clearCoordinatesDialogInfo} from "../../../Map/Common/Dialog/CoordinatesEditor/CoordinatesEditor";
import {machinerySVG} from "../../../Map/Common/svg/Svgs";
import "leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import {resetRightPanelData, setRightPanelAttr} from "../../../../../redux/reducers/rightPanelReducer";
import {loadRightPanelData} from "../../../RightPanel/right_panel";
import {handleBlobError, handleErrors} from "../../../../../redux/commonReducerFunctions/ThunkErrorsHandler";
import {openFileFromBlob} from "../../../../../api/miscAPIFuntions";
import {deleteTMLayers} from "./CAProperties/TransportMonitoringData/TransportMonitoringDataCommon";
import {addCaStockPilesToMap, createCaStockpiles, removeCaStockPilesFromMap} from "./stockpiles";
import {dispatch} from "../../../../Common/misc_functions";

export function getSpeciesColor(specieName) {
  let res = 'grey';
  Object.entries(getSpecies().data).forEach(([key, value]) => {
    if (key.toUpperCase() === specieName.toUpperCase()) {
      res = value.color;
    }
  })
  return res;
}

export const cuttingsAreasTemplate = {
  layerArray: [], //all loaded layers
  group: null, //group of visible layers
  markerGroup: null, //маркеры деля "в разработке"
  styles: {
    //стили
    'Отведена': prepOptionsX,
    'Задекларирована': declrOptions,
    'В разработке': inprOptionsX,
    'Разработана': completeOptionsX,
    'Идет вывозка': inmovingOptionsX,
    'Закрыта': closedOptionsX,
    'В архиве': archOptions,
  },
  defaultStatusFilters: [
    //статусы по-умолчанию
    "Отведена",
    "Задекларирована",
    "В разработке",
    "Разработана",
    "Идет вывозка",
    "Закрыта",
  ],
  filters: {
    //фильтры
    organization_name: [],
    rental_contract_number: [],
    region: [],
    forestry: [],
    district_forestry: [],
    declarationName: [],
    status: [],
    monitoring_data: [],
  }, //create dinamically on loading
  groups: [
    "organization_name",
    "rental_contract_number",
    "declarationName",
    "status",
  ], //все группы
  structuredTreeGroups: [],
  filtersNamesMap: {
    //названия
    organization_name: "Организация",
    rental_contract_number: "Рабочая область",
    declarationName: declarationString,
    region: "Регион",
    forestry: "Лесничество",
    district_forestry: "Участковое лесничество",
    status: "Статус деляны",
    monitoring_data: "Даные мониторинга ЛЗК",
  },
  structuredTree2: {data: [], show: true, type: "root"}, //сгруппироврованое древо
  loading: false,
  isLoading: false,
  loadingError: null,
  selectedCA: null,
  archCasLoading: false,
  archCasLoaded: false,
  stockpilesGroup: null, //все штабеля, деляны хранят из внутри себя
}

let cuttingsAreas = null;

export function putCAtoStructuredTree2(layer, tree, copyTree = null) {
  //ложит деляну в древо группировки
  let properties = layer.elz_properties;
  let treeGroup = cuttingsAreas.structuredTreeGroups;
  let parentNode = tree;
  let copyParentNode = copyTree;
  for (let ind = 0; ind < treeGroup.length; ind++) {
    let propName = properties[treeGroup[ind]];
    if (!propName) propName = "Нет";
    if (!(typeof propName === "string" || propName instanceof String))
      propName = propName.name;
    let childNode = parentNode.data[propName];
    let copyChildNode = copyParentNode?.data[propName]
    if (!childNode) {
      parentNode.data[propName] = {
        data: {},
        show: Boolean(copyChildNode?.show),
        name: propName,
      }
      childNode = parentNode.data[propName];
      childNode.parentNode = parentNode;
      childNode.grType = treeGroup[ind];
      if (treeGroup[ind] === "rental_contract_number")
        childNode.id = layer.elz_properties.rental_contract;
    }
    if (ind === treeGroup.length - 1) {
      if (!Array.isArray(childNode.data)) {
        childNode.data = []; //исправляем {} на [] если поперли деляны
      }
      childNode.data.push(layer);
    }
    parentNode = childNode;
    copyParentNode = copyChildNode;
  }
}

export function getCuttingAreas() {
  // возвращает объект делян
  return cuttingsAreas;
}

/**
 * Изменение координат из окна координат
 * @param layer - слой деляны
 * @param latlngs - новые координаты
 */
export function setCANewCoordinates(layer, latlngs) {
  const dispatch = window.elz_dispatch;
  const json = {
    geometry: {
      type: "Polygon",
      coordinates: [[]],
    },
  }
  latlngs.map((coo) => {
    json.geometry.coordinates[0].push([coo.lng, coo.lat])
  })
  json.geometry.coordinates[0].push([latlngs[0].lng, latlngs[0].lat])
  dataAPI.plots.pathCoordinates(layer.elz_properties.id, json)
    .then(() => {
      const arr = latlngs.map(
        (latlng) => new L.LatLng(latlng.lat, latlng.lng)
      )
      layer.setLatLngs(arr)
      dispatch(setSnack("success", coordinatesChangeSuccess))
      clearCoordinatesDialogInfo()
    })
    .catch((err) => {
      handleErrors(dispatch, err)
    })
}

export function getCAInteractive(layer) {
  //кнопки для попы
  const dispatch = window.elz_dispatch;
  const interactive = {};
  const iAmEditor = !cannotIDo.editorAction()
  const fullView = !cannotIDo.fullViewAction()

  if (fullView) {
    interactive.ca_popup_full_info_button = {
      type: "click",
      f: () => {
        deleteTMLayers()
        closeMapPopup()
        dispatch(hideAllOverlayWindows())
        dispatch(showCAProperiesDialog(layer))
      },
    }
  }

  if (iAmEditor) {
    //редактирование (основные свойства)
    interactive.ca_popup_edit_button = {
      type: "click",
      f: () => {
        closeMapPopup();
        dispatch(
          setPlotDataAttr({
            showUpdatePlotModal: true,
            selectedPlot: layer.elz_properties,
          })
        )
      },
    }
    //редактирование (доп. свойств)
    if (iAmEditor && !cannotIDo.changeCAData() && layer.elz_properties.status !== "В архиве") {
      interactive.ca_status_data_button = {
        type: "click",
        f: () => {
          dispatch(
            setRightPanelAttr({
              showCADataDialogWindow: true,
              selectedElement: {...layer.elz_properties, type: "Plot"},
            })
          )
          //dispatch(setPlotDataAttr({selectedPlot: layer.elz_properties}))
        },
      }
    }
    if (!cannotIDo.use1CReportsModule()) {
      interactive.ca_common_report_button = {
        type: 'click',
        f: () => {
          const dispatch = window.elz_dispatch;
          closeMapPopup()
          dispatch(setSnack('info', 'Запрос на формирование отчета отправлен. Файл откроется автоматически.'))
          getCACommonReport(layer.elz_properties.id)
        }
      }
    }
  }

  return interactive;
}

export function setCASelectedAndScrollTo(layer) {
  //выделить деляну в древе и прокрутить
  const dispatch = window.elz_dispatch;
  cuttingsAreas.selectedCA = layer;
  const li = document.getElementById(`ca_tree_item_${layer.elz_properties.id}`);
  if (li) {
    if (!elementIsInViewport(li)) li.scrollIntoView();
  } else cuttingsAreas.selectedCA = null;
  dispatch(refreshSearchResults());
}

/**
 * Обработчик клика по деляне (выделение или попа)
 * @param event {object} - leaflet event
 * @param layer - {L.Polygon} - слой
 */
export function casClickCallback(event, layer) {
  //Клик по деляне
  const dispatch = window.elz_dispatch;
  const ctrlKey = event.originalEvent.ctrlKey;
  if (!ctrlKey) {
    //show menu
    if (!prepareToOpenPopup(event))
      return;
    if (!layer.elz_oldopts) {
      const interactive = getCAInteractive(layer)
      showCAPopup(event, interactive, layer)
      loadRightPanelData('Plot', layer, layer.elz_properties.id, false)
    } else {
      const state = window.store.getState()["caSelectionsReducer"].items;
      const arr = [];
      state.forEach((layer) => {
        if (layer.elz_properties.id)
          arr.push(layer.elz_properties);
      })
      if (arr.length)
        showSumCAPopup(event, arr)
    }
    setCASelectedAndScrollTo(layer)
  } else { //add to selected
    prepareToOpenPopup(event) //for stopPropagation only
    const ind = caSearchLayerInSelection(layer);
    if (ind !== -1) {
      removeCALayerFromSelection(ind, dispatch);
    } else {
      addCALayerToSelection(layer, dispatch);
    }
  }
}

function getCAsLabelText(ca, showStratums = false) {
  //подписи делянов для печати
  let text = "";
  const props = ca.elz_properties;
  if (props?.quarter_number) text += `${props.quarter_number}/`;
  else text += "нет/";
  if (props.number) text += `${props.number}`;
  else text += "нет";
  if (showStratums && props.allotments_numbers)
    text += ` (${props.allotments_numbers.replaceAll(" ", "")})`;
  return text;
}

export function showCAsLabels(group, map, showStratums = false) {
  //показать подписи делянов
  group.eachLayer((ca) => {
    const text = getCAsLabelText(ca, showStratums);
    const point = ca.getBounds();
    const lng =
      (point._northEast.lng - point._southWest.lng) / 2 + point._southWest.lng;
    const northWestCoords = [point._northEast.lat, lng];
    const marker = L.circleMarker(northWestCoords, {radius: 0, weight: 0});
    marker.bindTooltip(text, {
      className: "cas_labels_class",
      interactive: true,
      permanent: true,
      offset: [0, -12],
      direction: "center",
    });
    ca.elz_label = marker;
    map.addLayer(marker);
  });
}

export function removeCAsLabels(group, map) {
  //удалить подписи делняов
  if (group)
    group.eachLayer((ca) => {
      if (ca.elz_label) map.removeLayer(ca.elz_label);
      delete ca.elz_label;
    });
}

export function addRemoveCAsStratums(group, showStratum = false) {
  //скрыть/показать подписи делянов
  group.eachLayer((ca) => {
    ca.elz_label.setTooltipContent(getCAsLabelText(ca, showStratum));
  });
}

/**
 * Добавляет значения фильтров, если их нет
 * @param elz_properties {object} - layer.elz_properties
 */
export function createFilterIfNotExists(elz_properties) {
  Object.entries(cuttingsAreas.filters).forEach(([name, arr]) => {
    let ele = elz_properties[name]
    if (ele?.name) ele = ele.name;
    if (ele && arr.indexOf(ele) === -1) {
      cuttingsAreas.filters[name].push(ele)
    }
  })
}

/**
 * Создает деляну из feature
 * @param feature - feature с бекенда
 * @returns {*} - слой leaflet с дополнительными полями
 */
export function createCA(feature) {
  const propepe = feature.properties;
  if (!propepe.status) propepe.status = "Отведена";

  const caStyle = cuttingsAreas.styles[propepe.status];
  const layer = L.polygon(
    feature.geometry.coordinates[0].map((ll) => [ll[1], ll[0]]),
    {...caStyle, pane: "cas"}
  )

  layer.elz_properties = feature.properties;
  layer.elz_properties.monitoring_data = "Нет"; //виртуальное свойство для фильтров

  if (propepe.status !== 'В архиве') {
    createFilterIfNotExists(propepe)
  }

  /// Группировка в дерево
  putCAtoStructuredTree2(layer, cuttingsAreas.structuredTree2)

  layer.elz_filter = []
  cuttingsAreas.layerArray.push(layer)
  //скрываем если архивная TODO здесь должна быть проверка на показываемость архивных делян из userSettings
  if (layer.elz_properties.status === "В архиве")
    layer.elz_filter.push("status")
  createCaStockpiles(layer, feature.stockpiles)
  layer.on("click", (e) => {
    if (prepareToOpenPopup(e))
      casClickCallback(e, layer)
  })
  return layer;
}

export function cloneCAPolygon(ca, pane) {
  //создать клон полигоны деляны для печати
  const polygon = L.polygon(ca.getLatLngs(), {...ca.options, pane: pane})
  polygon.elz_properties = ca.elz_properties;
  return polygon;
}

export function deleteAllCAByRentalContractID(id) {
  //удалить все деляны по id воркспейса
  const dispatch = window.elz_dispatch;
  const cas = getCuttingAreas()
  let ind = 0;
  if (cas) {
    while (ind < cas.layerArray.length) {
      const ca = cas.layerArray[ind];
      if (ca.elz_properties.rental_contract === id) {
        removeCAFromMap(ca);
        cas.layerArray.splice(ind, 1)
      }
      ind++;
    }
    dispatch(refreshFilters())
  }
}

function deleteCAFromStructuredTree2(node, ca) {
  //удалить деляну из дерева
  if (Array.isArray(node.data)) {
    let ind = -1;
    node.data.map((no) => {
      ind++;
      if (no === ca) {
        node.data.splice(ind, 1)
      }
    })
  } else {
    Object.values(node.data).map((child) => {
      deleteCAFromStructuredTree2(child, ca)
    })
  }
}

export function deleteCAFromSearchResults(ca, items) {
  //удалить деляну из списка поиска
  const dispatch = window.elz_dispatch;
  const itemsCopy = [...items];
  for (let ind = 0; ind < itemsCopy.length; ind++) {
    if (itemsCopy[ind] === ca) {
      itemsCopy.splice(ind, 1)
      break;
    }
  }
  dispatch(setCASearchResults(itemsCopy))
}

export function deleteCA(ca, searchResults, dispatch) {
  //удалить деляну
  const ind = cuttingsAreas.layerArray.findIndex(
    (lay) => lay.elz_properties.id === ca.elz_properties.id
  )
  if (ind > -1) {
    deleteCAFromStructuredTree2(cuttingsAreas.structuredTree2, ca)
    if (searchResults) deleteCAFromSearchResults(ca, searchResults)
    const indInSelection = caSearchLayerInSelection(ca)
    if (indInSelection !== -1) {
      removeCALayerFromSelection(indInSelection, dispatch)
    }
    removeCAFromMap(ca)
    cuttingsAreas.layerArray.splice(ind, 1)
    if (dispatch) {
      const state = window.store.getState()["rightPanelReducer"];
      if (state.rightPanelVisible && ca.elz_properties.id === state["selectedElement"].id)
        dispatch(resetRightPanelData())
      dispatch(refreshFilters())
      dispatch(refreshSearchResults())
    }
  }
}

function checkAbsentStatusFilters(name) {
  if (name === 'В архиве') return;
  //Добавляет статус в фильтр, если его нет
  const filters = getCuttingAreas().filters.status;
  if (filters.indexOf(name) === -1) filters.push(name)
}

/**
 * Добавляет маркер комплекса для денляны в статусе "разработана"
 * @param layer - слой деляны
 * @param type {"std"|"mon"} - тип иконки: "std" - обычная, 'mon' - с мониторингом
 * @returns {L.Marker} - маркер
 */
export function setMachineryMarker(layer, type = 'std') {
  const point = layer.getBounds().getCenter()
  let name = layer.elz_properties.properties_1c["АббревиатураТехники"]
  const marker = L.marker(point, {
    icon: machinerySVG(name, type),
    pane: "cas_markers",
    interactive: false,
  })
  const map = getMap1()
  if (layer.elz_markers) {
    map.elz_cas_markers.removeLayer(layer.elz_markers)
  }
  layer.elz_markers = marker;
  if (map.elz_cas_markers)
    map.elz_cas_markers.addLayer(marker)
  return marker;
}

export function changeCA(res, id) {
  //patch деляны
  const dispatch = window.elz_dispatch;
  const ind = cuttingsAreas.layerArray.findIndex((lay) => lay.elz_properties.id === id)
  if (ind !== -1) {
    const ca = cuttingsAreas.layerArray[ind];
    if (ca.elz_markers)
      getMap1().elz_cas_markers.removeLayer(ca.elz_markers)
    ca.elz_properties = res.data.properties;
    if (res.data.properties.status === "В разработке") {
      setMachineryMarker(ca, ca.elz_mon ? 'mon' : 'std')
    }
    const caStyle = cuttingsAreas.styles[ca.elz_properties.status]
    ca.setStyle(caStyle)
    if (ca.elz_oldopts) {
      //если деляна выделена
      ca.setStyle({color: caSelectionBorderColor})
      ca.elz_oldopts = caStyle;
    }
    loadRightPanelData('Plot', ca, ca.elz_properties.id)
    checkAbsentStatusFilters(ca.elz_properties.status)
    dispatch(refreshFilters())
    dispatch(refreshSearchResults())
    dispatch(caSelectionRefresh())
  }
}

/**
 * Загружает список архивных делян и создает их через createCA()
 * @returns {Promise<null>}
 */
export async function loadArchivedCuttingAreas() {
  cuttingsAreas.archLoading = true;
  const res = await dataAPI.plots.getAllArchived()
  const searchResult = window.store.getState().searchResultsReducer.searchResults;
  const dispatch = window.elz_dispatch;
  //удаляем все архивные деляны, т.к. прилетели все, включая те, которые есть
  //важно: узер может создать архивную деляну без загрузки всех
  cuttingsAreas.layerArray.map(layer => {
    if (layer.elz_properties.status === 'В архиве')
      deleteCA(layer, searchResult, dispatch)
  })
  res.data.forEach((feature) => {
    createCA(feature)
  })
  dispatch(refreshFilters())
  dispatch(refreshSearchResults())
  return res;
}

function loadCAsMonitoringData(id) {
  const layers = cuttingsAreas.layerArray;
  dataAPI.plots.getCAsMonitoringData(id).then(res => {
    let found = false;
    res.data["x_plots"].forEach((id) => {
      const layer = layers.find(layer => layer.elz_properties.id === id)
      if (layer) {
        if (layer.elz_properties.status === 'В разработке' && layer.elz_markers) {
          setMachineryMarker(layer, 'mon')
          layer.elz_mon = true; //не для фильтра
          found = true;
        }
        layer.elz_properties.monitoring_data = 'Есть'; //для фильтра
      }
    })
    if (found) {
      cuttingsAreas.filters.monitoring_data.unshift('Есть')
    }
    dispatch(refreshFilters())
  })
    .catch(err => {
      handleErrors(dispatch, err)
    })
}

export function loadCuttingAreas() {
  const map = getMap1()
  //загрузка делянов
  const dispatch = window.elz_dispatch;
  cuttingsAreas = structuredClone(cuttingsAreasTemplate)
  if (cuttingsAreas.loading || cuttingsAreas.isLoading)
    return null;
  cuttingsAreas.loading = true;

  dataAPI.plots.getAll()
    .then((res) => {
      cuttingsAreas.group = new L.LayerGroup()
      cuttingsAreas.stockpilesGroup = new L.LayerGroup()
      //TODO перетащить маркеры в cuttingAreas
      map.elz_cas_markers = L["markerClusterGroup"]({
        spiderfyOnMaxZoom: false,
        showCoverageOnHover: false,
        zoomToBoundsOnClick: true,
        maxClusterRadius: 20,
      }).addTo(map)
      //создает деляны
      res.data.map((feature) => {
        if (feature.geometry?.coordinates?.length) //игнорим деляны без координат
          createCA(feature)
      })
      map.addLayer(cuttingsAreas.group)
      map.elz_zoom.addLayer(cuttingsAreas.stockpilesGroup, 14, 20)
      cuttingsAreas.isLoading = true;
      cuttingsAreas.loading = false;
      dispatch(refreshSearchResults())
      dispatch(refreshFilters())
      loadCAsMonitoringData(getTariff().orgId)
    })
    .catch((err) => {
      console.error(err.message);
      cuttingsAreas.loading = false;
      cuttingsAreas.isLoading = true;
      cuttingsAreas.loadingError = err.message;
      dispatch(refreshFilters())
      dispatch(refreshSearchResults())
    })
}

export function createCAFromPolygon(feature, node) {
  //создание деляны из полигона
  const dispatch = window.elz_dispatch;
  const drawLayersGroup = getDrawLayerGroup()
  const ca = createCA(feature)
  if (drawLayersGroup.hasLayer(node.layer)) {
    node.visible = false;
    drawLayersGroup.removeLayer(node.layer)
    if (node.id)
      dataAPI.projects.shapes
        .patch(node.id, {is_visible_web: false})
        .then(() => {
        })
        .catch((err) => console.error(err));
  }
  loadRightPanelData('Plot', ca, ca.elz_properties.id)
  if (ca.elz_filter.length === 0)
    dispatch(setSnack("success", caCreatedString));
  else
    dispatch(setSnack("success", caCreatedButNotVisibleString, 5000))
  dispatch(refreshFilters())
  dispatch(refreshSearchResults())
}

/**
 * Удаляет деляну с карты вместе с маркерами и штабелями.
 * @param ca {object} - слой деляны
 */
export function removeCAFromMap(ca) {
  if (ca.elz_markers) { //удаляем маркеры
    getMap1().elz_cas_markers.removeLayer(ca.elz_markers)
    ca.elz_markers = null;
  }
  removeCaStockPilesFromMap(ca) //удаляем штабеля
  getCuttingAreas().group.removeLayer(ca) //удаляем слой дделяны
}

export function addCAToMap(ca) {
  //добавляет деляну на карту, только для фильтра
  const map = getMap1()
  if (!map.hasLayer(ca)) {
    const cas = getCuttingAreas()
    if (ca.elz_properties.status === "В разработке") {
      setMachineryMarker(ca, ca.elz_mon ? 'mon' : 'std')
    }
    addCaStockPilesToMap(ca)
    cas.group.addLayer(ca)
  }
}

export function getCACommonReport(id) {
  dataAPI.reports.ca.commonCAReport(id).then(res => {
    const blob = new Blob([res.data], {type: 'application/pdf'})
    openFileFromBlob(blob)
  }).catch(err => {
    handleBlobError(err)
  })
}

function renameWorkspaceInStructuredTree2(tree, id, name) {
  if (tree.grType === 'rental_contract_number' && tree.id === id) {
    tree.name = name;
    return true;
  } else {
    if (tree.data) {
      const arr = Object.values(tree.data)
      let ind = 0;
      while (ind < arr.length) {
        const item = arr[ind]
        if (item.data)
          if (renameWorkspaceInStructuredTree2(item, id, name))
            break;
        ind++;
      }
    }
  }
  return false;
}

export function renameCAsWorkspace(feature) {
  if (cuttingsAreas?.layerArray) {
    cuttingsAreas.layerArray.forEach((layer) => {
      if (layer.elz_properties.rental_contract === feature.id) {
        layer.elz_properties.rental_contract_number = feature.number;
      }
    })
  }
  if (cuttingsAreas?.structuredTree2) {
    renameWorkspaceInStructuredTree2(cuttingsAreas.structuredTree2, feature.id, feature.number)
  }
}

/**
 * Ищет слой деляны по ID
 * @param id {number} - id деляны
 * @returns {object|undefined}
 */
export function searchCALayerByID(id) {
  const cas = getCuttingAreas()
  if (cas?.layerArray) {
    return cas.layerArray.find(layer => layer.elz_properties.id === id)
  }
  return undefined;
}
