import {getCuttingAreas, searchCALayerByID} from "./CuttingsAreas";
import {getPlotStockpilePopup, prepareToOpenPopup} from "../../../Map/MapPopup/popupFunctions";
import {changeMapPopup, closeMapPopup, showLoadingPopup, showMapPopup} from "../../../Map/MapPopup/mapPopup";
import {otherDataAPI} from "../../../../../api/api";
import {cannotIDo} from "../../../Map/Common/tariffs";
import {urlGenerator} from "../../../../../utils/urlGenerator";
import {getStockpileThunk, setStockpilesAttr} from "../../../../../redux/reducers/stockpilesReducer";
import {disableModeAndUndoChanges, setPhotoAttr} from "../../../../../redux/reducers/stockpilePhotoReducer";
import {handleErrors} from "../../../../../redux/commonReducerFunctions/ThunkErrorsHandler";
import {compareLatLngsArrays, mapCoordinatesArray} from "../../../Map/Common/geoFunctions";
import {dispatch} from "../../../../Common/misc_functions";
import {setSnack} from "../../../Map/Common/Dialog/Snack/snackReducer";
import {geoJSONdefaultPrecision} from "../../../Map/Common/Defaults";
import {hideMapDialog, showMapDialog} from "../../../Map/Common/Dialog/DialogReducer";
import {yesNoWarningButtons} from "../../../Map/Common/Dialog/MapDialog";
import {layerEditor} from "../../../Map/Common/LayersEditor/LayerEditor";
import {getMap1} from "../../../Map/GlobalObjects";
import {finishPrevEditingFirst} from "../../../Map/Common/Strings_RU";

const stockpileStyle = {
  color: 'darkred',
  fillColor: 'orange',
  opacity: 0.5,
  fillOpacity: 1,
  weight: 2,
  pane: 'cas_stockpiles',
}
const stockpilesGrayStyle = {
  color: '#000000',
  fillColor: '#939393',
}
const editedStockpiles = [];

function setLayerColor(layer) {
  const source = layer.options.source_geom;
  const style = {...stockpileStyle}
  switch (source) {
    case 'web':
      layer.setStyle(style)
      break;
    case 'mobile':
    case 'empty':
      layer.setStyle({...style, ...stockpilesGrayStyle})
      break;
  }
}

/**
 * Возвращает массив редактируемых штабелей. Должно быть убрано после подключения редактора.
 * @returns {*[]}
 */
export function getEditesStockpiles() {
  return editedStockpiles;
}

/**
 * Завершает редактирование штабеля. Только на карте. Не сохраняет изменения на сервере.
 * @param layer {object} - leaflet сло штабеля
 */
export function stopStockpileEditing(layer) {
  layer.elz_editor.delete()
  getCuttingAreas().stockpilesGroup.addLayer(layer)
  const ind = editedStockpiles.findIndex(lay => lay === layer)
  if (ind !== -1) {
    editedStockpiles.splice(ind, 1)
  }
}

/**
 * Сохраняет изменения координат штабеля на сервере
 * @param layer {object} - слой leaflet с изсененями
 */
function saveStockpileCoordinatesToServer(layer) {
  layer.elz_saving = true;
  const json = {
    geometry: layer.toGeoJSON(geoJSONdefaultPrecision).geometry,
  }
  otherDataAPI.stockpiles.patchStockpile(layer.options.id, json)
    .then(() => {
      delete layer.elz_saving;
      delete layer.elz_saving_error;
      layer.options.source_geom = 'web';
      setLayerColor(layer)
      dispatch(setSnack('success', 'Сохранение штабеля завершено.'))
    })
    .catch(err => {
      handleErrors(dispatch, err)
      delete layer.elz_saving;
      layer.elz_saving_error = true;
    })
}

/**
 * Включает или переключает режим редактирования слоя.
 * @param layer {object} - leaflet слой
 * @param mode - режим редактирования ('edit', 'move', 'rotate')
 * @param ignoreMode {boolean} - всегда включать редактирование независимо от текущего режима (не завершать редактирование повторным тыком)
 */
function startStockpileEditing(layer, mode, ignoreMode = false) {
  const group = getCuttingAreas().stockpilesGroup;
  if (group.hasLayer(layer)) {
    group.removeLayer(layer)
  }

  layerEditor({
    map: getMap1(),
    layer: layer,
    editModes: ['edit', 'rotate', 'move'],
    saveHandler: (editor) => {
      const oldCoords = editor.ownLayer.getLatLngs()[0]
      const newCoords = editor.drawLayer.getLatLngs()[0]
      if (!compareLatLngsArrays(oldCoords, newCoords)) {
        editor.ownLayer.setLatLngs(newCoords)
        saveStockpileCoordinatesToServer(layer)
      }
      stopStockpileEditing(editor.ownLayer)
    },
    cancelHandler: (editor) => {
      stopStockpileEditing(editor.ownLayer)
    }
  })

  editedStockpiles.push(layer)
  delete layer.elz_saving;
  delete layer.elz_saving_error;
}

/**
 * Обработчик клика по штабелю
 * @param event {{latlng, target}}
 */
function stockpileClickHandler(event) {

  function getPopupInteractive() {
    const interactive = {}
    if (!cannotIDo.useStockpilesModule()) {
      interactive.stp_popup_go_to_module = {
        type: 'click',
        f: () => {
          const dispatch = window.elz_dispatch;
          dispatch(setStockpilesAttr({selectedPlot: layer.options.ca_options.id}))
          dispatch(setPhotoAttr({selectedPhoto: null}))
          dispatch(disableModeAndUndoChanges())
          dispatch(getStockpileThunk(layer.options.id))
          window.elz_navigate(urlGenerator().stockpiles)
        }
      }
    }
    //если можно редактировать штебеля, показываем кнопки
    if (!cannotIDo.writeStockpilesModule() || !!cannotIDo.editorAction()) {
      if (!layer.elz_emode && layer.elz_saving_error) {
        interactive.stp_popup_save_button = {
          type: 'click',
          f: () => {
            closeMapPopup()
            saveStockpileCoordinatesToServer(layer)
          }
        }
      }
      if (layer.options.source_geom === "web") { //разрешено сбрасывать геометрию
        interactive.stp_popup_reset_geometry_button = {
          type: 'click',
          f: () => {
            closeMapPopup()
            stockpileGeometryReset(layer)
          }
        }
      }
      if (!layer.elz_editor) {
        interactive.stp_popup_edit_button = {
          type: 'click',
          f: () => {
            closeMapPopup()
            const editor = window.store.getState().layerEditingReducer.editor;
            if (editor) {
              dispatch(setSnack('warning', finishPrevEditingFirst))
            } else {
              startStockpileEditing(layer, 'edit')
            }
          }
        }
      }
    }
    return interactive;
  }

  const layer = event.target;
  if (!prepareToOpenPopup(event))
    return;

  const interactive = getPopupInteractive()
  if (!cannotIDo.fullViewAction()) {
    showLoadingPopup(event.latlng)
    otherDataAPI.stockpiles.getStockpilePopupInfo(layer.options.id).then(res => {
        const html = getPlotStockpilePopup(layer, res.data, interactive)
        changeMapPopup(html, interactive)
      }
    ).catch(err => {
      const html = getPlotStockpilePopup(layer, null, interactive)
      changeMapPopup(html, interactive)
      handleErrors(dispatch, err)
    })
  } else {
    const html = getPlotStockpilePopup(layer, null, interactive)
    showMapPopup(event.latlng, html, interactive)
  }
}

/**
 * Создает штабель на карте. Добавляет его в elz_stockpiles деляны
 * @param ca {object} - слой деляны
 * @param feature {object} - фича штабеля с сервера
 * @param group {L.LayerGroup} - группа штабелей
 */
function createStockpileInCA(ca, feature, group) {
  const coordinates = feature.geometry.coordinates[0].map(co => [co[1], co[0]])
  let style = {...stockpileStyle}
  if (feature.source_geom === 'empty') {
    style = {...style, ...stockpilesGrayStyle}
  }
  const lay = L.polygon(coordinates, style)
  lay.options.id = feature.id; //необходимо для открытия модуля штаблей
  lay.options.ca_options = { //инфа для попы и модуля штабелей
    name: ca.elz_properties.name,
    id: ca.elz_properties.id
  }
  lay.options.source_geom = feature.source_geom || 'empty';
  lay.options.name = feature.name || 'Штабель';
  lay.on('click', stockpileClickHandler)
  ca.elz_stockpiles.push(lay)
  group.addLayer(lay)
}

/**
 * Создает все штабеля для деляны.
 * Добавляет их в cuttingsAreas.stockpilesGroup.
 * Добавляет их в elz_stockpiles.
 * @param ca {{elz_stockpiles: Array, options: object, elz_properties: object}} - слой деляны
 * @param stockpiles {Array} - массив фичей штабелей с бекенда
 */
export function createCaStockpiles(ca, stockpiles) {
  const group = getCuttingAreas().stockpilesGroup;
  ca.elz_stockpiles = []
  //Добавляем штабли токо для неархивных делян
  //При создании деляны из полигона stockpiles === undefined
  if (ca.elz_properties.status !== "В архиве" && stockpiles) {
    stockpiles.filter(data => data.geometry !== null).forEach(stockpile => {
      createStockpileInCA(ca, stockpile, group)
    })
  }
}

/**
 * Убирает все штабеля деляны с карты
 * @param ca {{elz_stockpiles: L.LayerGroup}} - слой деляны
 */
export function removeCaStockPilesFromMap(ca) {
  const group = getCuttingAreas().stockpilesGroup;
  ca.elz_stockpiles.forEach(stockpileLayer => {
    group.removeLayer(stockpileLayer)
  })
}

/**
 * Добавляет все штабеля деляны на карту
 * @param ca {{elz_stockpiles: L.LayerGroup}} - слой деляны
 */
export function addCaStockPilesToMap(ca) {
  const group = getCuttingAreas().stockpilesGroup;
  ca.elz_stockpiles.forEach(stockpileLayer => {
    if (!stockpileLayer.elz_editor)
      group.addLayer(stockpileLayer)
  })
}

/**
 * Создает штабель из feature с бекенда и добавляет его в деляну (для создания из редактора)
 */
export function createCAStockpile(caID, feature) {
  const caLayer = searchCALayerByID(caID)
  if (caLayer) {
    createStockpileInCA(caLayer, feature, getCuttingAreas().stockpilesGroup)
  }
}

export function deleteCAStockpile(caID, stockpileID) {
  const caLayer = searchCALayerByID(caID)
  const cas = getCuttingAreas()
  if (caLayer) {
    const ind = caLayer.elz_stockpiles.findIndex(stockpile => stockpile.options.id === stockpileID)
    if (ind !== -1) {
      const stock = caLayer.elz_stockpiles[ind]
      cas.stockpilesGroup.removeLayer(stock)
      caLayer.elz_stockpiles.splice(ind, 1)
    }
  }
}

export function stockpileGeometryReset(layer) {
  layer.elz_saving = true;
  dispatch(showMapDialog(
    'warning',
    'Сброс координат штабеля',
    'Координаты штабеля будут сброшены в начальные значения. Это не затронет другую информацию и фотографии. Продолжить?',
    yesNoWarningButtons,
    (value) => {
      dispatch(hideMapDialog())
      if (value === 'Да') {
        closeMapPopup()
        otherDataAPI.stockpiles.resetCoordinates(layer.options.id)
          .then(res => {
            const coords = mapCoordinatesArray(res.data.geometry.coordinates[0])
            layer.setLatLngs(coords)
            layer.options.source_geom = res.data.source_geom;
            setLayerColor(layer)
            delete layer.elz_saving;
            delete layer.elz_saving_error;
            layer.options.source_geom = res.data.source_geom;
          })
          .catch(err => {
            handleErrors(dispatch, err)
            delete layer.elz_saving;
            delete layer.elz_saving_error;
          })
      }
    }
  ))
}
