import {forwardRef, useCallback, useState} from 'react';
import {Autocomplete, Checkbox, Chip, List, TextField, Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import {compileShortName} from "../../../../Main/Map/Common/MiscFunction";
import {getTariff} from "../../../../Main/Map/Common/tariffs";
import {
  addAllUsersInWorkspacePermissionaly,
  addWorkspaceInMeChanged,
  changeUsersInWorkspace,
  deleteAllUsersFromWorkspacePermissionally,
  removeWorkspaceInMeChanged
} from "../../../org_page_api";
import {handleErrors} from "../../../../../redux/commonReducerFunctions/ThunkErrorsHandler";
import {setSnack} from "../../../../Main/Map/Common/Dialog/Snack/snackReducer";
import {workspaceSettingSaved} from "../../../../Main/Map/Common/Strings_RU";
import {changeOrgPageAttrs} from "../../../organizationPageReducer";

const UsersInWorkspacesEditable = ({workspace, iAmOwner}) => {
  const organizationUsers = useSelector(state => state.organizationPageReducer.employeesData)
  const [oldUsers, setOldUsers] = useState(null)
  const icon = <CheckBoxOutlineBlankIcon sx={{fontSize: 14}}/>
  const checkedIcon = <CheckBoxIcon sx={{fontSize: 14}}/>
  const myId = getTariff().userID;
  const dispatch = useDispatch()

  function addDeleteAll(type) {
    if (type === 'add') { //add all
      addAllUsersInWorkspacePermissionaly(workspace, organizationUsers)
    } else { //remove all
      deleteAllUsersFromWorkspacePermissionally(workspace, organizationUsers)
    }
    dispatch(changeOrgPageAttrs({employeesData: [...organizationUsers]}))
  }

  const onChangeHandler = (list) => {
    workspace.users = list;
    dispatch(changeOrgPageAttrs({employeesData: [...organizationUsers]}))
  }

  const onBlurHandler = () => {
    if (JSON.stringify(workspace.users) !== JSON.stringify(oldUsers)) { //TODO это может не работать при изменении!!!
      changeUsersInWorkspace(workspace.id, workspace.users)
        .then(() => {
          const myID = getTariff().userID;
          const iWas = !!workspace.users.find(user => user.id === myID)
          const iBecame = !!oldUsers.find(user => user.id === myID)
          if (iWas !== iBecame) {
            if (iWas) {
              addWorkspaceInMeChanged(workspace.id, 'add')
            } else {
              removeWorkspaceInMeChanged(workspace.id, 'delete')
            }
          }
          setOldUsers(null)
          dispatch(setSnack('success', workspaceSettingSaved))
        })
        .catch(err => {
          handleErrors(dispatch, err)
          workspace.users = oldUsers;
          dispatch(changeOrgPageAttrs({employeesData: [...organizationUsers]})) //передерг редусера
          setOldUsers(null)
        })
    }
  }

  function onOpenHandler() {
    setOldUsers([...workspace.users])
  }

  const CustomList = useCallback(forwardRef((props, ref) => {
    return (
      <List dense {...props} ref={ref}>
        <ListItem>
          <Button size={"small"} onClick={() => addDeleteAll('add')}>Добавить всех</Button>
        </ListItem>
        <ListItem>
          <Button size={"small"} onClick={() => addDeleteAll('delete')}>Удалить всех</Button>
        </ListItem>
        {props.children}
      </List>
    )
  }), [workspace])

  let ind = 0;
  return (
    <Autocomplete
      ListboxComponent={CustomList}
      disableClearable
      disableCloseOnSelect
      size={"small"}
      multiple
      limitTags={4}
      options={organizationUsers || []}
      value={workspace.users}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(user) => compileShortName(user.first_name, user.last_name, user.patronymic) || ''}
      getOptionDisabled={(user) => !iAmOwner && user.is_admin && myId !== user.id}
      renderOption={(props, option, {}) => (
        <ListItem {...props} key={ind++}>
          <Checkbox
            size={'small'}
            sx={{p: 0}}
            icon={icon}
            checkedIcon={checkedIcon}
            checked={!!workspace.users.find(user => user.id === option.id)}
            style={{marginRight: 8}}
          />
          <Typography sx={{fontSize: 13}}>
            {compileShortName(option.first_name, option.last_name, option.patronymic)}
          </Typography>
        </ListItem>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            key={ind++}
            label={compileShortName(option.first_name, option.last_name, option.patronymic)}
            {...getTagProps({index})}
            onDelete={undefined}
          />
        ))
      }
      onChange={(_event, item) => {
        onChangeHandler(item)
      }}
      onBlur={onBlurHandler}
      onOpen={onOpenHandler}
      renderInput={(params) => {
        return (
          <TextField {...params} label={'Сотрудники'}/>)
      }}
    />
  )
}

export default UsersInWorkspacesEditable;
