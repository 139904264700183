import {applyMiddleware, combineReducers, createStore} from "redux";
import thunkMiddleware from "redux-thunk";
import {loginReducer} from "./reducers/loginReducer";
import {userReducer} from "./reducers/userReducer";
import {elzMapReducerCP} from "../components/Main/Map/ELZMapReducer";
import {filterReducer} from "../components/Main/Map/Filters/FiltersReducer";
import {leftDrawerReducer} from "../components/Main/LeftPanel/LeftDrawerReducer";
import {searchResultsReducer} from "../components/Main/LeftPanel/Context/CAs/SearchResultsReducer";
import {organizationInfoReducer} from "./reducers/organizationInfoReducer";
import {dialogReducer} from "../components/Main/Map/Common/Dialog/DialogReducer";
import {projectsReducer} from "../components/Main/LeftPanel/Context/Projects/projectsReducer";
import {referencesReducer} from "./reducers/referencesReducer";
import {plotDataReducer} from "./reducers/plotDataReducer";
import {paymentReducer} from "./reducers/paymentReducer";
import {enterDialogReducer} from "../components/Main/Map/Common/Dialog/EnterDialogReducer";
import {snackReducer} from "../components/Main/Map/Common/Dialog/Snack/snackReducer";
import {measuringReducer} from "../components/Main/Overlay/Measuring/measuringReducer";
import {feedbackReducer} from "./reducers/feedbackReducer";
import {planetableSvgReducer} from "./reducers/planetableSvgReducer";
import {baseLayersReducer} from "../components/Main/Overlay/BaseLayers/BaseLayersReducer";
import {blindReducer} from "../components/Main/Map/Blind/BlindReducer";
import {planetablesReducer} from "./reducers/planetablesReducer";
import {introToursReducer} from "./reducers/IntroToursReducer";
import {forestUseReportReducer} from "./reducers/forestUseReportReducer";
import {printingReducer} from "../components/Main/LeftPanel/Context/Printing/printingReducer";
import {planetablesGroupingReducer} from "./reducers/planetablesGroupingReducer";
import {caPropertiesReducer} from "../components/Main/LeftPanel/Context/CAs/CAProperties/caPropertiesReducer";
import {caSelectionsReducer} from "../components/Main/LeftPanel/Context/CAs/Selections/caSelectionsReducer";
import {userLayersReducer} from "../components/Main/LeftPanel/Context/MiscLayers/UserLayers/userLayersReducer";
import {stockpilesReducer} from "./reducers/stockpilesReducer";
import {stockpilePhotoReducer} from "./reducers/stockpilePhotoReducer";
import {workspaceReducer} from "../components/Main/LeftPanel/Context/Projects/WorkspaceSettings/workspaceReducer";
import {
  coordinatesEditorReducer
} from "../components/Main/Map/Common/Dialog/CoordinatesEditor/coordinatesEditorReducer";
import {websocketMiddleware, websocketReducer} from "./reducers/websocketReducer";
import {operationalReportReducer} from "./reducers/operationalReportReducer";
import {rightPanelReducer} from "./reducers/rightPanelReducer";
import {violationsReducer} from "../components/Main/LeftPanel/Context/Infrastructure/Violations/violationsReducer"
import {warehousesReducer} from "../components/Main/LeftPanel/Context/Infrastructure/Warehouses/warehousesReducer";
import {mapSearchReducer} from "../components/Main/Overlay/Searching/mapSearchReducer";
import {infrastructureViewReducer} from "../components/Main/Overlay/InfrastructureView/infrascructureViewReduces";
import {userBlockReducer} from "./reducers/userBlockReducer";
import {roadsReducer} from "../components/Main/LeftPanel/Context/Roads/roadsReducer";
import {pillarsReducer} from "../components/Main/LeftPanel/Context/Infrastructure/Pillars/pillarsReducer";
import {infractructureReducer} from "../components/Main/LeftPanel/Context/Infrastructure/infractructureReducer";
import {cuttingAreasReducer} from "../components/Main/LeftPanel/Context/CAs/cuttingAreasReducer";
import {organizationPageReducer} from "../components/NewOrganizationPage/organizationPageReducer";
import {bitrixReducer} from "../components/Main/Map/Common/Bitrix/bitrixReducer";
import {layerEditingReducer} from "../components/Main/Map/Common/LayersEditor/layerEditorReducer";

export const appReducer = combineReducers({
  bitrixReducer: bitrixReducer,
  loginReducer: loginReducer,
  userReducer: userReducer,
  organizationInfoReducer: organizationInfoReducer,
  elzMapReducerCP: elzMapReducerCP,
  filterReducer: filterReducer,
  leftDrawerReducer: leftDrawerReducer,
  searchResultsReducer: searchResultsReducer,
  dialogReducer: dialogReducer,
  projectsReducer: projectsReducer,
  referencesReducer: referencesReducer,
  plotDataReducer: plotDataReducer,
  paymentReducer: paymentReducer,
  enterDialogReducer: enterDialogReducer,
  snackReducer: snackReducer,
  measuringReducer: measuringReducer,
  feedbackReducer: feedbackReducer,
  planetableSvgReducer: planetableSvgReducer,
  planetablesReducer: planetablesReducer,
  baseLayersReducer: baseLayersReducer,
  blindReducer: blindReducer,
  introToursReducer: introToursReducer,
  forestUseReportReducer: forestUseReportReducer,
  printingReducer: printingReducer,
  planetablesGroupingReducer: planetablesGroupingReducer,
  caPropertiesReducer: caPropertiesReducer,
  caSelectionsReducer: caSelectionsReducer,
  userLayersReducer: userLayersReducer,
  stockpilesReducer: stockpilesReducer,
  stockpilePhotoReducer: stockpilePhotoReducer,
  workspaceReducer: workspaceReducer,
  websocketReducer: websocketReducer,
  coordinatesEditorReducer: coordinatesEditorReducer,
  operationalReportReducer: operationalReportReducer,
  rightPanelReducer: rightPanelReducer,
  violationsReducer:violationsReducer,
  warehousesReducer:warehousesReducer,
  mapSearchReducer: mapSearchReducer,
  infrastructureViewReducer: infrastructureViewReducer, //кнопка видимости на карте
  userBlockReducer: userBlockReducer,
  roadsReducer: roadsReducer,
  pillarsReducer: pillarsReducer,
  infrastructureReducer: infractructureReducer, //левая панель
  cuttingAreasReducer: cuttingAreasReducer,
  organizationPageReducer: organizationPageReducer,
  layerEditingReducer: layerEditingReducer,
})

// rootReducer - обёртка для сброса всех редьюсеров до initial state (напр. при выходе пользователя из приложения)
const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGGED_OUT') {
    state = undefined;
  }
  return appReducer(state, action);
}

let store = createStore(rootReducer, applyMiddleware(thunkMiddleware, websocketMiddleware));
window.store = store;
export default store;
