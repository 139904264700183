import {useEffect} from 'react';
import {TitleCommonBlock} from "../Common/TitleCommonBlock";
import Leftbar from "./Leftbar/Leftbar";
import ImageContainer from "./Image/ImageContainer";
import BottomBlock from "./BottomBlock/BottomBlock";
import Rightbar from "./Rightbar/Rightbar";
import {Stack, useTheme} from "@mui/material";
import Box from "@mui/material/Box";
import Toolbar from "./Toolbar/Toolbar";
import {
  deletePhotoFromSelectedStockpile,
  replacePhotoInSelectedStockpile,
  setPhotosToSelectedStockpile
} from "../../redux/reducers/stockpilesReducer";
import {useDispatch, useSelector} from "react-redux";
import {setPhotoAttr} from "../../redux/reducers/stockpilePhotoReducer";
import ClosedStockpileToolbar from "./ClosedStockpileToolbar/ClosedStockpileToolbar";
import {setSnack} from "../Main/Map/Common/Dialog/Snack/snackReducer";
import {cannotIDo} from "../Main/Map/Common/tariffs";
import {urlGenerator} from "../../utils/urlGenerator";

const Stockpiles = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const selectedPhoto = useSelector(state => state.stockpilePhotoReducer.selectedPhoto)
  const plotsStockpiles = useSelector(state => state.stockpilesReducer.plotsStockpiles)
  const selectedStockpile = useSelector(state => state.stockpilesReducer.selectedStockpile)
  const selectedPlot = plotsStockpiles?.find(plot => plot.id === selectedStockpile?.xplot)
  //const images = useSelector(state => state.stockpilesReducer.selectedStockpile?.images)
  //const buttons = {'Да': {color: theme.palette.warning.main}, 'Нет': {color: theme.palette.primary.main}}
  const stockpilePhotosMessages = useSelector(state => state.websocketReducer.stockpilePhotosMessages)
  const iAmEditor = !cannotIDo.writeStockpilesModule() && !cannotIDo.editorAction()
  useSelector(state => state.userReducer) //для обновления при нажатии F5

  function addWebsocketPhoto(lastPhoto) {
    let isPhotoFromSelectedStockpile = selectedStockpile.id === lastPhoto["photodata"];
    let isPhotoUploaded = selectedStockpile.images.find((image) => image.id === lastPhoto.id)
    if (lastPhoto.error) {
      dispatch(setSnack('error', lastPhoto.error))
    } else if (isPhotoFromSelectedStockpile) {
      if (isPhotoUploaded) {
        dispatch(replacePhotoInSelectedStockpile(lastPhoto))
        selectedPhoto?.id === lastPhoto.id && dispatch(setPhotoAttr({selectedPhoto: lastPhoto}))
      } else {
        dispatch(setPhotosToSelectedStockpile([lastPhoto]))
      }
    }
  }

  function deleteWebsocketPhoto(lastPhoto) {
    let isPhotoFromSelectedStockpile = selectedStockpile.images.some(image => image.id === lastPhoto["id_img"])
    if (lastPhoto.error) {
      dispatch(setSnack('error', lastPhoto.error))
    } else if (isPhotoFromSelectedStockpile) {
      dispatch(deletePhotoFromSelectedStockpile(lastPhoto["id_img"]))
      selectedPhoto?.id === lastPhoto["id_img"] && dispatch(setPhotoAttr({selectedPhoto: null}))
    }
  }

  //обработка получения данных фото штабеля через websocket
  useEffect(() => {
    let newPhotoData = stockpilePhotosMessages[stockpilePhotosMessages.length - 1]
    let lastPhoto = newPhotoData?.data
    if (selectedStockpile && lastPhoto) {
      switch (newPhotoData.code) {
        case 200:
          addWebsocketPhoto(lastPhoto)
          break;
        case 204:
          deleteWebsocketPhoto(lastPhoto)
      }
    }
  }, [stockpilePhotosMessages])

  function goToBack(navigate) {
    navigate(urlGenerator().main)
  }

  if (cannotIDo.useStockpilesModule())
    return null;

  const borderString = `1px solid ${theme.palette.primary.main}`;

  const toolbarContainerStyle = {
    width: '100%',
    minHeight: '10%',
    display: 'flex',
    pl: 0.5,
    pr: 0.5,
    overflowX: 'auto',
    overflowY: 'hidden',
    borderTop: borderString,
    borderBottom: borderString,
    boxSizing: 'border-box',
  }

  const imageContainerStyle = {
    position: 'relative',
    marginBottom: 'auto',
    boxSizing: 'border-box',
    flex: 1,
  }

  const rightbarContainerStyle = {
    minWidth: '250px',
    width: '20%',
    height: '100%',
    borderLeft: borderString,
    borderTop: borderString,
    borderRight: borderString,
    boxSizing: 'border-box',
  }

  const bottomBlockContainerStyle = {
    width: '100%',
    height: '16%',
    border: borderString,
    boxSizing: 'border-box',
    padding: '1vh',
  }

  return (
    <Stack sx={{height: '100%', width: '100%'}}>
      <TitleCommonBlock title={'Замер штабелей'} closeButtonHandler={goToBack}/>
      <Stack sx={{width: '100%', flex: 1}} direction={'row'}>
        <Leftbar iAmEditor={iAmEditor} borderString={borderString}/>
        <Stack sx={{width: '60%'}}>
          <Box sx={toolbarContainerStyle}>
            {(selectedPlot?.is_closed || !iAmEditor)
              ? <ClosedStockpileToolbar iAmEditor={iAmEditor}/>
              : <Toolbar iAmEditor={iAmEditor}/>
            }
          </Box>
          <Box sx={imageContainerStyle} id={'lalal'}>
            <div style={{position: 'absolute', inset: 0}}>
              <ImageContainer iAmEditor={iAmEditor}/>
            </div>
          </Box>
        </Stack>
        <Box sx={rightbarContainerStyle}>
          <Rightbar borderString={borderString}/>
        </Box>
      </Stack>
      <Box sx={bottomBlockContainerStyle}>
        <BottomBlock iAmEditor={iAmEditor}/>
      </Box>
    </Stack>
  )
}

export default Stockpiles;
