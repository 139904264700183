export const leftbarContainer = {overflow: 'auto'}

export const plotsTreePaddingLeft = '18px'

export const treeListItemStyle = {
  display: 'flex', flexDirection: 'column', justifyContent: 'start',
  alignItems: 'flex-start', width: '100%', padding: '3px 0', margin: 0,
  cursor: 'pointer', userSelect: 'none',
}

export const leftBarTextStyle = {color: '#545454', fontWeight: '600', padding: 0,}

export const stockpileTreeItemTextStyle = {fontStyle: 'italic', fontWeight: '400', margin: 0,}
