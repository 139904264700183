import {Stack, Typography} from "@mui/material";
import ObjectData from "./ObjectData/ObjectData";
import StockpileData from "./StockpileData/StockpileData";
import {useSelector} from "react-redux";
import {rightBarTitleStyle} from "./rightbarStyles";

function Rightbar({borderString}) {
  const selectedStockpile = useSelector(state => state.stockpilesReducer.selectedStockpile)
  const selectedPhoto = useSelector(state => state.stockpilePhotoReducer.selectedPhoto)

  const bottomBlockStyle = {
    height: '40%',
    borderTop: borderString,
    boxSizing: 'border-box',
    width: '100%',
  }

  return (
    <Stack justifyContent={'space-between'} alignItems={'center'} sx={{width: '100%', height: '100%'}}>
      <Stack sx={{marginTop: '1vh', height: '60%', width: '100%'}} alignItems={'center'}>
        {selectedPhoto &&
          <ObjectData/>
        }
      </Stack>
      <Stack sx={bottomBlockStyle} alignItems={'center'}>
        {selectedStockpile &&
          <>
            <Typography sx={rightBarTitleStyle}>Информация по штабелю</Typography>
            <StockpileData selectedPhoto={selectedPhoto}/>
          </>
        }
      </Stack>
    </Stack>
  )
}

export default Rightbar;
