import React from 'react';
import {IconButton, ListItem, ListItemButton, Stack, Typography} from "@mui/material";
import {
  defaultPaddingStep,
  fTextFieldStyle,
  projectsTreeListItemStyle,
  shapeTypographyStyle,
  treeItemButtonIconSize,
  treeItemButtonStyle
} from "../../../../Map/Common/Styles";
import {refreshProjects} from "../projectsReducer";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import {FTextField} from "../FTextFiled";
import {saveChangeString} from "../../../../Map/Common/Strings_RU";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import {ProjectsItemContextMenu} from "../ProjectsItemContextMenu";
import {cancelEdit, getProjects} from "../ProjectsCommon";
import {useDispatch} from "react-redux";
import {ReactComponent as PolygonIcon} from '../../../../Map/Common/svg/polygon.svg';
import {ReactComponent as LineIcon} from '../../../../Map/Common/svg/line.svg';
import {ReactComponent as MarkerIcon} from "../../../../Map/Common/svg/marker.svg";
import {loadRightPanelData} from "../../../../RightPanel/right_panel";
import {flyToShape} from "../../../../Map/Common/fly";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {getDrawLayerGroup} from "../../../../Map/GlobalObjects";
import {closeMapPopup} from "../../../../Map/MapPopup/mapPopup";

const ShapeTreeItem = ({node, eyeClickHandler, endEditing, manuallySaving, readOnly, padding}) => {
  const dispatch = useDispatch()
  const projects = getProjects()
  const notSaved = node.edit === 0 && (node.new || node.changed)

  function clickHandler() {
    //resetPillarsSelection(false)
    projects.selectedNode = node;
    flyToShape(node.layer, node.type)
    loadRightPanelData(node.type, node, node.id, false)
    dispatch(refreshProjects())
  }

  return (
    <ListItem
      disableGutters
      disablePadding
      style={{paddingLeft: defaultPaddingStep * padding}}
    >
      <ListItemButton
        disableRipple
        style={{...projectsTreeListItemStyle}}
        selected={node === projects.selectedNode}
      >
        <Stack
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'left'}
          style={{
            width: '100%',
            userSelect: 'none',
          }}
        >
          <IconButton
            disableRipple
            style={treeItemButtonStyle}
            onClick={clickHandler}
          >
            {node.type === 'Polygon' ?
              <PolygonIcon style={{stroke: 'grey'}}/> :
              node.type === 'Line' ?
                <LineIcon style={{fill: 'grey'}}/> :
                <MarkerIcon style={{fill: 'grey'}}/>
            }
          </IconButton>
          <IconButton
            style={treeItemButtonStyle}
            onClick={() => {
              eyeClickHandler(node)
              dispatch(refreshProjects())
            }}>
            {node.visible ?
              <VisibilityOutlinedIcon color={'primary'} fontSize={'small'}
                                      style={{...treeItemButtonIconSize, fill: null}}/>
              :
              <VisibilityOffOutlinedIcon fontSize={'small'} style={treeItemButtonIconSize}/>
            }
          </IconButton>
          {!node.edit ?
            <Typography
              style={shapeTypographyStyle}
              onClick={clickHandler}
            >
              {node.name ? node.name : 'Без названия'}
            </Typography>
            :
            <FTextField
              _endEditing={(e) => {
                endEditing(e, dispatch);
                dispatch(refreshProjects())
              }}
              _cancelEdit={() => {
                dispatch(refreshProjects())
              }}
              value={node.name}
              style={fTextFieldStyle}
            />
          }
          {notSaved &&
            <IconButton
              style={treeItemButtonStyle}
              title={saveChangeString}
              disableRipple
              onClick={() => manuallySaving(node)}
            >
              <SaveOutlinedIcon fontSize={'small'} style={treeItemButtonIconSize}/>
            </IconButton>
          }
          {node.elz_editor
            ?
            <IconButton
              style={{...treeItemButtonStyle, padding: 0}}
              size={'small'}
              color={'error'}
              title={'Отменить редактирование'}
              onClick={(event) => {
                event.stopPropagation()
                getDrawLayerGroup().addLayer(editor.ownLayer)
                node.layer.elz_editor.delete()
              }}
            >
              <CloseOutlinedIcon fontSize={'small'}/>
            </IconButton>
            :
            !readOnly &&
            !node.layer.elz_editor
              ?
              <ProjectsItemContextMenu node={node}/>
              :
              <IconButton
                style={{...treeItemButtonStyle, padding: 0}}
                size={'small'}
                color={'error'}
                title={'Отменить редактирование'}
                onClick={(event) => {
                  event.stopPropagation()
                  closeMapPopup()
                  cancelEdit(node)
                }}
              >
                <CloseOutlinedIcon fontSize={'small'}/>
              </IconButton>
          }
        </Stack>
      </ListItemButton>
    </ListItem>
  )
}

export default ShapeTreeItem;
