import axios from "axios"
import {formatDateFromObjToStr, getSentinelDate, localizeDateTimeFormat} from "./DateTimeFunctions.js"
import {dataAPI} from "../../../../api/api";
import {getMap1} from "../GlobalObjects";
import {errorOnDataRecieved, noInfoString, settingsString} from "./Strings_RU";
import {getUserMiscLayers} from "../../LeftPanel/Context/MiscLayers/UserLayers/UserLayers";
import {getBaseLayers} from "../../Overlay/BaseLayers/BaseLayersCommon";
import {getBlindBaseLayers} from "../Blind/BlindBaseLayersCommon";
import {getBlindMiscLayers} from "../Blind/BlindMiscLayersCommon";
import {getMiscLayers} from "../../LeftPanel/Context/MiscLayers/MiscLayersCommon";
import {parseParamsFromUrl} from "./MiscFunction";
import {changeMapPopup, getMapPopup, showLoadingPopup} from "../MapPopup/mapPopup";
import {getProjects} from "../../LeftPanel/Context/Projects/ProjectsCommon";

let featureInfoEnabled = true;

export function setFeatureInfoEnabled(enabled) {
  featureInfoEnabled = enabled;
}

function createHTMLPropertiesList(obj) {
  let result = '';
  if (obj) {
    const props = obj; //TODO здесь должны быть пропы из маплиста с нормальными названиями
    if (Object.keys(props).length) { //есть хоть что-то
      Object.entries(props).map(([key, value]) => {
        result += `<span class="popup-header popup_nowrap">${key}: </span><span class="popup-row popup_minwidth150">${value}</span><br />`;
      })
    }
  }
  return result;
}

export async function getFeatureInfoData(coordinates, point, blind, interactive = {}) {
  const popupObject = showLoadingPopup(coordinates)

  const baseLayers = blind ? getBlindBaseLayers() : getBaseLayers()
  const miscLayers = blind ? getBlindMiscLayers() : getMiscLayers()

  const map = getMap1()
  const addTabs = []

  let errorMessage = null;
  const bounds = map.getBounds()

  /*Object.values(baseLayers).map(l => {
    if (l.l && l.feas) {
      if (addTabs.indexOf('base') === -1) {
        addTabs.push('base')
      }
    }
  })
  Object.values(miscLayers).map(l => {
    if (l.l && l.feas)
      if (addTabs.indexOf('misc') === -1) {
        addTabs.push('misc')
      }
  })*/
  const userLayers = getUserMiscLayers().data;
  const userLayersEnabled = []
  if (userLayers.length) {
    userLayers.map(node => {
      if (!blind) {
        if (node.show && node.params.enableGetFeatureInfo) {
          if (addTabs.indexOf('user') === -1) {
            addTabs.push('user')
          }
          userLayersEnabled.push(node)
        }
      } else if (node.blind_show && node.params.enableGetFeatureInfo) {
        if (addTabs.indexOf('user') === -1) {
          addTabs.push('user')
        }
        userLayersEnabled.push(node)
      }
    })
  }

  /*if (addTabs.length === 0)
    return;*/

  const size = map.getSize()

  let info = []

  /**
   * Загружает сведения о пажарах. Добавляет их в попу.
   * @param today {boolean}- true - сегодняшний, false - архивный
   * @returns {Promise<boolean>}
   */
  async function getFiresInfo(today) {
    const buffer = 20;
    let layer = 'firepoints_current';
    if (!today)
      layer = 'firepoints_arch';
    const url = process.env.REACT_APP_GEOSERVER_URL + `tiles/geoserver/wms?service=WMS&version=1.0.0&request=GetFeatureInfo&layers=${layer}&srs=EPSG:4326&bbox=${bounds.toBBoxString()}&width=${size.x}&height=${size.y}&query_layers=${layer}&buffer=${buffer}&info_format=application/json&x=${point.x.toFixed(0)}&y=${point.y.toFixed(0)}&exceptions=application/json`;
    try {
      const res = await axios.get(url)
      if (res.data?.features?.length) {
        const props = res.data.features[0].properties;
        const coordinates = res.data.features[0]["geometry"]["coordinates"]
        const sat = props.instrument.trim();
        const header = `${sat} обнаружил пожар`;
        const date_str = props["acq_date"].trim()
        const date_r = new Date(Date.parse(date_str))
        const date = formatDateFromObjToStr(date_r)
        const day = props["daynight"][0] === 'D' ? 'день' : 'ночь';
        const bright = props["bright"];
        let confidence = parseInt(props["confidence"].trim())
        if (!confidence) {
          const conf = props["confidence"].trim()
          switch (conf) {
            case 'n':
              confidence = 'средняя';
              break;
            case 'h':
              confidence = 'высокая';
              break;
            case 'l':
              confidence = 'низкая';
          }
        } else {
          confidence += '%';
        }
        let data = `<div class='popup-row popup_nowrap'>${header}<br></div>`;
        data += '<table class="w-100"><tbody>';
        if (date) {
          data += `<tr><td class="popup_padding_right popup_nowrap">Дата обнаружения</td><td>${date}</td></tr>`;
        }
        if (day) {
          data += `<tr><td class=" popup_padding_right">Режим</td><td>${day}</td></tr>`;
        }
        if (bright) {
          data += `<tr><td class=" popup_padding_right">Яркость</td><td>${bright}</td></tr>`;
        }
        if (confidence) {
          data += `<tr><td class=" popup_padding_right">Вероятность</td><td>${confidence}</td></tr>`;
        } else {
          data += `<tr><td class=" popup_padding_right">Вероятность</td><td>нет</td></tr>`;
        }
        if (coordinates) {
          data += `<tr><td class=" popup_padding_right">Широта</td><td>${coordinates[1]}</td></tr>`;
          data += `<tr><td class=" popup_padding_right">Долгота</td><td>${coordinates[0]}</td></tr>`;
        }
        data += '</tbody></table>';
        data += '</div>';
        info.push({name: 'Пожар', html: data})
        return true;
      }
    } catch (err) {
      console.error(err)
      return false;
    }
  }

  //Аренды
  if (miscLayers['russia_rent']?.l) {
    const url = process.env.REACT_APP_GEOSERVER_URL + `tiles/geoserver/wms?service=WMS&version=1.0.0&request=GetFeatureInfo&layers=dln%3Arussia_rent&srs=EPSG:4326&bbox=${bounds.toBBoxString()}&width=${size.x}&height=${size.y}&query_layers=dln%3Arussia_rent&info_format=application/json&x=${point.x.toFixed(0)}&y=${point.y.toFixed(0)}&exceptions=application/json`;
    await axios.get(url).then(res => {
      const feas = res.data.features;
      if (feas.length) {
        const props = feas[0].properties;
        let data = `<div class="ol-popup">`;
        data += `<div class='popup-header'>Арендатор:</div><div class='popup-row popup_minwidth150'>${props['companynam']}<br>`;
        if (props['inn'])
          data += `ИНН: ${props['inn']}`
        data += '</div>'
        if (props['constituen'])
          data += `<div class='popup-header'>Регион:</div><div class='popup-row popup_minwidth150'>${props['constituen']}</div>`;
        if (props['forestryna'])
          data += `<div class='popup-header'>Лесничество:</div><div class='popup-row popup_minwidth150'>${props['forestryna']}</div>`;
        if (props['subforestr'])
          data += `<div class='popup-header'>Уч. лесничество:</div><div class='popup-row popup_minwidth150'>${props['subforestr']}</div>`;
        data += `</div>`;
        info.push({name: 'Аренда', html: data})
      }
    }).catch(err => {
      console.warn(err.message)
      errorMessage = errorOnDataRecieved;
    })
  }

  //Пожары
  if (miscLayers['firepoints_current']?.l) {
    //Если нет инфы в сегодняшних пожарах, запрашиваем архивные.
    if (!await getFiresInfo(true)) {
      await getFiresInfo(false)
    }
  }

  //Пользовательские
  let ind = 0;
  while (ind < userLayersEnabled.length) {
    const node = userLayersEnabled[ind]
    if (blind && map.hasLayer(node.blind_layer) || (!blind && (map.hasLayer(node.layer)))) {
      const url = new URL(node.url)
      const params = parseParamsFromUrl(url, null) //здесь панель не нужна, така как не создается слой
      params.request = 'GetFeatureInfo';
      params.version = '1.0.0';
      params.bbox = bounds.toBBoxString()
      params.width = size.x;
      params.height = size.y;
      params.x = point.x.toFixed(0)
      params.y = point.y.toFixed(0)
      params.srs = 'EPSG:4326';
      params.query_layers = params.layers;

      if (params.format)
        delete params.format;
      if (params.transparent)
        delete params.transparent;
      params.info_format = 'application/json';
      params.exceptions = params.info_format;
      let paramsString = '';
      Object.entries(params).map(([key, value]) => {
        paramsString += `${key}=${value}&`;
      })
      const href = url.protocol + '//' + url.host + url.pathname + '?' + paramsString;
      await axios.get(href).then(res => {
        if (res.data.features?.length) {
          res.data.features.map(fea => {
            const htm = createHTMLPropertiesList(fea?.properties)
            if (htm)
              info.push({name: node.name, html: htm})
          })
        }
      }).catch(err => console.error(err))
    }
    ind++;
  }

  //Sentinel
  if (baseLayers['sentinel'].show) {
    const layer = baseLayers.sentinel;
    const time1 = getSentinelDate(layer.time1)
    const time2 = getSentinelDate(layer.time2)
    const extent = `${bounds.getSouthWest().lat},${bounds.getSouthWest().lng},${bounds.getNorthEast().lat},${bounds.getNorthEast().lng}`;
    const url = process.env.REACT_APP_BASE_URL + `api/v1/gis/santinel_px/wms?SERVICE=WMS&REQUEST=GetFeatureInfo&LAYERS=${layer.channel}&QUERY_LAYERS=${layer.channel}&WIDTH=${size.x}&HEIGHT=${size.y}&I=${point.x.toFixed(0)}&J=${point.y.toFixed(0)}&CRS=EPSG:4326&BBOX=${extent}&INFO_FORMAT=application%2Fjson&TIME=${time1}/${time2}&MAXCC=${layer.cc}`;
    await dataAPI.tiles.getFeatureInfo(url)
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          if (res.data.features.length) {
            const fea = res.data.features[0].properties;
            let data = `<div class="ol-popup">
                      <div class='popup-row popup_nowrap'>Дата снимка: ${localizeDateTimeFormat(fea.date, fea.time)}</div>
                      <div class='popup-row popup_nowrap'>Облачность: ${fea['cloudCoverPercentage']}%</div>
                    </div>`;
            info.push({name: 'Sentinel', html: data})
          }
        } else
          console.warn(res)
      })
      .catch(err => {
        errorMessage = errorOnDataRecieved;
        console.error(err.message)
      })
  }

  //Договор/Лесничества/Квартлал
  await dataAPI.getFeatureInfo.byPoint(coordinates[0], coordinates[1]).then(res => {
    let htm = '<div class="ol-popup">';
    htm += `<div class='popup-header'>Координаты:</div>`;
    htm += '<div class="popup-row">';
    htm += `<div class="popup_nowrap ">Широта: ${coordinates[0]}</div>`;
    htm += `<div class="popup_nowrap">Долгота: ${coordinates[1]}</div>`;
    htm += '</div>';
    if (res.data.quarter) {
      htm += `<div class='popup-header'>Квартал:</div>`;
      htm += '<div class="popup-row">'
      htm += `<div class="popup_nowrap">Номер: ${res.data.quarter.number || ''}</div>`;
      htm += `<div class="popup_nowrap">Лесничество: ${res.data.quarter.x_forestry?.name || ''}</div>`;
      htm += `<div class="popup_nowrap">Уч. лесничество: ${res.data.quarter.x_district_forestry?.name || ''}</div>`;
      htm += '</div>';
    }
    htm += '</div>';
    info.unshift({name: 'Инфо', html: htm})

    let button = '';

    if (res.data.rental_contract) {
      if (interactive.map_popup_edit_workspace_button && getProjects()?.data) { //кнопка настроек воркспейса
        /*button = `<button class="iconButtonCss popup_setting_button" style="float: right"
              title="Настройки рабочей области"
              type="button"
              data="${res.data.rental_contract.id}"
              id="map_popup_edit_workspace_button"
            >
            ${settingsSVG()}
          </button>`;*/
        button = `<hr /><div class="cp_ca_buttons_panel popup_centered_flex">
            <a id="map_popup_edit_workspace_button" data="${res.data.rental_contract.id}" href="#" 
               class="cp_ca_report_link popup_button_120px" style="margin-top: 5px">${settingsString}</a>
            </div>`;
      }
      htm = `<div class="popup_minwidth150 popup_wp_name_style">
               <p class="bold m-0">${res.data.rental_contract.number}</p>
               ${button}
              </div>`;
      info.unshift({name: 'Договор', html: htm})
    }
  })
  //.catch(err => console.error(err))

  let curTab = 0;

  function setTabOnClicks() {
    let ind = 0;
    while (ind < info.length) {
      const ele = popupObject.getElement().querySelector(`#popup_tab_button_${ind}`)
      ele.onclick = changeTab;
      ind++;
    }
  }

  function changeTab(event) {
    event.stopPropagation()
    event.preventDefault()
    const index = parseInt(event.target.id.replace('popup_tab_button_', ''))
    if (!isNaN(index) && index !== curTab) {
      curTab = index;
      const html = getPopupHTML(index)
      changeMapPopup(html, interactive)
      setTabOnClicks()
    }
  }

  function getPopupHTML(curTab) {
    let ind = 0;
    let html = '<div class="popup_tabs">';
    info.map(item => {
      let style = window.elz_theme.palette.text.disabled;
      if (ind === curTab)
        style = window.elz_theme.palette.warning.main;
      html += `<button id="popup_tab_button_${ind}" class="popup_tab_button" style="color: ${style};">${item.name}</button>`;
      ind++;
    })
    html += '</div>';
    html += info[curTab].html;
    return html;
  }

  if (popupObject === getMapPopup()) {
    if (info.length) {
      let ind = 0;
      while (ind < info.length) { //добавляем в интерактив кнопки переключения вкладок
        interactive[`popup_tab_button_${ind}`] = {type: 'click', f: changeTab}
        ind++;
      }
      const html = getPopupHTML(curTab)
      changeMapPopup(html, interactive)
    } else {
      changeMapPopup(`<div class="ol-popup popup_nowrap"><div class='popup-header'>${noInfoString}</div></div>`)
    }
  }
  return errorMessage;
}
