import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import {initBaseLayers} from "../Overlay/BaseLayers/BaseLayersCommon.js";
import {Box} from "@mui/material";
import {initMapLayers} from "./ELZMapReducer.js";
import {initMiscLayers} from "../LeftPanel/Context/MiscLayers/MiscLayersCommon";
import {getMap1, mapDivId, setDrawLayerGroup, setMap1} from "./GlobalObjects";
import {getFeatureInfoData} from "./Common/getFeatureInfo";
import {Overlay} from '../Overlay/Overlay.jsx';
import {Zoom} from "./Zoom/Zoom";
import {loadCuttingAreas} from '../LeftPanel/Context/CAs/CuttingsAreas.js';
import {Filters} from "./Filters/Filters";
import './Common/libs/wms_headers';
import './Common/css/leaflet.css';
import {loadRoads} from "../LeftPanel/Context/Roads/roads";
import {initSettingsTimer, setUserSettings} from "./Common/userSettings";
import {getSideToSideControl, initBlind} from "./Blind/SideBySide";
import {CreatePillarDialog} from "../LeftPanel/Context/Infrastructure/Pillars/CreatePillarDialog";
import {loadPillars} from "../LeftPanel/Context/Infrastructure/Pillars/pillars";
import {pageSizesStd} from "../LeftPanel/Context/Printing/PrintMap";
import {getProjects, initProjects} from "../LeftPanel/Context/Projects/ProjectsCommon";
import {initUserMiscLayers} from "../LeftPanel/Context/MiscLayers/UserLayers/UserLayers";
import {cannotIDo, getTariff} from "./Common/tariffs";
import {urlGenerator} from "../../../utils/urlGenerator";
import {redrawLayersIfNeeded} from "./Common/redrawLayersIfNeeded";
import {CADataDialog} from "../RightPanel/RPPlotData/CAData/CADataDialog";
import {MobileBanner} from "./Common/Dialog/MobileBanner";
import {removeTime} from "./Common/DateTimeFunctions";
import {getDateFromLocalizedString} from "./Common/MiscFunction";
import {closeMapPopup, getMapPopup,} from "./MapPopup/mapPopup";
import {CoordinatesEditor} from "./Common/Dialog/CoordinatesEditor/CoordinatesEditor";
import {deleteRedArrow} from "./Common/redArrow";
import {loadWarehouses} from "../LeftPanel/Context/Infrastructure/Warehouses/WarehouseCommon";
import {loadViolations} from "../LeftPanel/Context/Infrastructure/Violations/ViolationCommon";
import {getEditesStockpiles} from "../LeftPanel/Context/CAs/stockpiles";
import {openWorkspaceSettingsDialog} from "../LeftPanel/Context/Projects/WorkspaceSettings/workspaceReducer";
import {dispatch} from "../../Common/misc_functions";
import {WorkspaceSettingsDialog} from "../LeftPanel/Context/Projects/WorkspaceSettings/WorkspaceSettingsDialog";

const position = [56.04774900419961, 92.90786057710649];
let observer = null;

//ВАЖНО! Не удалять.
/*(function(){
  var originalInitTile = L.GridLayer.prototype._initTile
  L.GridLayer.include({
    _initTile: function (tile) {
      originalInitTile.call(this, tile);
      var tileSize = this.getTileSize();
      tile.style.width = tileSize.x + 2 + 'px';
      tile.style.height = tileSize.y + 2 + 'px';
    }
  });
})()*/

export function resetMapHighlightedItem() {
  const map = getMap1()
  const item = map.elz_highlighted;
  if (item) {
    item.resetStyleFunction()
    delete map.elz_highlighted;
  }
}

/**
 * Устанавливает подсвеченный на карте слой, что потом убрать подсветку по клику
 * @param item {{resetStyleFunction: function}}
 */
export function setMapHighlightedItem(item) {
  resetMapHighlightedItem()
  getMap1().elz_highlighted = item;
}

/**
 * Обработка события клика по карте.
 *
 * @param {object} event - объект события клика
 * @return {undefined} - нет возвращаемого значения
 */
function showMapPopup(event) {
  const map = getMap1()

  resetMapHighlightedItem()
  deleteRedArrow()
  if (getMapPopup()) {
    closeMapPopup()
    return;
  }

  const coordinates = [event.latlng.lat, event.latlng.lng];
  const containerPoint = event.containerPoint;
  const pencilDrawing = window.store.getState()["measuringReducer"].pencilSelection.enable;
  if (!map.pm.globalDrawModeEnabled() && !pencilDrawing && !getEditesStockpiles().length) {
    let rectX = 999999;
    const control = getSideToSideControl()
    if (control) {
      rectX = control._divider.style.left.replaceAll('px', '')
    }
    const interactive = {}
    if (!cannotIDo.adminAction()) {
      interactive.map_popup_edit_workspace_button = {
        type: 'click',
        f: (event) => {
          closeMapPopup()
          const wpId = event.target.getAttribute('data')
          const node = getProjects().data.find(wp => wp.id.toString() === wpId)
          if (node) {
            dispatch(openWorkspaceSettingsDialog(node, false))
          }
        }
      }
    }
    getFeatureInfoData(coordinates, containerPoint, rectX < containerPoint.x, interactive).then()
  }
}

export const ELZMapCP = (props) => {
  const [refresh, setRefresh] = useState(false)
  const [bannerShow, setBannerState] = useState(false)
  const {nobanner, setNoBanner, curTab} = props
  const userState = useSelector(state => state.userReducer)
  const mapParentDiv = useRef()
  const papaDiv = useRef()
  const isMobileDevice = useSelector(state => state.userReducer.isMobileDevice)

  useSelector(state => state.printingReducer)

  let map = getMap1();
  const dispatch = useDispatch()

  const mapDivParentCSS = {
    width: '100%',
    height: '100%',
    position: 'relative',
  }

  const parentParentCSS = {
    width: '100%',
    height: '100%',
    border: '1px solid' + window.elz_theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    flex: 1,
    boxSizing: 'border-box',
  }

  function setPrintPreviewObserver() { // обсервер для режима предпросмотра печати
    const mapDiv = mapParentDiv.current;
    if (!mapDiv)
      return;
    const htmlDiv = papaDiv?.current;
    if (htmlDiv) {
      if (observer) {
        observer.unobserve(htmlDiv)
        observer.disconnect()
      }
      observer = new ResizeObserver(() => {
        const curTab = window.store.getState()["leftDrawerReducer"].curTab;
        if (map && curTab === 'printing') {
          const papaDivObject = papaDiv.current;
          if (papaDivObject) {
            const printingState = window.store.getState()["printingReducer"]
            let divWidth = papaDivObject['offsetWidth'] * 0.95;
            let divHeight = papaDivObject['offsetHeight'] * 0.95;
            const page = pageSizesStd[printingState.pageSize];
            const aspect = page.height / page.width;
            if (printingState.orientation === 'Landscape') {
              let calcHeight = divWidth / aspect;
              if (divHeight <= calcHeight) {
                calcHeight = divHeight;
                divWidth = calcHeight * aspect;
              }
              mapDiv['style'].width = `${divWidth}px`;
              mapDiv['style'].height = `${calcHeight}px`;
              mapDiv['style'].border = `solid 3px green`;
            } else {
              let calcWidth = divHeight / aspect;
              if (divWidth <= calcWidth) {
                calcWidth = divWidth;
                divHeight = calcWidth * aspect;
              }
              mapDiv['style'].width = `${calcWidth}px`;
              mapDiv['style'].height = `${divHeight}px`;
              mapDiv['style'].border = `solid 3px green`;
            }
          }
          map.invalidateSize()
        }
      })
      observer.observe(htmlDiv)
      return () => {
        observer.disconnect()
      }
    }
  }

  useEffect(() => {
    const map = getMap1()
    // map init
    if (window.location.pathname !== urlGenerator().login) {
      if (!map) {
        if (userState.userInfo) {
          L.DomEvent.fakeStop = () => true; //dumb for L.VectorGrid onclick event
          let zoom = window.localStorage.getItem('zoom')
          if (!zoom)
            zoom = 12;
          const map = L.map(mapDivId, {
            center: position,
            zoom: zoom,
            zoomDelta: 1,
            zoomSnap: 1,
            minZoom: 0,
            maxZoom: 20,
            wheelPxPerZoomLevel: 120,
            zoomControl: false,
            attributionControl: false,
            doubleClickZoom: false,
          })
          setMap1(map)
          //location
          let center_position = [window.localStorage.getItem('center-lat'), window.localStorage.getItem('center-lng')];
          if (!center_position[0] || !center_position[1]) {
            if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition((position) => {
                  const latit = position.coords.latitude;
                  const longit = position.coords.longitude;
                  map.setView([latit, longit], map.getZoom())
                },
                (error) => {
                  console.error(error)
                  map.setView(center_position, map.getZoom())
                },
                {timeout: 5000, enableHighAccuracy: true}
              )
            }
          } else {
            map.setView(center_position, map.getZoom())
          }
          /*navigator.geolocation.watchPosition(
            (p) => console.log(p),
            (err) => console.log(err)
          )*/

          map.createPane("bottom")
          map.getPane("bottom").style.zIndex = 2;
          map.createPane("base")
          map.getPane("base").style.zIndex = 3;
          map.createPane("sentinel")
          map.getPane("sentinel").style.zIndex = 5;
          map.createPane("user_misc")
          map.getPane("user_misc").style.zIndex = 6;
          map.createPane("bigarenda")
          map.getPane("bigarenda").style.zIndex = 7;
          map.getPane("bigarenda").style.pointerEvents = "none"; //no interactive
          map.createPane("misc")
          map.getPane("misc").style.zIndex = 9;
          map.createPane("perimeters")
          map.getPane("perimeters").style.zIndex = 10;
          map.createPane("misc_fires")
          map.getPane("misc_fires").style.zIndex = 11;
          map.createPane("top")
          //map.getPane("top").style.zIndex = 13;
          map.getPane("top").style.pointerEvents = "none"; //no interactive
          map.getPane("top").style.zIndex = 15;
          map.createPane("warehouses")
          map.getPane("warehouses").style.zIndex = 14;
          map.createPane("cas")
          map.getPane("cas").style.zIndex = 17;
          map.createPane("cas_stockpiles") //штабли
          map.getPane("cas_stockpiles").style.zIndex = 19;
          map.createPane("cas_points") //точки выпилки
          map.getPane("cas_points").style.zIndex = 30;
          map.createPane("roads") //дороги
          map.getPane("roads").style.zIndex = 50;
          map.createPane("cas_markers") //маркеры деляны (комплекс)
          map.getPane("cas_markers").style.zIndex = 53;
          map.createPane("pillars") //столбы
          map.getPane("pillars").style.zIndex = 55;
          map.createPane("shapes") //шейпы
          map.getPane("shapes").style.zIndex = 300;
          map.createPane("layerPane") //рисовалка: слои
          map.getPane("layerPane").style.zIndex = 301;
          map.createPane("markersPane") //рисовалки маркеры
          map.getPane("markersPane").style.zIndex = 302;
          map.createPane("vertexPane") //рисовалка: вершины
          map.getPane("vertexPane").style.zIndex = 350;

          //blind panes
          map.createPane("blind_base")
          map.getPane("blind_base").style.zIndex = 3;
          map.createPane("blind_sentinel")
          map.getPane("blind_sentinel").style.zIndex = 5;
          map.createPane("blind_bigarenda")
          map.getPane("blind_bigarenda").style.zIndex = 7;
          map.getPane("blind_bigarenda").style.pointerEvents = "none"; //no interactive
          map.createPane("blind_user_misc")
          map.getPane("blind_user_misc").style.zIndex = 6;
          map.createPane("blind_misc")
          map.getPane("blind_misc").style.zIndex = 9;
          map.createPane("blind_fires")
          map.getPane("blind_fires").style.zIndex = 11;

          map.createPane("blind_perimeters")
          map.getPane("blind_perimeters").style.zIndex = 203;
          map.createPane("blind_roads")
          map.getPane("blind_roads").style.zIndex = 206;
          map.createPane("blind_pillars")
          map.getPane("blind_pillars").style.zIndex = 206;

          map.elz_zoom = new Zoom(map)
          setUserSettings(userState.userInfo['map_settings'])
          //init blind control
          initBlind(dispatch)
          //init layers
          initBaseLayers(false) //main
          initBaseLayers(true) //blind
          initMiscLayers(false) //main
          initMiscLayers(true) //blind

          //init user layers
          if (cannotIDo.useUserLayers() === 0)
            initUserMiscLayers(dispatch)
          //init drawing
          const drawLayersGroup = L.featureGroup()
          map.pm.setGlobalOptions({
            layerGroup: drawLayersGroup,
            showTooltip: false,
            showTooltipOnHover: false,
            panes: {vertexPane: 'vertexPane', layerPane: 'layerPane', markerPane: 'markersPane'}
          })
          setDrawLayerGroup(drawLayersGroup)
          const selectionLayersGroup = L.featureGroup()
          drawLayersGroup.addTo(map) //нужен не только для шейпов!
          map.pm.setLang('ru')
          initProjects()
          loadCuttingAreas(map) //ALL (!) Cuttings area loading
          loadRoads().then()
          loadPillars()
          loadWarehouses()
          loadViolations()
          initSettingsTimer()
          //map.on("click", mapSingleClick)
          // ------------- //
          function mouseDown(event) {
            map.elz_mdown_latlng = event.containerPoint;
          }

          function mapClick(event) {
            const oldCoords = map.elz_mdown_latlng;
            const newCoords = event.containerPoint;
            if (newCoords && oldCoords && oldCoords.x === newCoords.x && oldCoords.y === newCoords.y) {
              showMapPopup(event)
            }
            delete map.elz_mdown_latlng;
          }

          map.on("mousedown", mouseDown)
          map.on("click", mapClick)
          // ------------- //
          map.on("moveend", () => {
            window.localStorage.setItem('center-lat', map.getCenter().lat.toString())
            window.localStorage.setItem('center-lng', map.getCenter().lng.toString())
            window.localStorage.setItem('zoom', map.getZoom().toString())
          })
          dispatch(initMapLayers(drawLayersGroup, selectionLayersGroup))
          //Обновляем компоненту после создания карты
          //костыль для отображения оверлея в ситуациях, когда карта создана нестандартно
          setRefresh(!refresh)
        }
      } else { // привет от реакта
        const mapContainer = map.getContainer()
        const mapDiv = document.getElementById(mapDivId)
        if (mapContainer !== mapDiv && mapContainer && mapDiv) {
          mapDiv.replaceWith(mapContainer)
          map.invalidateSize()
          redrawLayersIfNeeded()
        }
      }
    }
    let observeFunc = null;
    // print mode
    if (curTab === 'printing') {
      observeFunc = setPrintPreviewObserver()
    } else {
      const mapDiv = mapParentDiv.current;
      if (mapDiv) {
        mapDiv['style'].height = `100%`;
        mapDiv['style'].width = `100%`;
        mapDiv['style'].border = '0';
      }
    }
    if (map)
      map.invalidateSize()
    return () => {
      if (observeFunc) {
        observeFunc()
      }
    }
  })

  // mobile mode
  useEffect(() => {
    const tariff = getTariff()
    if (!bannerShow && isMobileDevice) {
      try {
        const dateCreated = removeTime(getDateFromLocalizedString(tariff.created)).getTime()
        const dateNow = removeTime(new Date()).getTime()
        if (dateCreated === dateNow) {
          setBannerState(true)
        }
      } catch (err) {
        console.error(err)
      }
    }
  }, [isMobileDevice])

  function closeBannerHandle() { // закрыть банер, если зашли с мобилки
    setNoBanner(true)
    setBannerState(false)
  }

  return (
    <>
      <Box style={parentParentCSS} id='cp_map_container' ref={papaDiv}>
        <Box id='map_parent_container' style={mapDivParentCSS} ref={mapParentDiv}>
          <Box id={mapDivId} style={{height: '100%', width: '100%'}}/>
          {map && curTab !== 'printing' && <Overlay/>}
        </Box>
        {
          map &&
          <>
            <CreatePillarDialog/>
            <Filters/>
            <CADataDialog/>
            <CoordinatesEditor/>
            {isMobileDevice && !nobanner && <MobileBanner open={bannerShow} callback={closeBannerHandle}/>}
          </>
        }
      </Box>
      <WorkspaceSettingsDialog/>
    </>
  )
}
