export const defaultState = {
  editor: null,
}

export function setEditingLayerData(data) {
  return {
    type: 'MAP_LAYER_EDITOR_SET_DATA',
    data: data,
  }
}

export function refreshEditorPanel() {
  return {
    type: 'MAP_LAYER_EDITOR_REFRESH_PANEL',
  }
}

export function getLayerEditor() {
  return window.store.getState().layerEditingReducer.editor;
}

export function layerEditingReducer(state = defaultState, action) {
  switch (action.type) {
    case 'MAP_LAYER_EDITOR_SET_DATA':
      return {...state, ...action.data}
    case 'MAP_LAYER_EDITOR_REFRESH_PANEL':
      return {...state}
    default:
      return state;
  }
}
