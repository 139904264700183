import {useDispatch, useSelector} from "react-redux";
import {
  deletePlotPhotoThunk,
  savePlotCommentThunk,
  savePlotPhotosThunk,
} from "../../../../redux/reducers/rightPanelReducer";
import {Box, Button, Divider, Stack} from "@mui/material";
import Comment from "../RPCommonElements/Comment/Comment";
import Photos from "../RPCommonElements/Photos/Photos";
import LastChange from "../RPCommonElements/LastChange/LastChange";
import Status from "./Status/Status";
import Reports from "./Reports/Reports";
import GeoData from "./GeoData/GeoData";
import Data1C from "./Data1C/1CData";
import ShortInfoBlock from "./ShortInfoBlock/ShortInfoBlock";
import {setPlotDataAttr} from "../../../../redux/reducers/plotDataReducer";
import {showCoordinatesEditor} from "../../Map/Common/Dialog/CoordinatesEditor/coordinatesEditorReducer";
import {clearCoordinatesDialogInfo} from "../../Map/Common/Dialog/CoordinatesEditor/CoordinatesEditor";
import {cannotIDo} from "../../Map/Common/tariffs";
import {setCANewCoordinates} from "../../LeftPanel/Context/CAs/CuttingsAreas";
import {PlotAddData} from "./PlotAddData";
import {
  caAllocatedStatusString,
  caDeclarationYearString,
  caFellingYearString,
  caPlanYearString,
  reforestrationActivtiesString
} from "../../Map/Common/Strings_RU";

const RpPlotData = () => {
  const dispatch = useDispatch()
  const plotData = useSelector(state => state.rightPanelReducer.plotData)
  const plotLayer = useSelector(state => state.rightPanelReducer.plotLayer)
  const selectedElement = useSelector(state => state.rightPanelReducer.selectedElement)
  const {comment: commentObj, photos, id: plotDataId} = plotData || {};
  const iAmReadOnly = !!cannotIDo.editorAction()
  const using_1C = !cannotIDo.use1CData()
  const fullView = !cannotIDo.fullViewAction()

  const addData = {
    [caAllocatedStatusString]: plotData?.cameral,
    [reforestrationActivtiesString]: plotData.restorations && plotData.restorations.length ? plotData.restorations : null,
    [caPlanYearString]: plotData?.plan_year,
    [caDeclarationYearString]: plotData?.declaration_year,
    [caFellingYearString]: plotData?.felling_year,
  }
  const isAddData = Object.values(addData).some(data => !!data)
  function showPlotDataEditorWindow() {
    dispatch(setPlotDataAttr({showUpdatePlotModal: true, selectedPlot: plotLayer.elz_properties}))
  }

  function showCoordinatesEditorWindow() {
    dispatch(showCoordinatesEditor(
      plotLayer.getLatLngs()[0],
      'Polygon',
      (latlngs) => {
        clearCoordinatesDialogInfo()
        const json = {
          geometry: {
            type: "Polygon",
            coordinates: [[]]
          }
        }
        latlngs.map(coo => {
          json.geometry.coordinates[0].push([coo.lng, coo.lat])
        })
        json.geometry.coordinates[0].push([latlngs[0].lng, latlngs[0].lat])
        //dispatch(patchPlotCoordinatesThunk(plotLayer, latlngs, json))
        setCANewCoordinates(plotLayer, latlngs)
      }))
  }

  return (
    <Box>
      <Stack spacing={1}>
        <Stack direction={'row'} spacing={0.5} sx={{pt: 1}}>
          {!iAmReadOnly &&
            <Button size={'small'} variant={'outlined'} fullWidth onClick={showPlotDataEditorWindow}>
              Информация
            </Button>
          }
          <Button size={'small'} variant={'outlined'} fullWidth onClick={showCoordinatesEditorWindow}>
            Координаты
          </Button>
        </Stack>
        {isAddData ? <PlotAddData data={addData}/> : <Divider/>}
        <Status status={selectedElement?.status} readOnly={iAmReadOnly}/>
        {fullView && <ShortInfoBlock/>}
        <Divider/>
        {using_1C && fullView &&
          <>
            <Data1C/>
            <Divider/>
          </>
        }
        {!iAmReadOnly &&
          <>
            <Reports/>
            <Divider/>
          </>
        }
        <Comment commentObj={commentObj} objectId={plotDataId} saveCommentThunk={savePlotCommentThunk}/>
        <Divider/>
        <Photos photos={photos || []} objectId={plotDataId} savePhotosThunk={savePlotPhotosThunk}
                deletePhotoThunk={deletePlotPhotoThunk} name={plotData?.name}/>
        <Divider/>
        <LastChange objectData={plotData}/>
        <Divider/>
        <GeoData/>
      </Stack>
    </Box>
  )
}

export default RpPlotData;
