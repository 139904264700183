import {ZoomComponent} from "../Map/Zoom/ZoomComponent";
import {Copyright} from "./Copyright";
import Box from "@mui/material/Box";

export function BottomPanel() {

  const leftPanelStyle = {
    position: 'absolute',
    bottom: '1vh',
    left: '1vh',
  }

  return (
    <Box
      style={leftPanelStyle}>
      <ZoomComponent/>
      <Copyright/>
    </Box>
  )
}
