import {useState} from "react";
import {TextField} from "@mui/material";

export function FTextField({
                             value, label, style, nofocus, variant, error, _endEditing, _cancelEdit,
                             required = false, inputProps = {}, InputProps = {}
                           }) {
  const [valueEdit, setValueEdit] = useState(value || '')
  const [focus, setFocus] = useState(false)

  return (
    <TextField
      error={error}
      label={label}
      autoComplete='off'
      style={{...style, width: '100%'}}
      variant={variant ? variant : 'standard'}
      required={required}
      size={'small'}
      inputRef={input => {
        if (input && !nofocus && !focus) {
          input.focus()
          setFocus(true)
        }
      }}
      inputProps={inputProps}
      InputProps={InputProps}
      value={valueEdit}
      onChange={(e) => setValueEdit(e.target.value)}
      onBlur={() => _endEditing(valueEdit, 'blur')}
      onKeyDown={(event) => {
        event.stopPropagation()
        if (event.key === 'Enter') {
          _endEditing(valueEdit, 'enter')
        } else if (event.key === 'Escape') {
          if (_cancelEdit)
            _cancelEdit(valueEdit)
        }
      }
      }
    />
  )
}
