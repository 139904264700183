import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Checkbox, Table, TableBody, TableCell, TableHead, TableRow, Typography,} from "@mui/material";
import {Loading} from "../../../../Main/Map/Common/Loading";
import {otherDataAPI} from "../../../../../api/api";
import {handleErrors} from "../../../../../redux/commonReducerFunctions/ThunkErrorsHandler";
import {cannotIDo, getTariff} from "../../../../Main/Map/Common/tariffs";
import {changeOrgPageAttrs} from "../../../organizationPageReducer";
import {ErrorAlert} from "../../../../Common/ErrorAlert";
import {postGraySpanStyle} from "../../../../Main/Map/Common/Styles";

const headers = [
  "",
  "Сотрудник",
  "Отведена",
  "Задекларирована",
  "В разработке",
  "Разработана",
  "Идёт вывозка",
  "Закрыта",
  "В архиве"
]
export const headerKeys = [
  "",
  "full_name",
  "show_reserved_plots",
  "show_declared_plots",
  "show_development_plots",
  "show_developed_plots",
  "show_removal_plots",
  "show_closed_plots",
  "show_archive_plots"
]

const HeaderCell = ({header, onHeaderChange, allChecked}) => {
  return (
    <TableCell style={{whiteSpace: 'nowrap'}}>
      {header === "Сотрудник" || header === "" ? header :
        <>
          <Checkbox
            checked={allChecked}
            onChange={() => onHeaderChange(header)}
          />
          {header}
        </>
      }
    </TableCell>
  )
}

export function CASPermissionsTable({workspace}) {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState({loading: false, error: false})
  const saving = useSelector(state => state.organizationPageReducer.dialogSaving)
  const employees = useSelector(state => state.organizationPageReducer.employeesCasPermissions)
  const iAmOwner = !cannotIDo.ownerAction()
  const myId = getTariff().userID;

  useEffect(() => {
    if (!employees && !loading.error && !loading.loading && !saving) {
      setLoading({loading: true, error: false})
      otherDataAPI.workspaces.getOrganizationCasStatusAccess(workspace.id).then(res => {
        dispatch(changeOrgPageAttrs({
          employeesCasPermissions: [...res.data["users_plots"]],
          oldData1: structuredClone(res.data["users_plots"])
        }))
        setLoading({loading: false, error: false})
      }).catch(err => {
        setLoading({loading: false, error: true})
        handleErrors(dispatch, err)
      })
    }
  })

  function isRowChecked(row) {
    return headerKeys.slice(2).every(key => row[key])
  }

  function handleRowCheckboxChange(rowIndex) {
    const row = employees[rowIndex]
    const allChecked = !headerKeys.slice(2).every(key => row[key])

    const updatedData = employees.map((row, i) =>
      i === rowIndex
        ? headerKeys.slice(2).reduce((acc, key) => {
          acc[key] = allChecked;
          return acc;
        }, {...row})
        : row
    )
    dispatch(changeOrgPageAttrs({employeesCasPermissions: updatedData}))
    dispatch(changeOrgPageAttrs({changesInDialog: true}))
  }

  function handleHeaderChange(header) {
    const key = headerKeys[headers.indexOf(header)]
    const updatedData = [...employees]
    let notChecked = !employees.every(row => (iAmOwner || myId === row["id_user"] || !row.is_admin) ? row[key] : true)
    updatedData.forEach(row => {
      if (iAmOwner || myId === row["id_user"] || !row.is_admin) {
        row[key] = notChecked;
      }
    })
    dispatch(changeOrgPageAttrs({employeesCasPermissions: updatedData}))
    dispatch(changeOrgPageAttrs({changesInDialog: true}))
  }

  function handleCheckboxChange(rowIndex, columnIndex) {
    const key = headerKeys[columnIndex];
    const updatedData = employees.map((row, i) => i === rowIndex ? {
      ...row,
      [key]: !row[key]
    } : row)
    dispatch(changeOrgPageAttrs({employeesCasPermissions: updatedData}))
    dispatch(changeOrgPageAttrs({changesInDialog: true}))
  }

  if (loading.loading)
    return <Loading/>

  if (loading.error)
    return (
      <ErrorAlert text={'Произошла ошибка при загрузке списков доступа к делянам'} style={{mt: 2}}
                  onClick={() => {
                    setLoading({loading: false, error: false})
                  }}
      />
    )

  if (!employees)
    return null;

  if (employees.length === 0)
    return <Typography>Нет доступных сотрудников</Typography>

  return (
    <Table>
      <TableHead>
        <TableRow>
          {headers.map((header, index) =>
            <HeaderCell
              key={index}
              header={header}
              onHeaderChange={handleHeaderChange}
              allChecked={
                index === 0
                  ?
                  false
                  :
                  employees.length > 0 && employees.every(
                    row => (iAmOwner || myId === row["id_user"] || !row.is_admin) ? row[headerKeys[index]] : true)
              }
            />
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {employees.map((row, rowIndex) => {
          const rowDisabled = saving || (!iAmOwner && row.is_admin && myId !== row["id_user"])
            return (
              <TableRow key={rowIndex} className="hoverTableRow">
                <TableCell>
                  <Checkbox
                    disabled={rowDisabled}
                    checked={isRowChecked(row)}
                    onChange={() => handleRowCheckboxChange(rowIndex)}
                  />
                </TableCell>
                {headerKeys.slice(1).map((key, cellIndex) =>
                  <TableCell key={cellIndex}>
                    {cellIndex === 0
                      ?
                      <>
                        <span>{`${row["full_name"]}`}</span>
                        <span style={postGraySpanStyle}>, {row.post}</span>
                      </>
                      :
                      <Checkbox
                        disabled={rowDisabled}
                        checked={row[key]}
                        onChange={() => handleCheckboxChange(rowIndex, cellIndex + 1)}
                      />
                    }
                  </TableCell>
                )}
              </TableRow>
            )
          }
        )}
      </TableBody>
    </Table>
  )
}
