import {Stack, Typography} from '@mui/material';
import CloseButton from "./CloseButton/CloseButton";

function Head({name}) {
  return (
    <Stack direction={'row'} className={'right-panel-header'}>
      <div style={{flex: 1, position: 'relative'}}>
        <div style={{position: 'absolute', inset: 0,}}>
          <Typography
            variant={'h6'}
            sx={{fontSize: 18, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
          >
            {name}
          </Typography>
        </div>
      </div>
      <CloseButton/>
    </Stack>
  )
}

export default Head;
