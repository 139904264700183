import {
  coordinatesSVG,
  deleteSVG,
  eyeSVG,
  markersIconsList,
  pencilSVG,
  resetSVG,
  saveSVG,
  speciesSVG
} from "../Common/svg/Svgs";
import '../Common/css/popup.css';
import '../Common/css/misc.css';
import {
  ca1CBlockingEditingString,
  caCommonInfoEditString,
  cancelText,
  caPencilSelect,
  casString,
  caStatusChangeString,
  consolidatedReportString,
  coordinatesEditorString,
  createCAString,
  editString,
  moreString,
  pillarDeleteString,
  pillarHideString,
  plotStockpilePopupTitle,
  reportIL1,
  reportIL1CreateReportString,
  reportPDFString,
  roadDeleteTitleString,
  roadEditTitleString,
  roadHideTitleString,
  warehouseCreateString
} from "../Common/Strings_RU";
import {calculateAreanAndDistance, getLayerLength} from "../Common/MiscFunction";
import {cannotIDo} from "../Common/tariffs";
import {getMap1} from "../GlobalObjects";
import L from "leaflet";
import {closeMapPopup, getMapPopup} from "./mapPopup";
import {deleteRedArrow} from "../Common/redArrow";
import {getRoads} from "../../LeftPanel/Context/Roads/roads";
import {resetMapHighlightedItem} from "../ELZMapCP";
import {getSpeciesColor} from "../../LeftPanel/Context/CAs/CuttingsAreas";

const savingDumbString = '<p class="popup_nowrap bold">Идет сохранение...</p><p></p>Пожалуйста, дождитесь завершения операции.</p>';

/**
 * Проверяет, можно ли открыть попу. Если попа уже открыта, закрывает её и возвращает false.
 * Удяляет красную стрелку (в любом случае).
 * Блокирует ивент, если он задан (в любом случае).
 * @param event - leaflet click event
 * @returns {boolean}
 */
export function prepareToOpenPopup(event) {
  deleteRedArrow()
  resetMapHighlightedItem()
  if (getMap1().pm.globalDrawModeEnabled()) return false;
  if (window.store.getState()["measuringReducer"].pencilSelection.enable) return false;
  if (event) {
    L.DomEvent.preventDefault(event)
    L.DomEvent.stopPropagation(event)
  }
  if (getMapPopup()) {
    closeMapPopup()
    return false;
  }
  return true;
}

function getCADataTable(props) {
  const props1c = props.properties_1c;
  let popup_body = '';

  popup_body += '<table class="ca_popup_table"><tbody>';
  let val1 = "- ", val2 = "- ";
  if (props1c && props1c['ПоказателиПроц'] && props1c['Показатели']) {
    val1 = props1c['ПоказателиПроц'].toFixed(0)
    val2 = props1c['Показатели'].toFixed(2)
  }
  popup_body += `<tr>
        <td class="popup_table_name">Показатели:</td>
        <td class="popup_table_col1"><span class="bold">${val1}</span>%</td>
        <td class="popup_table_col2"><span class="bold">${val2}</span>м&sup3;/га</td>
    </tr>`;
  val1 = "- ";
  if (props1c && props1c['ПланВырубки']) {
    val1 = props1c['ПланВырубки'].toFixed(0);
    popup_body += `<tr>
        <td class="popup_table_name popup_nowrap">План вырубки:</td>
        <td class="popup_table_col1"><span class="bold">${val1}</span>м&sup3;</td>
       </tr>`;
  }

  val1 = "- ";
  val2 = "- ";
  if (props1c && props1c['ОбъемПоДекларации'] && props.square) {
    val1 = props1c['ОбъемПоДекларации'].toFixed(0);
    val2 = props.square.toFixed(2);
  }
  popup_body += `<tr>
        <td class="popup_table_name">Декларация:</td>
        <td class="popup_table_col1"><span class="bold">${val1}</span>м&sup3;</td>
        <td class="popup_table_col2"><span class="bold">${val2}</span>га</td>
       </tr>`;
  //const complex = '';
  val1 = "- ";
  //val2 = "- ";
  if (props1c && props1c['ОбъемПоЗаготовке']) {
    val1 = props1c['ОбъемПоЗаготовке'].toFixed(0)
    //val2 = complex;
  }
  popup_body += `<tr>
        <td class="popup_table_name">Заготовка:</td>
        <td class="popup_table_col1"><span class="bold">${val1}</span>м&sup3;</td>
      </tr>`;
  val1 = "- ";
  if (props1c && props1c['ПоследняяБригада']) {
    val1 = props1c['ПоследняяБригада']
  }
  popup_body += `<tr>
      <td class="popup_table_name">Бригада:</td>
      <td class="popup_table_col1" colspan="2"><span class="bold">${val1}</span></td><td></td>
    </tr>`;
  val1 = "- ";
  if (props1c && props1c['ОбъемПоЗамеру']) {
    val1 = props1c['ОбъемПоЗамеру']
  }
  popup_body += `<tr>
      <td class="popup_table_name">Замер:</td>
      <td class="popup_table_col1"><span class="bold">${val1}</span>м&sup3;</td><td></td>
    </tr>`;
  val1 = "- ";
  if (props1c && props1c['ОбъемПоВывозке']) {
    val1 = props1c['ОбъемПоВывозке']
  }
  popup_body += `<tr>
        <td class="popup_table_name">Вывозка:</td>
        <td class="popup_table_col1"><span class="bold">${val1}</span>м&sup3;</td><td></td>
      </tr>`;
  popup_body += '</tbody></table>';
  return popup_body;
}

export function getCAPopup(layer, interactive) { //get ca's popup
  const fullView = !cannotIDo.fullViewAction()
  const props = layer.elz_properties;
  let popup_body = '<div class="popup_body ca_popup_body">';
  //header
  popup_body += `<div class="ca_header_flex">`;
  popup_body += `<div class="bold flex-full-width popup_break_word">`;
  popup_body += props.name;
  popup_body += '</div>';

  if (interactive.ca_popup_edit_button)
    popup_body += `<a id='ca_popup_edit_button' class="ca_poupup_edit_button" href="#" title="${caCommonInfoEditString}"><img src="https://delyana.ru/img/pencil.svg" alt="${editString}"></a>`;
  if (!cannotIDo.use1CData())
    popup_body += `<img class="popup_1c_logo" src="https://delyana.ru/img/icons8-1c-30.png" alt='1C' title="${ca1CBlockingEditingString}">`;
  popup_body += '</div>';

  if (interactive.ca_status_data_button)
    popup_body += `<div class="ca_edit_info_button_div">
          <a 
            id='ca_status_data_button' 
            title="${caStatusChangeString}" 
            href="#" 
            class="cp_ca_report_link"
            style="margin: 0"
          >
           ${props.status}
         </a>
         </div>`;
  else
    popup_body += `<div class="bold w-100">${props.status}</div>`;
  //end of header

  if (fullView) {
    popup_body += '<hr>';
    popup_body += getCADataTable(props)
    popup_body += '<hr>';
  }

  //кнопки внизу
  const curtab = window.store.getState()["leftDrawerReducer"].curTab;
  if (curtab !== 'printing' && fullView) {
    popup_body += '<div class="buttons_grid">';
    if (interactive.ca_popup_full_info_button)
      popup_body += `<a id='ca_popup_full_info_button' href="#" class="cp_ca_report_link buttons_item-3">${moreString}</a>`;
    /*if (interactive.ca_coordinate_editor_button) {
      popup_body += `<a id='ca_coordinate_editor_button' href="#" class="cp_ca_report_link buttons_item-3"">${coordinatesString}</a>`;
    }*/
    if (interactive.ca_common_report_button) {
      popup_body += `<a id='ca_common_report_button' href="#" class="cp_ca_report_link buttons_item-3">${reportPDFString}</a>`;
    }
    popup_body += '</div>';
  }
  //конец кнопок

  popup_body += '</div>';
  return popup_body;
}

export function getCASumPopup(props, casListString, nodata = false) { //get ca's popup
  const maxNameChars = 50;
  const cas_list_string = (casListString.length <= maxNameChars) ? casListString : casListString.substring(0, maxNameChars) + '<span class="popup_nowrap"> + еще...';
  let popup_body = '<div class="popup_body ca_popup_body">';

  //header
  popup_body += `<div class="bold" style="flex: 1">${consolidatedReportString}</div>`;
  popup_body += `<div style="flex: 1; margin-top: 3px">${casString} (кв/№): ${cas_list_string}</div>`;
  //end of header

  if (!nodata) {
    popup_body += '<hr>';
    popup_body += getCADataTable(props)
    popup_body += '<hr>';
  }

  popup_body += '<div class="buttons_grid">';
  popup_body += `<a id='ca_popup_close_and_reset_selection' href="#" class="cp_ca_report_link buttons_item-3">Убрать выделение и закрыть</a>`;
  popup_body += '</div>';

  return popup_body;
}

/**
 * Создаёт всплывающее окно для информации по штабелю на деляне (в html).
 * @param layer - leaflet слой штабеля
 * @param data {object} - информация по фотоштабелю (ответ сервера)
 * @param interactive - информация для кнопок
 * */
export function getPlotStockpilePopup(layer, data, interactive) {
  let constructedTable = "";
  let popup = '<div class="popup_body ca_popup_body">';

  popup += `<div class="bold" style="flex: 1">${layer?.options?.name || plotStockpilePopupTitle}</div>`;
  popup += `<div class="mt-1 mb-1" style="flex: 1; color: #646464;">Деляна: ${layer.options.ca_options.name}</div>`;
  let hr = false;
  if (data && data["brief_data"]) {
    hr = true;
    data["brief_data"].forEach(value => {
      constructedTable += `<tr>
            <td class="popup_table_specie_icon" style="color: ${getSpeciesColor(value.name)}">${speciesSVG()}</td>
            <td>${value.name}</td>
            <td class="popup_table_col1"><span class="bold">${value.volume}</span> м&sup3;</td>
            </tr>`;
    })
    popup += `
      <hr />
      <table class="ca_popup_table">
        <tbody>
          ${constructedTable}
          <tr>
            <td colspan="2" class="bold">Общий объём</td>
            <td class=""><span class="bold">${data.all_volume}</span> м&sup3;</td>
          </tr>
        </tbody>
      </table>
  `;
  }

  if (interactive.stp_popup_go_to_module) {
    hr = true;
    popup += `<div class="cp_ca_buttons_panel">
        <a id="stp_popup_go_to_module" class="cp_ca_report_link" style="margin-top: 5px">Замер штабелей</a>        
      </div>`;
  }

  if (hr) {
    popup += '<hr />';
  }

  if (interactive.stp_popup_edit_button) {
    popup += `<div class="bold mt-3">Координаты${interactive.stp_popup_reset_geometry_button ? "  (отредактированы)" : ""}</div>`;
    popup += '<div class="popup_buttons_panel popup_button_panel_justify_start">';
    popup += `<button class="iconButtonCss"
                  title="Изменить штабель"
                  type="button"
                  id="stp_popup_edit_button"
                  >
                  ${pencilSVG()}
              </button>`;
    if (interactive.stp_popup_reset_geometry_button) {
      popup += `<button class="iconButtonCss"
                  title="Сбросить координаты"
                  type="button"
                  id="stp_popup_reset_geometry_button"
                  >
                  ${resetSVG()}
              </button>`;
    }
    if (interactive.stp_popup_save_button) {
      popup += `<button class="iconButtonCss"
                  title="Попробовать сохранить еще раз"
                  type="button"
                  id="stp_popup_save_button"
                  >
                  ${saveSVG()}
                </button>`;
    }
    popup += '</div>';
    popup += '<hr/>';
  }

  popup += '</div>';
  return popup;
}

/**
 * Возвращает попу для шейпа.
 * @param layer - слой шейпа
 * @param interactive - интерактивные действия в попе
 * @returns {html} - строка с html попы
 */
export function getShapePopup(layer, interactive) {
  const disabled = layer.node.saving || layer.node.new;
  //Name
  let popup = `<div class="popup_nowrap">${layer.node['name']}</div>`;
  const shape = layer.pm['_shape'];

  if (!cannotIDo.editorAction()) {
    popup += '<hr/>';
    if (!disabled) {
      //Color and weigth
      const colors = ['#000000', '#333333', '#666666', '#999999', '#FFFFFF', '#FF0000', '#00FF00', '#0000FF', '#0D920DFF', '#40E0D0', '#3388FF', '#FFBF00', '#E5863B', '#AF0CBB', '#FF7F50', '#DE3163',];
      popup += '<datalist id="popup_color_chooser_datalist">';
      for (const color of colors) popup += `<option value="${color}"></option>`;
      popup += '</datalist>';
      popup += `<div class="popup_buttons_panel">`;

      let colorValue;
      if (!layer["elz_selected"]) colorValue = layer.node.type !== 'Marker' ? layer.elz_properties.color : layer.elz_properties.markerColor; else colorValue = layer.node.type !== 'Marker' ? layer.elz_old_style.color : layer.elz_old_style.markerColor;

      popup += `<input
              id="popup_feas_color_chooser"
              type="color"
              value=${colorValue} 
              list="popup_color_chooser_datalist"
              class="popup_color_chooser"
              >`;
      if (shape !== 'Marker') {
        popup += `<select id="popup_feas_weight_chooser" class="popup_weight_select">`
        const start = (shape === 'Line') ? 1 : 0;
        for (let i = start; i <= 10; i++) popup += `<option value="${i}" ${layer.options.weight.toString() === i.toString() ? 'selected' : ''}>${i}px</option>`
        popup += '</select>'
      } else {
        popup += `<select id="popup_feas_icon_chooser" class="popup_weight_select">`;
        Object.entries(markersIconsList).map(([key, entrie]) => {
          popup += `<option value="${key}" ${layer.elz_properties['markerSymbol'] === key ? 'selected' : ''}>${entrie.name}</option>`
        })
        popup += '</select>'
      }
      popup += '</div>';
      popup += '<hr>';

      //Control buttons
      popup += '<div class="popup_buttons_panel popup_button_panel_justify_start">';
      if (interactive.uf_edit_button) popup += `<button class="iconButtonCss"
                title="Редактировать фигуру"
                tabindex="0" type="button" data-mui-internal-clone-element="true"
                id="uf_edit_button"
                >
                ${pencilSVG()}
              </button>`;
      if (interactive.uf_erase_button) popup += `<button class="iconButtonCss" 
              title="Удалить фигуру" 
              tabindex="0" type="button" data-mui-internal-clone-element="true"
              id="uf_erase_button"
              >
              ${deleteSVG(layer.elz_emode === 'erase' ? 'green' : 'black')}
              </button>`;
      if (interactive.uf_eye_button) popup += `<button class="iconButtonCss" 
              title="Скрыть фигуру" 
              tabindex="0" type="button" data-mui-internal-clone-element="true"
              id="uf_eye_button"
              >
              ${eyeSVG()}
              </button>`;
      popup += "</div>";
      popup += '<hr>';
      //Create new element button
      if (interactive.uf_create_cutting_area_button && interactive.uf_create_warehouse_button) {
        popup += `<div class="cp_ca_buttons_panel">`;
        popup += `<a id="uf_create_cutting_area_button" href="#" class="${cannotIDo.createCA() ? "cp_ca_report_link_disabled" : "cp_ca_report_link"} full_width" style="margin-top: 5px">${createCAString}</a>`;
        popup += `<a id="uf_create_warehouse_button" href="#" class="${cannotIDo.createCA() ? "cp_ca_report_link_disabled" : "cp_ca_report_link"} full_width" style="margin-top: 5px">${warehouseCreateString}</a>`;
        popup += '</div>';
      }
      if (interactive.uf_create_road_button) {
        popup += `<div class="cp_ca_buttons_panel">`;
        popup += '<a id="uf_create_road_button" href="#" class="cp_ca_report_link full_width" style="margin-top: 5px">Создать дорогу</a>';
        popup += '</div>';
      }
      if (interactive.uf_create_pillar_button) {
        popup += `<div class="cp_ca_buttons_panel">`;
        popup += '<a id="uf_create_pillar_button" href="#" class="cp_ca_report_link full_width" style="margin-top: 5px">Создать кварт. столб</a>';
        popup += '</div>';
      }
      //Coordinates button
      /*if (interactive.uf_coordinates_button) {
        popup += `<div class="cp_ca_buttons_panel">`;
        popup += `<a id="uf_coordinates_button" href="#" class="cp_ca_report_link full_width" style="margin-top: 5px">${coordinatesString}</a>`;
        popup += '</div>';
      }*/
    } else {
      popup += '<div class="c popup_nowrap">Идет сохранение фигуры<br />Попробуйте позже</div>';
    }
  }
  return popup;
}

export function getRoadPopup(layer, interactive) {
  const workspace = getRoads().roadsByWorkspaces[layer.elz_properties.rental_contract]
  if (layer.elz_saving) {
    return `<div class="mb-1">${savingDumbString}</div>`;
  }
  let popup_body = `<div class="popup_nowrap bold mb-1">${layer.elz_properties.name}</div>`;
  if (workspace) {
    popup_body += `<div class="mb-2">${workspace.name}</div>`;
  }
  const length = (getLayerLength(layer) / 1000).toFixed(2)
  popup_body += `<div class="popup_nowrap">Длина: ${length}км.</div>`;

  if (!cannotIDo.editorAction() && !layer.elz_editor) {
    popup_body += '<hr>';
    popup_body += '<div class="cp_ca_buttons_panel popup_flex_row">';
    if (interactive.road_edit_button) {
      popup_body += `<button class="iconButtonCss"
                      title="${roadEditTitleString}"
                      type="button"
                      id="road_edit_button"
                      >
                        ${pencilSVG()}
                    </button>`;
    }
    if (interactive.road_delete_button) {
      popup_body += `<button class="iconButtonCss"
                      title="${roadDeleteTitleString}"
                      type="button"
                      id="road_delete_button"
                      >
                        ${deleteSVG('black')}
                    </button>`;
    }
    if (interactive.road_visible_button && !layer.elz_emode) {
      popup_body += `<button class="iconButtonCss" 
                    title="${roadHideTitleString}"
                    type="button"
                    id="road_visible_button"
                   >
                    ${eyeSVG()}
                   </button>`;
    }
    popup_body += '</div>'
    popup_body += '<hr>';
  }
  /*  if (interactive["road_coordinates_button"]) {
      popup_body += `<div class="cp_ca_buttons_panel">`;
      popup_body += '<a id="road_coordinates_button" href="#" class="cp_ca_report_link full_width" style="margin-top: 5px">Координаты</a>';
      popup_body += '</div>';
    }*/
  return popup_body;
}

export function getPillarPopup(layer, interactive) {
  let popup_body = `<div class="popup_nowrap bold">${layer.elz_properties.name}</div>`;
  let workspace = layer.elz_parent_node;
  if (!workspace) {
    workspace = layer.elz_parent_layer.elz_parent_node;
  }
  popup_body += `<div class="mb-1">${workspace.name}</div>`;
  if (!cannotIDo.editorAction()) {
    popup_body += '<hr>';
    popup_body += '<div class="cp_ca_buttons_panel popup_flex_row">';
    if (interactive.pillar_delete_button) {
      popup_body += `<button class="iconButtonCss"
                            title="${pillarDeleteString}"
                            type="button"
                            id="pillar_delete_button">
                      ${deleteSVG('black')}
                    </button>`;
    }
    if (interactive.pillar_visible_button) {
      popup_body += `<button class="iconButtonCss" 
                      title="${pillarHideString}"
                      type="button"
                      id="pillar_visible_button"
                     >
                      ${eyeSVG()}
                     </button>`;
    }
    if (interactive["pillar_coordinates_button"]) {
      popup_body += `<button class="iconButtonCss icon_button_css_stroke" 
                        title="${coordinatesEditorString}"
                        type="button"
                        id="pillar_coordinates_button"
                       >
                        ${coordinatesSVG()}
                       </button>`;
    }
    popup_body += '</div>';
    popup_body += '<hr>';
  }
  return popup_body;
}

export function getIL1Popup() {
  let popup_body = `<div class="popup_nowrap">${reportIL1}</div>`;
  popup_body += '<div class="cp_ca_buttons_panel">';
  popup_body += `<a id='map_reports_il1_commit' href="#" class="cp_ca_report_link" style="margin-top: 5px">${reportIL1CreateReportString}</a>`;
  popup_body += `<a id='map_reports_il1_cancel' href="#" class="cp_ca_report_link" style="margin-top: 5px">${cancelText}</a>`;
  popup_body += '</div>';
  return popup_body;
}

export function getPencilDrawingShapePopup(data, interactives) {
  const typeP = data.elz_type === 'Polygon';
  let coords;
  if (typeP) {
    coords = [...data.getLatLngs()[0]]
    coords.push(coords[0])
  } else {
    coords = [...data.getLatLngs()]
  }
  let popup_body = '<div class="ca_header_flex"></div>';
  const [distance, area] = calculateAreanAndDistance(coords)
  popup_body += '<div class="bold">Зона</div>';
  if (typeP) {
    popup_body += `<div class="popup_nowrap">Периметр: ${(distance / 1000).toFixed(2)} км.</div>`;
    popup_body += `<div class="popup_nowrap">Площадь: ${(area / 10000).toFixed(2)} га.</div>`;
  } else {
    popup_body += `<div class="popup_nowrap">Длина: ${(distance / 1000).toFixed(2)} км.</div>`;
  }
  popup_body += '<hr />';
  if (interactives.pensil_drawing_select_ca_buttons)
    popup_body += `<div class="cp_ca_buttons_panel"><a id="pensil_drawing_select_ca_buttons" href="#" class="cp_ca_report_link" style="margin-top: 5px">${caPencilSelect}</a></div>`;
  popup_body += `<div class="cp_ca_buttons_panel"><a id="pensil_drawing_delete_shape_button" href="#" class="cp_ca_report_link" style="margin-top: 5px">Убрать выделение</a></div>`;
  return popup_body;
}

export function getWarehousePopup(layer, interactive) {
  const props = layer.elz_node.properties;
  let popup_body = '<div class="ca_popup_body">';
  popup_body += '<div class="ca_header_flex"></div>';
  popup_body += `<div class="bold">${props.name}</div>`;
  popup_body += `<div>${props.type}</div>`;
  popup_body += '<hr />';
  popup_body += `<div>Адрес: ${props.address || 'не указан'}</div>`;
  popup_body += `<div class="cp_ca_buttons_panel poupup_mt2">`;
  if (interactive.wh_edit_info_button)
    popup_body += `<a id="wh_edit_info_button" href="#" class="cp_ca_report_link" style="margin-top: 5px">Информация</a>`;
  /*  if (interactive.wh_edit_coordinates_button)
      popup_body += `<a id="wh_edit_coordinates_button" href="#" class="cp_ca_report_link" style="margin-top: 5px">Координаты</a>`;*/
  popup_body += '</div>';
  popup_body += '</div>';
  return popup_body;
}
