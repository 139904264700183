import {dispatch} from "../../../../Common/misc_functions";
import {refreshEditorPanel, setEditingLayerData} from "./layerEditorReducer";
import L from "leaflet";
import {svgMarker} from "../../../LeftPanel/Context/Projects/ProjectsCommon";

const editStyle = {
  pane: 'shapes',
}

class LayerEditor {
  map;
  ownLayer;
  drawLayer;
  editModes;
  editMode;
  saveHandler;
  cancelHandler;
  disableUndo;
  markerOptions;
  _layerType;
  _history = []

  constructor({map, layer, editModes, saveHandler, cancelHandler, disableUndo = false, markerOptions = null}) {
    this.map = map;
    this.ownLayer = layer;
    this.editModes = editModes;
    this.saveHandler = saveHandler;
    this.cancelHandler = cancelHandler || null;
    this.disableUndo = disableUndo;
    this.markerOptions = markerOptions;
    this._layerType = this._getLayerType()
    this.editMode = this._getEditMode(this._layerType, editModes)
    this.drawLayer = this._createDrawLayer()
    this.drawLayer.elz_owner_layer = layer;
    map.addLayer(this.drawLayer)
    this._switchEditMode(this.editMode)
    layer.elz_editor = this;
    this._addEvents()
    dispatch(setEditingLayerData({editor: this}))
  }

  _addEvents() {
    this.drawLayer.on("pm:vertexadded", this._addVertex)
    this.drawLayer.on("pm:vertexremoved", this._removeVertex)
    this.drawLayer.on("pm:markerdragend", this._moveMarker)
    this.drawLayer.on("pm:rotateend", this._rotateLayer)
    this.drawLayer.on("pm:dragend", this._dragLayer)
    this.drawLayer.on("click", this._stopPropagation)
  }

  _removeEvents() {
    this.drawLayer.off("pm:vertexadded", this._addVertex)
    this.drawLayer.off("pm:vertexremoved", this._removeVertex)
    this.drawLayer.off("pm:markerdragend", this._moveMarker)
    this.drawLayer.off("pm:rotateend", this._rotateLayer)
    this.drawLayer.off("pm:dragend", this._dragLayer)
    this.drawLayer.off("click", this._stopPropagation)
  }

  _stopPropagation(event) {
    L.DomEvent.preventDefault(event)
    L.DomEvent.stopPropagation(event)
  }

  _addToHistory(name) {
    const history = this.getHistory()
    history.push({type: name, data: [...this._getLayerLatLng(this.drawLayer)]})
    dispatch(refreshEditorPanel())
  }

  _dragLayer(event) {
    const editor = event.layer.elz_owner_layer.elz_editor;
    editor._addToHistory('lmove')
    /*const editor = event.layer.elz_owner_layer.elz_editor;
    const history = editor.getHistory()
    history.push({type: 'ldrag', data: [...editor._getLayerLatLng(editor.drawLayer)]})
    dispatch(refreshEditorPanel())*/
  }

  _rotateLayer(event) {
    const editor = event.layer.elz_owner_layer.elz_editor;
    editor._addToHistory('lrotate')
    /*const history = editor.getHistory()
    history.push({type: 'lrotate', data: [...editor._getLayerLatLng(editor.drawLayer)]})
    dispatch(refreshEditorPanel())*/
  }

  _moveMarker(event) {
    const editor = event.layer.elz_owner_layer.elz_editor;
    editor._addToHistory('vmove')
    /*    const history = editor.getHistory()
        history.push({type: 'vmove', data: [...editor._getLayerLatLng(editor.drawLayer)]})
        dispatch(refreshEditorPanel())*/
  }

  _addVertex(event) {
    const editor = event.layer.elz_owner_layer.elz_editor;
    editor._addToHistory('vmove')
    /*const history = editor.getHistory()
    history.push({type: 'vadd', data: [...editor._getLayerLatLng(editor.drawLayer)]})
    dispatch(refreshEditorPanel())*/
  }

  _removeVertex(event) {
    const editor = event.layer.elz_owner_layer.elz_editor;
    const history = editor.getHistory()
    history.push({type: 'vremove', data: [...editor._getLayerLatLng(editor.drawLayer)]})
    dispatch(refreshEditorPanel())
  }

  _getLayerLatLng(layer) {
    return layer.getLatLngs ? layer.getLatLngs() : [layer.getLatLng()]
  }

  _setLayerLatLng(latlng) {
    if (this._layerType === 'Marker') {
      this.drawLayer.setLatLng(latlng[0])
    } else {
      this.drawLayer.setLatLngs(latlng)
    }
  }

  _getLayerType() {
    if (this.ownLayer instanceof L.Polygon) {
      return 'Polygon';
    } else {
      if (this.ownLayer instanceof L.Polyline) {
        return 'Polyline';
      } else {
        if (this.ownLayer instanceof L.Marker) {
          return 'Marker';
        }
      }
    }
    return undefined;
  }

  _getEditMode(shapeType, editModes) {
    if (shapeType === 'Marker') {
      return 'move';
    } else {
      if (editModes.indexOf('edit') !== -1) {
        return 'edit';
      } else {
        return shapeType[0]
      }
    }
  }

  _stopDrawing() {
    this.drawLayer.pm.disable()
    this.drawLayer.pm.disableLayerDrag()
    this.drawLayer.pm.disableRotate()
  }

  _switchEditMode(mode) {
    this._stopDrawing()
    this.editMode = mode;
    switch (mode) {
      case 'edit':
        this.drawLayer.pm.enable()
        break;
      case 'move':
        this.drawLayer.pm.enableLayerDrag()
        break;
      case 'rotate':
        this.drawLayer.pm.enableRotate()
        break;
    }
    dispatch(refreshEditorPanel())
  }

  _createDrawLayer() {
    const coords = this._getLayerLatLng(this.ownLayer)
    switch (this._layerType) {
      case 'Polyline':
        return L.polyline(coords, {...editStyle})
      case 'Polygon':
        return L.polygon(coords, {...editStyle})
      case 'Marker':
        return L.marker(coords[0], {
          icon: svgMarker(this.markerOptions.markerColor, 'move', this.markerOptions.markerSymbol),
          bubblingMouseEvents: true,
          ...editStyle,
        })
    }
  }

  getHistory() {
    return this._history;
  }

  delete() {
    this._removeEvents()
    const panelCurLayer = window.store.getState().layerEditingReducer.editor.ownLayer;
    if (panelCurLayer._leaflet_id && panelCurLayer._leaflet_id === this.ownLayer._leaflet_id) {
      dispatch(setEditingLayerData({editor: null}))
    }
    this.map.removeLayer(this.drawLayer)
    delete this.ownLayer.elz_editor;
  }

  undo() {//вызывается из панели
    let data = this._getLayerLatLng(this.ownLayer)
    if (this._history.length > 1) {
      data = this._history[this._history.length - 2].data;
    }
    this._stopDrawing() //приходится делать так, иначе баги с вертексами
    this._setLayerLatLng(data, this.drawLayer)
    this._switchEditMode(this.editMode)
    this._history.pop()
    dispatch(refreshEditorPanel())
  }

  switchMode(mode) {
    this._switchEditMode(mode)
  }
}

export function layerEditor(options) {
  return new LayerEditor(options)
}
