import {Autocomplete, FormControl, Grid, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {FTextField} from "../Context/Projects/FTextFiled";
import {
    caAllocatedStatusString,
    caDeclarationYearString,
    caFellingYearString,
    caPlanYearString,
    reforestrationActivtiesString
} from "../../Map/Common/Strings_RU";

const restorationsList = [
    "Сохранение подроста",
    "Содействие естественному лесовосстановлению (минерализация почвы)",
    "Искусственное лесовосстановление (создание лесных культур)"
]
const allocatedPlotStatues = [
  'Камеральный',
  'В натуре',
  'Расчет МДО',
  'На проверке',
  'К исправлению',
  'К декларированию',
]

export const defaultAllocatedPlotStatus = allocatedPlotStatues[5]

export function PlotAddingFields({dataObj, setDataObj, errors}) {

  function setData(field, value) {
    setDataObj({...dataObj, [field]: value})
  }

  return (
    <Grid spacing={3} sx={{mb: 3}} container>
      <Grid xs={6} md={6} lg={6} item>
        <FormControl fullWidth>
          <InputLabel id="allocated-plotp-status-label">{caAllocatedStatusString}</InputLabel>
          <Select
            labelId="allocated-plotp-status-label"
            size={'small'}
            variant={'outlined'}
            value={dataObj.cameral}
            label="Статус отвода"
            disabled={dataObj.status !== 'Отведена'}
            onChange={(e) => {
              setData('cameral', e.target.value)
            }}
          >
            {allocatedPlotStatues.map((item, index) =>
              <MenuItem value={item} key={index}>{item}</MenuItem>
            )}
          </Select>
        </FormControl>
      </Grid>
      <Grid xs={6} md={6} lg={6} item>
        <Autocomplete
          size={'small'}
          //multiple
          //disableCloseOnSelect
          //freeSolo
          value={dataObj.restorations.length ? dataObj.restorations[0] : null}
          renderInput={(params) => <TextField {...params} label={reforestrationActivtiesString}/>}
          /*getOptionLabel={(params) => {
            return params || null;
          }}*/
          options={restorationsList}
          onChange={(e, v) => {
            setData('restorations', !!v ? [v] : [])
          }}
        />
      </Grid>
      <Grid xs={4} md={4} lg={4} item>
        <FTextField
          error={errors.plan_year}
          value={dataObj.plan_year}
          label={caPlanYearString}
          variant={'outlined'}
          nofocus={true}
          _endEditing={(value) => {
            setData('plan_year', value)
          }}
        />
      </Grid>
      <Grid xs={4} md={4} lg={4} item>
        <FTextField
          error={errors.declaration_year}
          value={dataObj.declaration_year}
          label={caDeclarationYearString}
          variant={'outlined'}
          nofocus={true}
          _endEditing={(value) => {
            setData('declaration_year', value)
          }}
        />
      </Grid>
      <Grid xs={4} md={4} lg={4} item>
        <FTextField
          error={errors.felling_year}
          value={dataObj.felling_year}
          label={caFellingYearString}
          variant={'outlined'}
          nofocus={true}
          _endEditing={(value) => {
            setData('felling_year', value)
          }}
        />
      </Grid>
    </Grid>
  )
}
