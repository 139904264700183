export const rightBarText = {fontSize: 15}
export const leftTopPhotoCard = {
  position: 'absolute',
  left: 4,
  top: 4,
  pt: 0.25,
  pb: 0.25,
  pr: 0.8,
  pl: 0.8
}
export const leftBottomPhotoCard = {
  position: 'absolute',
  left: 4,
  bottom: 4,
  pr: 0.8,
  pl: 0.8
}

export const rightTopPhotoCard = {
  position: 'absolute',
  right: 4,
  top: 4,
  pt: 0.25,
  pb: 0.25,
  pr: 0.8,
  pl: 0.8,
  bgcolor: "primary.light",
};

export const imageStyle = {
  borderRadius: 5,
  height: '10vh',
  width: '180px'
}

export const baseIconStyle = {
  position: 'absolute',
  top: '50%',
  transform: 'translate(0%, -50%)',
  color: 'white',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  borderRadius: '50%',
  width: '35px',
  height: '35px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '16px',

};

export const iconRight = {
  ...baseIconStyle,
  right: '8px',
};

export const iconLeft = {
  ...baseIconStyle,
  left: '8px',
};

export const iconLeftCenter = {
  ...baseIconStyle,
  transform: 'translate(-50%, -50%)',
  left: '40%',
  right: '60%'
};

export const iconRightCenter = {
  ...baseIconStyle,
  transform: 'translate(-50%, -50%)',
  left: '60%',
  right: '40%'
};

