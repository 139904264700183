import {otherDataAPI} from "../../api/api";
import {setSnack} from "../../components/Main/Map/Common/Dialog/Snack/snackReducer";
import {tariffRules} from "../../components/NewOrganizationPage/Tariffs/tariffsRules";
import {handleErrors} from "../commonReducerFunctions/ThunkErrorsHandler";
import {updateTariffOrgInfoLimits} from "../../components/Main/Map/Common/tariffs";

const SET_ORG_INFO_ATTR = "SET_ORG_INFO_ATTR";
const REMOVE_WORKSPACE_FROM_WORKSPACES_LIST = 'REMOVE_WORKSPACE_FROM_WORKSPACES_LIST'
const REMOVE_USER_FROM_USERS_LIST = 'REMOVE_USER_FROM_USERS_LIST'
const REPLACE_USER_IN_ORGANIZATION = 'REPLACE_USER_IN_ORGANIZATION'
const REPLACE_WORKSPACE_IN_WORKSPACES_LIST = 'REPLACE_WORKSPACE_IN_WORKSPACE_LIST'
const UPDATE_ORG_DATA = 'UPDATE_ORG_DATA'
const REPLACE_USERS_LIST_IN_WORKSPACE = 'REPLACE_USERS_LIST_IN_WORKSPACE'
const SET_CURRENT_USER_INFO = 'SET_CURRENT_USER_INFO'
const GET_ALL_RENTAL_CONTRACT = 'GET_ALL_RENTAL_CONTRACT'
const SET_RENTAL_CONTRACTS_ACCESS_STATE = 'SET_RENTAL_CONTRACTS_ACCESS_STATE'
const SET_LOADING_STATE = 'SET_LOADING_STATE'

const initialState = {
    //organization
    organizationData: null,
    organizationDataLoading: false,
    organizationDataLoadingError: false,

    // workspaces
    organizationWorkspaces: null,
    organizationWorkspacesLoading: null,
    selectedWorkspace: null,
    createWorkspaceMode: false,

    //users
    organizationUsers: null,
    organizationUsersLoading: false,
    selectedUser: null,
    createUserMode: false,

    // Договоры аренды и сущности с ним (дороги, деляны) в зависимости от таба.
    allRentalContract: [],

    //Скрыть кнопку сохранения
    hideSaveButton: false,

    // Доступ к модулям
    allModules: null,

    //тарифы
    tariffsRules: tariffRules,

    //sentinel users
    sentinelUsers: {
        data: null,
        loading: false,
        loadingError: false,
        allCheked: false,
    },

    // Информация о текущем пользователе.
    currentUserInfo: null,

    // Состояние изменения данных доступа пользователя.
    // type - доступ к договорам аренды, делянам, дорогам (rental_contracts, plots, roads, modules).
    // isChanged - состояние изменения данных, если данные были изменены.
    rentalContractsAccessState: {type: null, isChanged: false},
    isLoading: false,
}

// редьюсер для работы с данными организации
export const organizationInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ORG_INFO_ATTR: {
            return {...state, ...action.payload};
        }
        case REMOVE_USER_FROM_USERS_LIST: {
            return {
                ...state, organizationUsers: state.organizationUsers.filter(user => user.id !== action.userId),

            }
        }
        case REPLACE_USER_IN_ORGANIZATION: {
            return {
                ...state, organizationUsers: state.organizationUsers.map(user => {
                    if (user.id !== action.userId) {
                        return user
                    } else {
                        return action.user
                    }
                })
            }
        }
        case UPDATE_ORG_DATA: {
            return {
                ...state, organizationData: {
                    ...state.organizationData, ...action.data
                }
            }
        }
        case REMOVE_WORKSPACE_FROM_WORKSPACES_LIST: {
            return {
                ...state,
                organizationWorkspaces: state.organizationWorkspaces.filter(user => user.id !== action.workspaceId),
            }
        }
        case REPLACE_WORKSPACE_IN_WORKSPACES_LIST: {
            return {
                ...state, organizationWorkspaces: state.organizationWorkspaces.map(workspace => {
                    if (workspace.id !== action.workspaceId) {
                        return workspace
                    } else {
                        return action.workspace
                    }
                })
            }
        }
        case REPLACE_USERS_LIST_IN_WORKSPACE: {
            return {
                ...state, organizationWorkspaces: state.organizationWorkspaces.map(workspace => {
                    if (workspace.id !== action.workspaceId) {
                        return workspace
                    } else {
                        return {...workspace, users: action.usersList}
                    }
                })
            }
        }
        case SET_CURRENT_USER_INFO:
            return {...state, currentUserInfo: action.user}
        case GET_ALL_RENTAL_CONTRACT: {
            return {...state, allRentalContract: action.rentalContracts};
        }
        case SET_RENTAL_CONTRACTS_ACCESS_STATE: {
            return {...state, rentalContractsAccessState: action.payload}
        }
        case SET_LOADING_STATE: {
            return {...state, isLoading: action.isLoading}
        }
        default:
            return state;
    }
}

export const setOrgInfoAttr = (payload) => ({type: SET_ORG_INFO_ATTR, payload});
export const updateOrgData = (data) => ({type: UPDATE_ORG_DATA, data});

/*export const setRentalContractsAccessState = (type, state) => ({
    type: SET_RENTAL_CONTRACTS_ACCESS_STATE,
    payload: {
        type: type,
        isChanged: state
    }
})*/

// Запрашивает с сервера информацию о текущем пользователе.
/*export const getCurrentUserInfo = () => {
    return async (dispatch) => {
        try {
            let res = await otherDataAPI.user.getUserInfo()
            dispatch(setCurrentUserInfo(res.data))
        } catch (e) {
            handleErrors(dispatch, e)
        }
    }
}*/

export const getOrganizationDataThunk = () => {
    return async (dispatch) => {
        try {
            dispatch(setOrgInfoAttr({organizationDataLoading: true, organizationDataLoadingError: false}))
            let res = await otherDataAPI.organization.getOrganizationData()
            updateTariffOrgInfoLimits(res.data)
            dispatch(setOrgInfoAttr({
                organizationData: res.data,
                organizationDataLoading: false,
                organizationDataLoadingError: false
            }))
        } catch (e) {
            dispatch(setOrgInfoAttr({organizationDataLoadingError: true, organizationDataLoading: false}))
        }
    }
}

// users thunks
export const getOrganizationUsersThunk = () => {
    return async (dispatch) => {
        try {
            dispatch(setOrgInfoAttr({organizationUsersLoading: true}))
            let res = await otherDataAPI.organization.getOrganizationUsers()
            dispatch(setOrgInfoAttr({organizationUsers: res.data}))
            dispatch(setOrgInfoAttr({organizationUsersLoading: false}))
        } catch (e) {
            console.error(e)
        }
    }
}
// end users thunks

//workspaces thunks
export const getOrganizationWorkspacesThunk = () => {
    return async (dispatch) => {
        try {
            dispatch(setOrgInfoAttr({organizationWorkspacesLoading: true}))
            let res = await otherDataAPI.workspaces.getOrganizationWorkspaces()
            dispatch(setOrgInfoAttr({organizationWorkspaces: res.data}))
            dispatch(setOrgInfoAttr({organizationWorkspacesLoading: false}))
        } catch (e) {
            handleErrors(dispatch, e)
        }
    }
}

/*export const updateWorkspaceThunk = (workspaceId, data) => {
    return async (dispatch) => {
        try {
            let res = await dataAPI.workspaces.patch(data, workspaceId)
            dispatch(replaceWorkspaceInWorkspacesList(res.data, workspaceId))
            dispatch(setOrgInfoAttr({selectedWorkspace: null}))
            dispatch(setSnack('success', 'Рабочая область изменена'))
        } catch (e) {
            handleErrors(dispatch, e)
        }
    }
}*/
//end workspaces thunks

export const changeOrgDataThunk = (data, orgId, setEditMode) => {
    return async (dispatch) => {
        try {
            await otherDataAPI.organization.changeOrgData(data, orgId)
            dispatch(updateOrgData(data))
            dispatch(setSnack('success', 'Данные организации изменены'))
            setEditMode(false)
        } catch (e) {
            handleErrors(dispatch, e)
        }
    }
}

export const syncWith1CSetPeriodThunk = (orgId, period) => {
    return async (dispatch, getState) => {
        const organizationData = getState().organizationInfoReducer.organizationData
        try {
            let res = await otherDataAPI.api1C.syncWith1CPeriod(orgId, period)
            dispatch(setOrgInfoAttr({organizationData: {...organizationData, auto_sync_1c: res.data.detail}}))
            dispatch(setSnack('success', 'Частота автоматической синхронизации с 1С успешно изменена'))
        } catch (e) {
            handleErrors(dispatch, e)
        }
    }
}

export const requestConnectionWith1CThunk = () => {
    return async (dispatch) => {
        try {
            let res = await otherDataAPI.api1C.requestConnectionWith1C()
            dispatch(setSnack('success', res.data.message))
        } catch (e) {
            handleErrors(dispatch, e)
        }
    }
}

export const requestDisconnect1CThunk = () => {
    return async (dispatch) => {
        try {
            let res = await otherDataAPI.api1C.requestDisconnect1C()
            dispatch(setSnack('success', res.data.message))
        } catch (e) {
            handleErrors(dispatch, e)
        }
    }
}

export const syncWith1CThunk = () => {
    return async (dispatch) => {
        try {
            let res = await otherDataAPI.api1C.syncWith1()
            dispatch(setSnack('success', res.data.message))
        } catch (e) {
            handleErrors(dispatch, e)
        }
    }
}

/*export function loadSentinelData() {
    const dispatch = window.elz_dispatch;
    async function load() {
        return await otherDataAPI.sentinel.getSentinelUsersData()
    }
    dispatch(setOrgInfoAttr({sentinelUsers: {loading: true, loadingError: false, data: null}}))
    load().then(res => {
        const iAmOwner = !cannotIDo.ownerAction()
        const myID = getTariff().userID;
        const allChecked = res.data.users.some(user => {
            const bool = iAmOwner || !user.is_admin || user.id === myID;
            if (!bool)
                return false;
            return user.settings_sentinel.is_on;
        })
        dispatch(setOrgInfoAttr({sentinelUsers: {loading: false, loadingError: false, data: res.data, allChecked: allChecked}}))
    }).catch(() => {
        dispatch(setOrgInfoAttr({sentinelUsers: {loading: false, loadingError: true, data: null}}))
    })
}*/
