import {dataAPI, otherDataAPI} from "../../api/api";
import {setSnack} from "../../components/Main/Map/Common/Dialog/Snack/snackReducer";
import {handleErrors} from "../commonReducerFunctions/ThunkErrorsHandler";
import {changeCA} from "../../components/Main/LeftPanel/Context/CAs/CuttingsAreas";
import {changeRoadStatusByID,} from "../../components/Main/LeftPanel/Context/Roads/roads";

const SET_RIGHT_PANEL_ATTR = "SET_RIGHT_PANEL_ATTR";
const SET_NEW_SHAPE_COMMENT = "SET_NEW_SHAPE_COMMENT";
const SET_NEW_SHAPE_PHOTOS = "SET_NEW_SHAPE_PHOTOS";
const REMOVE_SHAPE_PHOTO = "REMOVE_SHAPE_PHOTO";
const SET_NEW_PILLAR_COMMENT = "SET_NEW_PILLAR_COMMENT";
const SET_NEW_ROAD_COMMENT = "SET_NEW_ROAD_COMMENT";
const SET_NEW_ROAD_PHOTOS = "SET_NEW_ROAD_PHOTOS";
const REMOVE_ROAD_PHOTO = "REMOVE_ROAD_PHOTO";
const SET_NEW_PLOT_COMMENT = "SET_NEW_PLOT_COMMENT";
const SET_NEW_PLOT_PHOTOS = "SET_NEW_PLOT_PHOTOS";
const REMOVE_PLOT_PHOTO = "REMOVE_PLOT_PHOTO";
const UPDATE_ROAD_DATA = "UPDATE_ROAD_DATA";
const REMOVE_VIOLATION_PHOTO = "REMOVE_VIOLATION_PHOTO";
const SET_NEW_VIOLATION_PHOTOS = "SET_NEW_VIOLATION_PHOTOS";
const SET_VIOLATION_DESCRIPTION = "SET_VIOLATION_DESCRIPTION";
const RESET_PANEL_DATA = "RESET_PANEL_DATA";

export const initialState = {
  itemType: null,
  rightPanelVisible: false,
  fullPhotoModal: {status: false, selectedPhoto: null, position: null},
  rightPanelDataLoading: false,
  rightPanelDataLoadingError: false,
  shapeData: null,
  plotData: null,
  plotLayer: null,
  node: null,
  roadData: null,
  pillarData: null,
  showCADataDialogWindow: false,
  anyClass: '',
}

// редьюсер для работы с данными правой панели
export const rightPanelReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RIGHT_PANEL_ATTR: {
      return {...state, ...action.payload};
    }
    case RESET_PANEL_DATA: {
      return {...initialState, rightPanelVisible: state.rightPanelVisible}
    }
    case SET_NEW_SHAPE_COMMENT: {
      return {
        ...state,
        shapeData: {...state.shapeData, comment: action.comment},
      };
    }
    case SET_NEW_SHAPE_PHOTOS: {
      return {
        ...state,
        shapeData: {
          ...state.shapeData,
          photos: [...action.photos],
        },
      };
    }
    case REMOVE_SHAPE_PHOTO: {
      return {
        ...state,
        shapeData: {
          ...state.shapeData,
          photos: state.shapeData.photos.filter(
            (photo) => photo.id !== action.photoId
          ),
        },
      };
    }
    case SET_NEW_PILLAR_COMMENT: {
      return {
        ...state,
        pillarData: {...state.pillarData, comment: action.comment},
      };
    }
    case SET_NEW_ROAD_COMMENT: {
      return {
        ...state,
        roadData: {...state.roadData, comment: action.comment},
      };
    }
    case SET_NEW_ROAD_PHOTOS: {
      return {
        ...state,
        roadData: {
          ...state.roadData,
          photos: [...action.photos],
        },
      };
    }
    case REMOVE_ROAD_PHOTO: {
      return {
        ...state,
        roadData: {
          ...state.roadData,
          photos: state.roadData.photos.filter(
            (photo) => photo.id !== action.photoId
          ),
        },
      };
    }
    case SET_NEW_PLOT_COMMENT: {
      return {
        ...state,
        plotData: {...state.plotData, comment: action.comment},
      };
    }
    case SET_NEW_PLOT_PHOTOS: {
      return {
        ...state,
        plotData: {
          ...state.plotData,
          photos: [...action.photos],
        },
      };
    }
    case REMOVE_PLOT_PHOTO: {
      return {
        ...state,
        plotData: {
          ...state.plotData,
          photos: state.plotData.photos.filter(
            (photo) => photo.id !== action.photoId
          ),
        },
      };
    }
    case UPDATE_ROAD_DATA: {
      return {
        ...state,
        roadData: {
          ...state.roadData,
          ...action.data,
        },
      };
    }
    case SET_VIOLATION_DESCRIPTION: {
      return {
        ...state,
        shapeData: {
          ...state.shapeData,
          description: action.description,
        },
      };
    }
    case SET_NEW_VIOLATION_PHOTOS: {
      return {
        ...state,
        shapeData: {
          ...state.shapeData,
          images: [...action.photos],
        },
      };
    }
    case REMOVE_VIOLATION_PHOTO: {
      return {
        ...state,
        shapeData: {
          ...state.shapeData,
          images: state.shapeData.images.filter((image) => image.id !== action.violationId),
        },
      };
    }
    default:
      return state;
  }
};

//reset all data in panel
export function resetRightPanelData() {
  return {type: 'RESET_PANEL_DATA'}
}

//action creators
export const setRightPanelAttr = (payload) => ({
  type: SET_RIGHT_PANEL_ATTR,
  payload,
});
export const setNewShapeComment = (comment) => ({
  type: SET_NEW_SHAPE_COMMENT,
  comment,
});
export const setNewShapePhotos = (photos) => ({
  type: SET_NEW_SHAPE_PHOTOS,
  photos,
});
export const removeShapePhoto = (photoId) => ({
  type: REMOVE_SHAPE_PHOTO,
  photoId,
});
export const setNewPillarComment = (comment) => ({
  type: SET_NEW_PILLAR_COMMENT,
  comment,
});
export const setNewRoadComment = (comment) => ({
  type: SET_NEW_ROAD_COMMENT,
  comment,
});
export const setNewRoadPhotos = (photos) => ({
  type: SET_NEW_ROAD_PHOTOS,
  photos,
});
export const removeRoadPhoto = (photoId) => ({
  type: REMOVE_ROAD_PHOTO,
  photoId,
});
export const setNewPlotComment = (comment) => ({
  type: SET_NEW_PLOT_COMMENT,
  comment,
});
export const setNewPlotPhotos = (photos) => ({
  type: SET_NEW_PLOT_PHOTOS,
  photos,
});
export const removePlotPhoto = (photoId) => ({
  type: REMOVE_PLOT_PHOTO,
  photoId,
});
export const setRoadData = (data) => ({type: UPDATE_ROAD_DATA, data});
export const setNewViolationPhotos = (photos) => ({
  type: SET_NEW_VIOLATION_PHOTOS,
  photos,
});
export const removeViolationPhoto = (violationId) => ({
  type: REMOVE_VIOLATION_PHOTO,
  violationId,
});
export const insertViolationDescription = (data) => ({
  type: SET_VIOLATION_DESCRIPTION,
  data,
});

// блок общих функций для получения данных, загрузки фото, сохранения комментариев, удаления фото и т.д. по шейпам,
// дорогам, столбам, делянам.
const loadData = async ({
                          dispatch,
                          apiCall,
                          successAction,
                          loadingAction,
                          successSnackText,
                        }) => {
  try {
    loadingAction && dispatch(loadingAction(true));
    const res = await apiCall();
    console.log(res)
    dispatch(successAction(res.data));
    successSnackText && dispatch(setSnack("success", successSnackText));
    loadingAction && dispatch(loadingAction(false));
  } catch (e) {
    handleErrors(dispatch, e);
    dispatch(setRightPanelAttr({rightPanelDataLoading: false}));
  }
};

const uploadPhotos = (files) => {
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append(`images`, files[i]);
  }
  return formData;
};

const deletePhoto = async ({
                             dispatch,
                             apiCall,
                             successAction,
                             successSnackText,
                           }) => {
  try {
    await apiCall();
    dispatch(successAction());
    successSnackText && dispatch(setSnack("success", successSnackText));
  } catch (e) {
    handleErrors(dispatch, e);
  }
};

export const saveShapeCommentThunk = (text, shapeId) => {
  return async (dispatch) => {
    await loadData({
      dispatch: dispatch,
      apiCall: () =>
        otherDataAPI.rightPanel.shapes.saveShapeComment(text, shapeId),
      successAction: (data) => setNewShapeComment(data),
      loadingAction: null,
      successSnackText: "Комментарий сохранен",
    });
  };
};

export const saveShapePhotosThunk = (files, shapeId) => {
  return async (dispatch) => {
    const formData = uploadPhotos(files);
    await loadData({
      dispatch: dispatch,
      apiCall: () =>
        otherDataAPI.rightPanel.shapes.saveShapePhotos(formData, shapeId),
      successAction: (data) => setNewShapePhotos(data),
      loadingAction: (status) =>
        setRightPanelAttr({rightPanelDataLoading: status}),
      successSnackText: "Фотографии сохранены",
    });
  };
};

export const deleteShapePhotoThunk = (photoId, shapeId) => {
  return async (dispatch) => {
    await deletePhoto({
      dispatch: dispatch,
      apiCall: () =>
        otherDataAPI.rightPanel.shapes.deleteShapePhoto(
          {id: photoId},
          shapeId
        ),
      successAction: () => removeShapePhoto(photoId),
      successSnackText: "Фотография удалена",
    });
  };
};

/*export const getPillarDataThunk = (pillarId) => {
    return async (dispatch) => {
        await loadData({
            dispatch: dispatch,
            apiCall: () => otherDataAPI.rightPanel.pillars.getPillarData(pillarId),
            successAction: (data) => setRightPanelAttr({pillarData: data}),
            loadingAction: (status) =>
                setRightPanelAttr({rightPanelDataLoading: status}),
        });
    };
};*/

export const savePillarCommentThunk = (text, pillarId) => {
  return async (dispatch) => {
    await loadData({
      dispatch: dispatch,
      apiCall: () =>
        otherDataAPI.rightPanel.pillars.savePillarComment(text, pillarId),
      successAction: (data) => setNewPillarComment(data),
      loadingAction: null,
      successSnackText: "Комментарий сохранен",
    });
  };
};

export const patchRoadDataThunk = (data, roadId) => {
  return async (dispatch) => {
    try {
      setRightPanelAttr({rightPanelDataLoading: true})
      await otherDataAPI.rightPanel.roads.patchRoadData(data, roadId)
      dispatch(setRoadData(data))
      await changeRoadStatusByID(roadId, data.status)
      dispatch(setSnack("success", "Данные обновлены"))
      setRightPanelAttr({rightPanelDataLoading: false})
    } catch (e) {
      handleErrors(dispatch, e)
      dispatch(setRightPanelAttr({rightPanelDataLoading: false}))
    }
  }
}

export const saveRoadCommentThunk = (text, roadId) => {
  return async (dispatch) => {
    await loadData({
      dispatch: dispatch,
      apiCall: () =>
        otherDataAPI.rightPanel.roads.saveRoadComment(text, roadId),
      successAction: (data) => setNewRoadComment(data),
      successSnackText: "Комментарий сохранен",
    });
  };
};

export const saveRoadPhotosThunk = (files, roadId) => {
  return async (dispatch) => {
    const formData = uploadPhotos(files);
    await loadData({
      dispatch: dispatch,
      apiCall: () =>
        otherDataAPI.rightPanel.roads.saveRoadPhotos(formData, roadId),
      successAction: (data) => setNewRoadPhotos(data),
      loadingAction: (status) =>
        setRightPanelAttr({rightPanelDataLoading: status}),
      successSnackText: "Фотографии сохранены",
    });
  };
};

export const deleteRoadPhotoThunk = (photoId, roadId) => {
  return async (dispatch) => {
    await deletePhoto({
      dispatch: dispatch,
      apiCall: () =>
        otherDataAPI.rightPanel.roads.deleteRoadPhoto({id: photoId}, roadId),
      successAction: () => removeRoadPhoto(photoId),
      successSnackText: "Фотография удалена",
    });
  };
};

export const getPlotDataThunk = (plotId) => {
  return async (dispatch) => {
    await loadData({
      dispatch: dispatch,
      apiCall: () => otherDataAPI.rightPanel.plots.getPlotData(plotId),
      successAction: (data) => setRightPanelAttr({plotData: data}),
      loadingAction: (status) =>
        setRightPanelAttr({rightPanelDataLoading: status}),
      successSnackText: "",
    });
  };
};

export const savePlotCommentThunk = (text, plotId) => {
  return async (dispatch) => {
    await loadData({
      dispatch: dispatch,
      apiCall: () =>
        otherDataAPI.rightPanel.plots.savePlotComment(text, plotId),
      successAction: (data) => setNewPlotComment(data),
      successSnackText: "Комментарий сохранен",
    });
  };
};

export const savePlotPhotosThunk = (files, plotId) => {
  return async (dispatch) => {
    const formData = uploadPhotos(files);
    await loadData({
      dispatch: dispatch,
      apiCall: () =>
        otherDataAPI.rightPanel.plots.savePlotPhotos(formData, plotId),
      successAction: (data) => setNewPlotPhotos(data),
      loadingAction: (status) =>
        setRightPanelAttr({rightPanelDataLoading: status}),
      successSnackText: "Фотографии сохранены",
    });
  };
};

export const deletePlotPhotoThunk = (photoId, plotId) => {
  return async (dispatch) => {
    await deletePhoto({
      dispatch: dispatch,
      apiCall: () =>
        otherDataAPI.rightPanel.plots.deletePlotPhoto({id: photoId}, plotId),
      successAction: () => removePlotPhoto(photoId),
      successSnackText: "Фотография удалена",
    })
  }
}

export const patchPlotInfoDataThunk = (id, json) => {
  return async (dispatch) => {
    try {
      const res = await dataAPI.plots.patchCAData(id, json)
      changeCA(res, id)
      dispatch(setRightPanelAttr({showCADataDialogWindow: false}))
      dispatch(setSnack("success", "Данные сохранены"))
    } catch (err) {
      handleErrors(dispatch, err);
    }
  }
}

export const insertViolationDescriptionThunk = (text, violationId) => {
  return async (dispatch) => {
    await loadData({
      dispatch: dispatch,
      apiCall: () =>
        otherDataAPI.rightPanel.violations.insertDescription(text, violationId),
      successAction: (data) => insertViolationDescription(data),
      successSnackText: "Описание нарушения сохранено",
    });
  };
};

export const insertViolationStatusThunk = (status, violationId) => {
  return async (dispatch) => {
    await loadData({
      dispatch: dispatch,
      apiCall: () =>
        otherDataAPI.rightPanel.violations.insertStatus(status, violationId),
      successAction: (data) => insertViolationDescription(data),
      successSnackText: "Статус нарушения установлен",
    });
  };
}

export const insertViolationPriorityThunk = (priority, violationId) => {
  return async (dispatch) => {
    await loadData({
      dispatch: dispatch,
      apiCall: () =>
        otherDataAPI.rightPanel.violations.insertPriority(priority, violationId),
      successAction: (data) => insertViolationDescription(data),
      successSnackText: "Приоритет нарушения установлен",
    });
  };
}

export const insertViolationPhotosThunk = (files, violationId) => {
  return async (dispatch) => {
    const formData = uploadPhotos(files);
    await loadData({
      dispatch: dispatch,
      apiCall: () =>
        otherDataAPI.rightPanel.violations.insertPhotos(formData, violationId),
      successAction: (data) => setNewViolationPhotos(data),
      loadingAction: (status) =>
        setRightPanelAttr({rightPanelDataLoading: status}),
      successSnackText: "Фотографии сохранены",
    });
  };
};

export const deleteViolationPhotoThunk = (photoId, violationId) => {
  return async (dispatch) => {
    await deletePhoto({
      dispatch: dispatch,
      apiCall: () =>
        otherDataAPI.rightPanel.violations.deletePhotoById(
          {id: photoId},
          violationId
        ),
      successAction: () => removeViolationPhoto(photoId),
      successSnackText: "Фотография удалена",
    });
  };
};

export const getWarehouseDataThunk = (id) => {
  return async (dispatch) => {
    try {
      let res = await otherDataAPI.rightPanel.warehouse.getById(id)
      dispatch(setRightPanelAttr({shapeData: res.data}))
    } catch (e) {
      handleErrors(dispatch, e)
    }
  }
}

export const insertWarehouseDescriptionThunk = (text, id) => {
  return async (dispatch) => {
    try {
      await otherDataAPI.rightPanel.warehouse.insertDescription(text, id)
      dispatch(setSnack('success', 'Комментарий сохранен'))
    } catch (e) {
      handleErrors(dispatch, e)
    }
  }
}
