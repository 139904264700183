//Сейчас используется только main. Blind для шторки, что будет реализовано позже.
const defaultState = {
  main: {
    all: true,
    rentBorders: true,
    roads: true,
    pillars: false,
    warehouses: true,
    cas: true,
    casStatutes: null,
    casStatutesCollapsed: true,
    archived_cas: false,
    stockpiles: true,
    violations: true,
  },
}

export function getInfrastructureViewReducer() {
  return window.store.getState().infrastructureViewReducer.main;
}

export function insrastructureViewChangeData(data, place) {
  return {
    type: 'MAP_INFRASTRUCTURE_SET_VISIBILITY',
    data: data,
    place: place,
  }
}

export function infrastructureViewReducer(state = defaultState, action) {
  switch (action.type) {
    case 'MAP_INFRASTRUCTURE_SET_VISIBILITY':
      return {...state, [action.place]: {...state[action.place], ...action.data}}
    default:
      return state;
  }
}
