import {RightPanelButton} from "./RightPanel/RightPanelButton";
import RightPanel from "../RightPanel/RightPanel";
import {setRightPanelAttr} from "../../../redux/reducers/rightPanelReducer";
import {useDispatch, useSelector} from "react-redux";
import './RightPanel/rp.css';

export function RightOverlayPane() {
  const rightPanelVisible = useSelector(state => state.rightPanelReducer.rightPanelVisible)
  const dispatch = useDispatch()

  function buttonClickHandler(event) {
    event.stopPropagation()
    event.preventDefault()
    dispatch(setRightPanelAttr({rightPanelVisible: !rightPanelVisible}))
  }

  return (
    <div className={'right-overlay-pane'}>
      <RightPanelButton onClick={buttonClickHandler}/>
      {rightPanelVisible && <RightPanel/>}
    </div>
  )
}
