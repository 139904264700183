import {Box} from "@mui/material";
import {useSelector} from "react-redux";
import {CAPropertiesWindow} from "../LeftPanel/Context/CAs/CAProperties/CAPropertiesWindow";
import {BottomPanel} from "./BottomPanel";
import {RightOverlayPane} from "./RightOverlayPane";
import {BaseLayersChooser} from "./BaseLayers/BaseLayersChooser";
import {MapSearchPanel} from "./Searching/MapSearchPanel";
import {TopLeftOverlayPane} from "./TopLeftOverlayPane";
import {TopRightOverlayPane} from "./TopRightOverlayPane";
import {LayerEditorPanel} from "../Map/Common/LayersEditor/LayerEditingPanel";

export const Overlay = () => {

  const panelStyle = {
    position: 'absolute',
    inset: 0,
    pointerEvents: 'none',
    overflow: 'hidden',
    zIndex: '999',
  }

  const curTab = useSelector(state => state.leftDrawerReducer.curTab)
  return (
    <Box id={'map1_overlay'} sx={panelStyle}>
      {curTab !== 'printing' &&
        <>
          <TopLeftOverlayPane />
          <TopRightOverlayPane />
          <MapSearchPanel />
          <BottomPanel/>
          <LayerEditorPanel/>
          <RightOverlayPane/>
          <CAPropertiesWindow/>
          <BaseLayersChooser/>
        </>
      }
    </Box>
  )
}
