//Выдела (Survey Plots), Квартала
/*export const defaultSPStyle = {
  fill: true,
  color: "grey",
  weight: 0.3,
  opacity: 0.5,
  fillColor: "transparent",
  fillOpacity: 0,
  pane: 'sps',
  snapIgnore: true,
}*/

//CA
export const prepOptionsX = {color: 'grey', fillColor: 'grey', weight: 2.5, opacity: 1, fillOpacity: 0.5}
export const declrOptions = {color: 'green', fillColor: 'green', weight: 2.5, opacity: 1, fillOpacity: 0.5}
export const inprOptionsX = {color: 'green', fillColor: 'green', weight: 2.5, opacity: 1, fillOpacity: 0.5}
export const inmovingOptionsX = {color: 'orange', fillColor: 'transparent', weight: 2.5, opacity: 1, fillOpacity: 0.2}
export const completeOptionsX = {color: 'green', fillColor: 'transparent', weight: 2.5, opacity: 1, fillOpacity: 0.5}
export const closedOptionsX = {color: 'grey', fillColor: 'transparent', weight: 2.5, opacity: 1, fillOpacity: 0}
export const archOptions = {color: '#bdbdbd', fillColor: 'white', weight: 2.5, opacity: 0.5, fillOpacity: 0.5}

//Stockpiles
//export const nSTPstyle = {color: 'orange', fillColor: 'white', fillOpacity: 0, weight: 2, pane: 'casstp'}
//export const hSTPstyle = {color: 'orange', fillColor: 'white', fillOpacity: 0.8, weight: 4, pane: 'casstp'}

//Overlay Pane
export const paneStyle = {
  backgroundColor: 'white',
  border: 'solid #999999 2px',
  width: 'fit-content',
  maxHeight: '80%',
  pointerEvents: 'auto',
  overflowY: 'hidden',
  display: 'flex',
  flexDirection: 'column',
}

export const headerStyle = {
  padding: '5px 10px 5px 10px',
  borderBottom: 'solid #AAAAAA 1px',
  cursor: 'move',
  display: 'flex',
  flexDirection: 'row',
  userSelect: 'none',
}

export const headerTextStyle = {
  flex: 1,
  marginLeft: '10px',
}

export const closeButtonStyle = {
  marginLeft: '10px',
  /*marginTop: '-2px',*/
  marginRight: 'auto',
  color: 'black',
  cursor: 'pointer',
}

export const bodyStyle = {
  overflowY: 'auto',
  padding: '5px',
}

export const overlayButtonStyle = {
  backgroundColor: 'white',
  border: '1px solid grey',
  borderRadius: '3px',
  pointerEvents: 'all',
  userSelect: 'none',
  height: 'fit-content',
  width: 'fit-content',
}

export const panelButtonStyle = {
  backgroundColor: 'white',
  border: 0,
  pointerEvents: 'all',
  userSelect: 'none',
}

/*export const unvisibleOverlayPanel = {
  backgroundColor: 'transparent',
  border: 0,
  position: 'absolute',
}*/

export const copyrightLink = {
  color: '#444444',
  fontSize: '70%',
  textDecoration: 'none',
  cursor: 'pointer',
  pointerEvents: 'auto',
  userSelect: 'none',
  paddingLeft: '0.5vw',
  marginBottom: '2px',
}

export const copyrightLink2 = {
  fontSize: '70%',
  textDecoration: 'none',
  cursor: 'pointer',
  pointerEvents: 'auto',
  userSelect: 'none',
  color: 'white',
  textShadow: 'grey 1px 1px ',
}

//Projects tree
export const fTextFieldStyle = {marginLeft: '5px'}
//export const selectedColor = '#E0E0E0';
//export const oddColor = '#f3f3f3';
export const defaultPaddingStep = 24;
export const treeItemButtonStyle = {height: defaultPaddingStep+'px', width: defaultPaddingStep+'px', padding: 0}
export const treeItemButtonIconSize = {height: (defaultPaddingStep-4)+'px', width: (defaultPaddingStep-4)+'px', fill: 'grey'} //4 - icon to button dimention delta
export const projectsTreeListItemStyle = {
  //alignItems: 'flex-start',
  width: '100%',
  padding: '3px 0px 3px 0px',
  margin: 0,
  cursor: 'pointer',
  userSelect: 'none',
}
export const nodeTypographyStyle = {
  padding: '0px 0px 0px 5px',
  width: '100%',
}
export const workspaceTypographyStyle = {
  ...nodeTypographyStyle,
  fontWeight: '600',
}
export const folderTypographyStyle = {
  ...nodeTypographyStyle,
}
export const shapeTypographyStyle = {
  ...nodeTypographyStyle,
}

//export const itemSelectionColor = 'yellow';

///Misc layers
export const miscLayersHeaderStyle = {
  fontWeight: 600,
}

export const postGraySpanStyle = {
  fontSize: '90%',
  color: 'grey',
  textTransform: 'lowercase',
}
