import {useDispatch, useSelector} from "react-redux";
import {Virtuoso} from "react-virtuoso";
import Box from "@mui/material/Box";
import {useEffect, useRef} from "react";
import {IconButton, ListItem, ListItemButton, Typography, useTheme} from "@mui/material";
import {projectsTreeListItemStyle, treeItemButtonIconSize, treeItemButtonStyle} from "../../../../Map/Common/Styles";
import {dispatch} from "../../../../../Common/misc_functions";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import SendToMobileOutlinedIcon from "@mui/icons-material/SendToMobileOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import {flyToPoint} from "../../../../Map/Common/fly";
import {loadRightPanelData} from "../../../../RightPanel/right_panel";
import {setSnack} from "../../../../Map/Common/Dialog/Snack/snackReducer";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import {closeMapPopup} from "../../../../Map/MapPopup/mapPopup";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import {FTextField} from "../../Projects/FTextFiled";
import {addPillarBlindLayer, getPillars, removePillarBlindLayer, savePillarOnServer} from "./pillars";
import {cannotIDo} from "../../../../Map/Common/tariffs";
import {ReactComponent as PillarIcon} from "../../../../Map/Common/svg/pillar1.svg";
import {changePillarsAttr, refreshPillarsTree} from "./pillarsReducer";
import {PillarContextMenu} from "./PillarContextMenu";

let scroll = null;
export function getScroll() {
  return scroll;
}

export function showHideAllPillars(workspaceID, visible, left= true) {
  const wpillars = getPillars().pillarsByWorkspaces[workspaceID]
  if (wpillars) {
    wpillars.data.map(pillar => {
      if (left) {
        wpillars.visible = visible;
        if (wpillars.visible) {//show left
          if (!wpillars.group.hasLayer(pillar)) {
            delete pillar.elz_hide;
            wpillars.group.addLayer(pillar)
          }
        } else {//hide left
          if (wpillars.group.hasLayer(pillar)) {
            pillar.elz_hide = true;
            wpillars.group.removeLayer(pillar)
          }
        }
      } else {
        wpillars.blind_visible = visible;
        if (wpillars.blind_visible) { //show right
          if (!pillar.elz_blind_layer)
            addPillarBlindLayer(pillar)
        } else { //hide right
          if (pillar.elz_blind_layer)
            removePillarBlindLayer(pillar)
        }
      }
    })
    dispatch(refreshPillarsTree())
  }
}

function WorkspaceRow({node, collapseNode, left}) {
  const selectedNode = useSelector(state => state.pillarsReducer.selectedNode)
  const iconColor = node.data.length === 0?'disabled':'primary';

  return (
    <ListItem
      disablePadding
      disableGutters
    >
      <ListItemButton
        disableRipple
        disableGutters
        style={{...projectsTreeListItemStyle}}
        onClick={() => {
          collapseNode(node)
          dispatch(changePillarsAttr({selectedNode: node}))
        }}
        selected={selectedNode === node}
      >
        <IconButton disableRipple style={treeItemButtonStyle} onClick={collapseNode}>
          {node.collapsed
            ?
            <ArrowDropDownIcon style={treeItemButtonIconSize}/>
            :
            <ArrowRightIcon style={treeItemButtonIconSize}/>
          }
        </IconButton>
        {node.is_mobile
          ? <SendToMobileOutlinedIcon fontSize={'small'} color={iconColor}/>
          : <PeopleAltOutlinedIcon fontSize={'small'} color={iconColor}/>
        }
        <IconButton disableRipple style={treeItemButtonStyle} onClick={(event) => {
          event.stopPropagation()
          showHideAllPillars(node.id, left ? !node.visible : !node.blind_visible, left)
        }}>
          {(left && node.visible) || (!left && node.blind_visible)
            ?
            <VisibilityOutlinedIcon color={'primary'} fontSize={'small'}
                                    style={{...treeItemButtonIconSize, fill: null}}/>
            :
            <VisibilityOffOutlinedIcon fontSize={'small'} style={treeItemButtonIconSize}/>
          }
        </IconButton>
        <Typography style={{paddingLeft: '5px', flex: 1, fontWeight: 600}}>{node.name || 'Без названия'}</Typography>
      </ListItemButton>
    </ListItem>
  )
}

function PillarRow ({layer, showHideLayer, readOnly, left}) {

  const theme = useTheme()
  const dispatch = useDispatch()
  const selectedNode = useSelector(state => state.pillarsReducer.selectedNode)

  return (
    <ListItem style={{paddingLeft: '24px'}} disablePadding>
      <ListItemButton
        disableGutters
        disableRipple
        sx={{...projectsTreeListItemStyle}}
        onClick={(e) => {
          e.stopPropagation()
          flyToPoint(layer.getLatLng(), true, 5)
          loadRightPanelData('Pillar', layer, layer.elz_properties.id, false)
          dispatch(changePillarsAttr({selectedNode: layer}))
        }}
        selected={selectedNode === layer}
      >
        <PillarIcon style={{...treeItemButtonIconSize, fill: theme.palette.warning.main}}/>
        <IconButton disableRipple style={treeItemButtonStyle} onClick={(event) => {
          event.stopPropagation()
          if (!layer.elz_editlayer) {
            showHideLayer(layer, layer.parentNode)
          } else {
            dispatch(setSnack('info', 'Сначала завершите редактирование столба.'))
          }
        }}>
          {(left && !layer.elz_hide) || (!left && layer.elz_blind_layer)
            ?
            <VisibilityOutlinedIcon color={'primary'} fontSize={'small'}
                                    style={{...treeItemButtonIconSize, fill: null}}/>
            :
            <VisibilityOffOutlinedIcon fontSize={'small'} style={treeItemButtonIconSize}/>}
        </IconButton>
        {!layer.elz_rename
          ?
          <>
            <Typography sx={{paddingLeft: '5px', flex: 1}}>
              {layer.elz_properties.name}
            </Typography>
            {!readOnly &&
              <>
                {layer.elz_saving
                  ?
                  <IconButton
                    disabled={!layer.elz_saving_error}
                    color={layer.elz_saving_error ? 'error' : 'disabled'}
                    style={{...treeItemButtonStyle, padding: 0}}
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      dispatch(refreshPillarsTree())
                      savePillarOnServer(layer)
                    }}
                  >
                    <SaveOutlinedIcon fontSize={'small'}/>
                  </IconButton>
                  :
                  !layer.elz_editlayer
                    ?
                    <PillarContextMenu layer={layer}/>
                    :
                    <IconButton
                      style={{...treeItemButtonStyle, padding: 0}}
                      size={'small'}
                      color={'primary'}
                      title={'Завершить редактирование'}
                      onClick={(event) => {
                        event.stopPropagation()
                        closeMapPopup()
                        stopEditing(layer.elz_editlayer)
                      }}
                    >
                      <DoneOutlinedIcon fontSize={'small'}/>
                    </IconButton>
                }
              </>
            }
          </>
          :
          <FTextField
            _dispatch={dispatch}
            _endEditing={(value) => {
              if (layer.elz_properties.name !== value) {
                layer.elz_properties.name = value;
                savePillarOnServer(layer)
              } else {
                delete layer.elz_rename;
                dispatch(refreshPillarsTree())
              }
              //убираем фокус после нажатия enter, чтобы не оставался фон
              document.activeElement.blur()
            }}
            _cancelEdit={() => {
              delete layer.elz_rename;
              dispatch(refreshPillarsTree())
            }}
            value={layer.elz_properties.name}
            style={{paddingLeft: '5px'}}
          />
        }
      </ListItemButton>
    </ListItem>
  )
}

function showHideLayer(layer) {
  const left = window.store.getState()["blindReducer"].left;
  if (left) {
    const group = layer.elz_parent_node.group;
    if (group.hasLayer(layer)) {
      layer.elz_hide = true;
      group.removeLayer(layer)
    } else {
      delete layer.elz_hide;
      group.addLayer(layer)
    }
  } else {
    if (layer.elz_blind_layer) {
      removePillarBlindLayer(layer, layer.elz_parent_node)
      layer.elz_blind_layer = null;
    } else {
      addPillarBlindLayer(layer, layer.elz_parent_node)
    }
  }
  const dispatch = window.elz_dispatch;
  dispatch(refreshPillarsTree())
}

export function collapsePillarsNode(node) {
  const reactArray = window.store.getState()["pillarsReducer"]["reactArray"];
  const ind = reactArray.findIndex(wp => wp === node)
  if (ind !== -1) {
    node.collapsed = !node.collapsed;
    if (node.collapsed) {
      const arr = node.data.map(layer => layer)
      reactArray.splice(ind + 1, 0, ...arr)
    } else {
      let end = ind + 1;
      while (end < reactArray.length && !reactArray[end].type) {
        end++;
      }
      end--;
      reactArray.splice(ind + 1, end - ind)
    }
  }
}

export function PillarsTree() {
  const reactArray = useSelector(state => state.pillarsReducer.reactArray)
  const virtuoso = useRef(null)
  const left = useSelector(state => state.blindReducer.left)
  const readOnly = !cannotIDo.editPillars()

  useEffect(() => {
    if (virtuoso.current) {
      scroll = virtuoso.current.scrollToIndex;
    }
    return () => {scroll = null}
  })

  const Item = (item) => {
    switch (reactArray[item].type) {
      case "pillars_group":
        return <WorkspaceRow key={item} node={reactArray[item]} collapseNode={collapsePillarsNode} left={left}/>
      default:
        return <PillarRow key={item} layer={reactArray[item]} showHideLayer={showHideLayer} readOnly={readOnly} left={left}/>
    }
  }

  return (
      <Box sx={{position: 'absolute', inset: 0, overflow: 'auto', paddingTop: '1vh'}}>
        <Virtuoso
          ref={virtuoso}
          totalCount={reactArray.length}
          itemContent={(index) => Item(index)}
          //skipAnimationFrameInResizeObserver
        />
        {/*<div style={scrollDivStyle}>
            {reactArray.map((item, index) => Item(index))}
        </div>*/}
      </Box>
  )
}
