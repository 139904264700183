import {Box, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {cellStyle, headerCellStyle, headerCellTextStyle, tableContainerStyle, tableStyle} from "../rightPanelStyles";
import {parameterString, valueString} from "../../Map/Common/Strings_RU";

export function PlotAddData({data}) {

  return (
    <Box style={tableContainerStyle} sx={{pt: '1vh'}}>
      <Table style={tableStyle}>
        <TableHead>
          <TableRow>
            <TableCell style={headerCellStyle}>
              <div style={headerCellTextStyle}>{parameterString}</div>
            </TableCell>
            <TableCell style={headerCellStyle}>
              <div style={headerCellTextStyle}>{valueString}</div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(data).map(([key, value]) => {
            if (value) {
              return (
                <TableRow key={key}>
                  <TableCell style={cellStyle}>{key}</TableCell>
                  <TableCell style={cellStyle}>{value}</TableCell>
                </TableRow>
              )
            } else {
              return null;
            }
          })}
        </TableBody>
      </Table>
    </Box>
  )
}
