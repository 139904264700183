//Всю эту дичь нужно заменить едиными воркспейсными нодами (или нет? или для списков оставить разделенными?)
import {addRoadsWorkspace, deleteRoadsWorkSpaceComplete, renameRoadsWorkspace} from "../Roads/roads";
import {
  addWorkspaceNodeIfNeeded,
  deleteRentPrimeters,
  deleteTreeNode,
  getWorkspaceNodeByID,
  renameWorkspaceByID
} from "../Projects/ProjectsCommon";
import {deleteAllCAByRentalContractID, renameCAsWorkspace} from "../CAs/CuttingsAreas";
import {
  addPillarWorkspace,
  deleteAllPillarsByWorkspaceID,
  renamePillarWorkspace
} from "../Infrastructure/Pillars/pillars";
import {deletingSuccess} from "../../../Map/Common/Strings_RU";
import {dispatch} from "../../../../Common/misc_functions";
import {dataAPI} from "../../../../../api/api";
import {setSnack} from "../../../Map/Common/Dialog/Snack/snackReducer";

/**
 * Удаляет воркспейс везде, в проекте. Не трогает сервер.
 * @param id {number} - id воркспейса
 */
export function deleteWorkspaceNotServer(id) {
  //Roads
  deleteRoadsWorkSpaceComplete(id)
  //Pillars
  deleteAllPillarsByWorkspaceID(id)
  //CAs
  deleteAllCAByRentalContractID(id)
  //Projects:
  const node = getWorkspaceNodeByID(id)
  if (node) {
    deleteTreeNode(node)
    //dispatch(refreshProjects())
  }
  //rent borders:
  deleteRentPrimeters(node, false, true)
  deleteRentPrimeters(node, true, false)
}

/**
 * Удаляет все упоминания воркспейса, кроме ЛК.
 * @param workspaceID - id воркспейса
 */
export async function deleteWorkspaceByID(workspaceID) {
  await dataAPI.workspaces.delete(workspaceID).then(() => {
    deleteWorkspaceNotServer(workspaceID)
    dispatch(setSnack('info', deletingSuccess))
  })
}

/**
 * Создает воркспейс везде.
 * @param feature {object} - фича воркспейса с бекенда
 */
export function addWorkspaceEverywere(feature) {
  addWorkspaceNodeIfNeeded(feature.id, feature.number, false) //projects
  addRoadsWorkspace(feature) //roads
  addPillarWorkspace(feature) //pillars
}

/**
 * Переименовывает воркспейс везде
 * @param feature {object} - фича воркспейса с бекенда
 */
export function renameWorkspaceEverywere(feature) {
  renameWorkspaceByID(feature.id, feature.number) //projects
  renameRoadsWorkspace(feature) //roads
  renamePillarWorkspace(feature) //pillars
  renameCAsWorkspace(feature)
}
