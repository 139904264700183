import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Alert, Switch} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import ReportImage from "./ReportImage/ReportImage";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  createReportUseForestThunk,
  getReportUseForestImageThunk,
  resetForestUseReportState,
  setForestUseReportAttr
} from "../../../redux/reducers/forestUseReportReducer";
import ModalPage from "../../Common/ModalPage";
import {ReportFields} from "./ReportFields/ReportFields";

const ForestUseReportModal = () => {
  const forestUseReportState = useSelector(state => state.forestUseReportReducer)
  const {reportData, quarterPx, quarterNumber, quarterCenterPoint, plotPx, drawMode, sendReportUseForestLoading,
    imageUrl, reportFormsValues
  } = forestUseReportState;
  const organizationName = useSelector(state => state.organizationInfoReducer.organizationData?.name) || '';
  const dispatch = useDispatch()
  const [imageLoaded, setImageLoaded] = useState(false)
  const [containerSize, setContainerSize] = useState({width: null, height: null})
  const [reportParameters, setReportParameters] = useState({
    owner: '',
    region: '',
    forestry: '',
    district_forestry: '',
    unique_location: '',
    quarter_number: '',
    allotments: '',
    d_plot_number: '',
    d_plot_square: ''
  })

  useEffect(() => {
    const containerRect = document.getElementById('photo-container')?.getBoundingClientRect()
    containerRect.width && setContainerSize({height: containerRect.height, width: containerRect.width})
  }, [])

  useEffect(() => {
    if (reportData.image_url) {
      setReportParameters({...reportFormsValues, owner: organizationName})
      !imageUrl && dispatch(getReportUseForestImageThunk(process.env.REACT_APP_SENTINEL_URL + reportData.image_url))
    }
  }, [reportData])

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const image = new Image();
  image.src = imageUrl;
  image.onload = handleImageLoad;

  const handleClose = () => {
    dispatch(resetForestUseReportState())
  }

  const actionButtonHandler = (reportType) => {
    dispatch(setForestUseReportAttr({reportFormsValues: reportParameters}))
    reportType === '1-ИЛ'
    ? dispatch(createReportUseForestThunk({
        ...reportParameters,
        quarters_px: [{qtr_px: [...quarterPx, quarterPx[0]], qtr_number: quarterNumber, center_px: quarterCenterPoint}],
        d_plot_px: [...plotPx, plotPx[0]],
        image_url: reportData.image_url,
        image_info_url: reportData.image_info_url,
        report_type: 'об использовании лесов, 1-ИЛ'
      })
    )
    : dispatch(createReportUseForestThunk({
        ...reportParameters,
        quarters_px: [{qtr_px: [...quarterPx, quarterPx[0]], qtr_number: quarterNumber, center_px: quarterCenterPoint}],
        d_plot_px: [...plotPx, plotPx[0]],
        image_url: reportData.image_url,
        image_info_url: reportData.image_info_url,
        report_type: 'по лесовосстановлению, 1-ВЛ'
      })
    )
  }

  return (
    <ModalPage
      title={`Создание отчетов 1-ИЛ, 1-ВЛ`}
      handleClose={handleClose}
      actionButtonHandler={actionButtonHandler}
      actionButtonName={'Создать'}
      actionButtonRemove={true}
    >
      <Stack direction={'row'}>
        <Box sx={{borderRight: 'solid 1px lightgrey', m: 1, display: 'flex', justifyContent: 'center', minWidth: '700px'}}
             id={'photo-container'}>
          {(imageLoaded && image)
            ?
            <ReportImage image={image} containerWidth={containerSize.width} containerHeight={containerSize.height}/>
            :
            forestUseReportState.loadingError
              ?
              <Alert sx={{m: 1, height: 'fit-content', width: '100%'}}
                     severity={'error'}>{forestUseReportState.loadingError}</Alert>
              :
              <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <CircularProgress sx={{mr: 1}}/>
                <Typography>
                  Загрузка снимка...
                </Typography>
              </Box>
          }
        </Box>
        <Box sx={{overflowY: 'auto', overflowX: 'hidden', minWidth: '300px'}}>
          <Box sx={{mb: 2, ml: 1, dispay: 'flex'}}>
            <FormControlLabel  control={
              <Switch
                size='small'
                checked={drawMode === 'drawQuarter'}
                label="Создать квартал"
                onChange={() => {dispatch(setForestUseReportAttr(
                  {drawMode: drawMode === 'drawQuarter' ? 'view' : 'drawQuarter'}
                ))}}/>} label={'Создать квартал'}/>
            <FormControlLabel  control={
              <Switch
                size='small'
                checked={drawMode === 'drawPlot'}
                label="Создать деляну"
                onChange={() => {
                  dispatch(setForestUseReportAttr(
                    {drawMode: drawMode === 'drawPlot' ? 'view' : 'drawPlot'}
                  ))}}/>} label={'Создать деляну'}/>
          </Box>
          <ReportFields reportParameters={reportParameters} setReportParameters={setReportParameters}/>
          {sendReportUseForestLoading ? (
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <CircularProgress/>
            </Box>
          ) : (
            <>
              <Button
                sx={{display: 'flex', marginRight: 'auto', mt: 1}}
                disabled={!imageLoaded}
                onClick={() => actionButtonHandler('1-ИЛ')}
              >Создать отчет ИЛ-1</Button>
              <Button
                sx={{display: 'flex', marginRight: 'auto', mt: 1}}
                disabled={!imageLoaded}
                onClick={() => actionButtonHandler('1-ВЛ')}
              >Создать отчет ВЛ-1</Button>
            </>

          )}
        </Box>
      </Stack>
    </ModalPage>
  );
};

export default ForestUseReportModal;
