import {dataAPI} from "../../../../../api/api";
import {getRoadPopup, prepareToOpenPopup} from "../../../Map/MapPopup/popupFunctions";
import {hideMapDialog, showMapDialog} from "../../../Map/Common/Dialog/DialogReducer";
import {finishPrevEditingFirst, roadsDeleting, roadsDeletingConfirmation} from "../../../Map/Common/Strings_RU";
import {setSnack} from "../../../Map/Common/Dialog/Snack/snackReducer";
import {setFeatureInfoEnabled} from "../../../Map/Common/getFeatureInfo";
import {getMap1} from "../../../Map/GlobalObjects";
import {closeMapPopup, showMapPopup} from "../../../Map/MapPopup/mapPopup";
import {loadRightPanelData} from "../../../RightPanel/right_panel";
import {resetRightPanelData} from "../../../../../redux/reducers/rightPanelReducer";
import {changeRoadsAttr, refreshRoadsTree} from "./roadsReducer";
import {handleErrors} from "../../../../../redux/commonReducerFunctions/ThunkErrorsHandler";
import {geoJSONdefaultPrecision} from "../../../Map/Common/Defaults";
import {collapseRoadNode, deleteRoadsWorkspaceFromReactArray, getScroll, updateReactWorkspace} from "./RoadsTree";
import {cannotIDo} from "../../../Map/Common/tariffs";
import {dispatch} from "../../../../Common/misc_functions";
import {showCoordinatesEditor} from "../../../Map/Common/Dialog/CoordinatesEditor/coordinatesEditorReducer";
import {clearCoordinatesDialogInfo} from "../../../Map/Common/Dialog/CoordinatesEditor/CoordinatesEditor";
import {resetMapHighlightedItem} from "../../../Map/ELZMapCP";
import {layerEditor} from "../../../Map/Common/LayersEditor/LayerEditor";
import {compareLatLngsArrays} from "../../../Map/Common/geoFunctions";
import {getLayerEditor} from "../../../Map/Common/LayersEditor/layerEditorReducer";

const roadsTemplate = {
  roadsByWorkspaces: {}, //object of wRoadTemplate
}
const wRoadTemplate = { //нода дорог в одном воркспейсе
  name: '',
  is_mobile: false,
  data: [],
  collapsed: false,
  visible: true,
  blind_visible: true,
  type: 'road_group',
  group: null,
  blindGroup: null,
}
export const roadsStyle = {
  weight: 3,
  color: '#808000',
  pane: 'roads'
}
export const activeRoadsStyle = {
  weight: 3,
  color: 'red',
  pane: 'roads'
}
export const selectedRoadStyle = {
  color: 'yellow',
  //weight: 5,
}

let roads = null;

/**
 * Возвращает объект дорог.
 * @returns {*} - объект дорог (roads)
 */
export function getRoads() {
  return roads;
}

/**
 * Удаляет все дороги из вокспейса. И сам воркспейс. В том числе из reactArray.
 * @param workspaceID
 */

/*export function deleteRoadsForWorkspaceID(workspaceID) {
  if (roads) {
    const workspace = roads.roadsByWorkspaces[workspaceID];
    const map = getMap1()
    if (workspace) {
      workspace.data.map(road => {
        if (road.elz_properties.rental_contract.toString() === workspaceID.toString()) {
          road.off()
          workspace.group.removeLayer(road)
          removeRoadBlindLayer(road, workspaceID)
        }
      })
      map.elz_zoom.removeLayer(workspace.group)
    }
    delete roads.roadsByWorkspaces[workspaceID]
    deleteRoadsWorkspaceFromReactArray(workspaceID)
  }
}*/

/**
 * Удаляет дорогу на карте и сервере
 * @param layer - слой дороги
 */
export function deleteRoadFromWorkspaceWithDialog(layer) {
  const dispatch = window.elz_dispatch;
  const workspaceID = layer.elz_parent_node.id;
  const workspace = roads.roadsByWorkspaces[workspaceID]
  const buttons = {'Да': {color: 'error'}, 'Нет': {color: 'success'}}
  dispatch(showMapDialog('warning', roadsDeleting, roadsDeletingConfirmation, buttons, (result) => {
    dispatch(hideMapDialog())
    if (result === 'Да') {
      let ind = workspace.data.findIndex(item => item === layer)
      if (ind > -1) {
        dataAPI.roads.delete(layer.elz_properties.id).then(res => {
          if (res.status === 204) {
            layer.elz_editor.delete()
            removeRoadBlindLayer(layer, workspaceID)
            workspace.group.removeLayer(layer)
            workspace.data.splice(ind, 1)
            //удалям из реакта
            const reactArray = window.store.getState()["roadsReducer"]["reactArray"]
            let reactInd = reactArray.findIndex(lay => lay === layer)
            if (reactInd !== -1) {
              reactArray.splice(reactInd, 1)
            }
            dispatch(refreshRoadsTree())
            dispatch(resetRightPanelData())
          }
        }).catch(err => {
          handleErrors(dispatch, err)
        })
      }
    }
  }))
}

/**
 * Открывает диалог колординат дороги. Сохраняет изменения.
 * @param layer {object} - слой leaflet
 */
export function roadCoordinatesDialog(layer) {
  dispatch(
    showCoordinatesEditor(
      layer.getLatLngs(),
      "Line",
      (latlngs) => {
        const old = layer.elz_editor.drawLayer()
        if (!compareLatLngsArrays(layer.getLatLngs(), old)) {
          layer.setLatLngs(latlngs)
          if (layer.elz_parent_layer) {
            layer.elz_parent_layer.setLatLngs(latlngs)
          } else {
            if (layer.elz_blind_layer) {
              layer.elz_blind_layer.setLatLngs(latlngs)
            }
          }
          saveRoadOnServer(layer)
        }
        clearCoordinatesDialogInfo()
      },
      layer.elz_properties.name
    )
  )
}

/**
 * Устанавлявает стиль основного и шторкового слоя
 * @param layer - основной слой
 * @param newStyle - стиль
 * @param save
 */
export function setRoadLayerStyle(layer, newStyle, save = false) {
  function changeLayer(layer, style) {
    if (save) {
      layer.elz_oldstyle = {...layer.options}
    } else {
      delete layer.elz_oldstyle;
    }
    layer.setStyle(style)
  }

  changeLayer(layer, newStyle)
  if (layer.elz_parent_layer) {
    changeLayer(layer.elz_parent_layer, newStyle)
  } else {
    if (layer.elz_blind_layer) {
      changeLayer(layer.elz_blind_layer, {...newStyle, pane: 'blind_roads'})
    }
  }
}

/**
 * Инициализаци редактирования на карте. Удаляет слои дорог, создает слой в редактрое.
 * @param layer - основной слой
 */
function startEditing(layer) {
  const mainLayer = layer.elz_parent_layer || layer;
  const workspace = roads.roadsByWorkspaces[mainLayer.elz_properties.rental_contract]

  if (!mainLayer.elz_hide) {
    workspace.group.removeLayer(mainLayer)
  }
  if (mainLayer.elz_blind_layer && !mainLayer.elz_blind_layer.elz_hide) {
    workspace.blindGroup.removeLayer(mainLayer.elz_blind_layer)
  }
  layerEditor({//запуск редактора
    layer: mainLayer,
    map: getMap1(),
    editModes: ['edit'],
    saveHandler: (editor) => {
      const newLatLngs = editor._getLayerLatLng(editor.drawLayer)
      const oldLatLngs = editor._getLayerLatLng(editor.ownLayer)
      stopEditing(mainLayer, newLatLngs)
      if (!compareLatLngsArrays(newLatLngs, oldLatLngs)) {
        saveRoadOnServer(mainLayer)
      }
    },
      cancelHandler: (editor) => {
        stopEditing(editor.ownLayer)
      },
    }
  )
  dispatch(refreshRoadsTree())
}

/**
 * Завершение редактирования на карте. Возвращает слои на место, удаляет слой из редактора.
 * @param layer {object} - leaflet слой (здесь всегда основной)
 * @param latlngs {object|undefined} - новые координаты (необязательно)
 */
export function stopEditing(layer, latlngs = null) {
  const workspace = roads.roadsByWorkspaces[layer.elz_properties.rental_contract]
  if (latlngs) {
    layer.setLatLngs(latlngs)
  }

  if (!layer.elz_hide) {
    workspace.group.addLayer(layer)
  }
  const blindLayer = layer.elz_blind_layer;
  if (blindLayer) {
    if (latlngs) {
      blindLayer.setLatLngs(latlngs)
    }
    if (!blindLayer.elz_hide) {
      workspace.blindGroup.addLayer(blindLayer)
    }
  }
  layer.elz_editor.delete()
  dispatch(refreshRoadsTree())
}

/**
 * Обработчик клика по дороге
 * @param event - leaflet event
 */
export function roadClickHandler(event) {
  const layer = event.target;
  if (!prepareToOpenPopup(event))
    return;
  setFeatureInfoEnabled(false)
  const interactive = {}
  interactive.road_visible_button = {
    type: 'click',
    f: () => {
      closeMapPopup()
      if (!layer.elz_parent_layer) {
        layer.elz_hide = true;
        roads.roadsByWorkspaces[layer.elz_properties.rental_contract].group.removeLayer(layer)
      } else {
        roads.roadsByWorkspaces[layer.elz_properties.rental_contract].blindGroup.removeLayer(layer)
        layer.elz_parent_layer.elz_blind_layer = null;
      }
      dispatch(refreshRoadsTree())
    },
  }
  if (!cannotIDo.editRoads()) {
    interactive.road_delete_button = {
      type: 'click',
      f: () => {
        closeMapPopup()
        deleteRoadFromWorkspaceWithDialog(layer.options.pane === 'roads' ? layer : layer.elz_parent_layer)
      }
    }
    interactive.road_edit_button = {
      type: 'click',
      f: () => { //editing
        closeMapPopup()
        if (!getLayerEditor()) {
          startEditing(layer)
        } else {
          dispatch(setSnack('warning', finishPrevEditingFirst))
        }
      }
    }
  }
  const roads = getRoads()
  const rpElement = window.store.getState()["rightPanelReducer"]["selectedElement"]

  if (!rpElement || rpElement.type !== 'Road' || rpElement.id !== layer.elz_properties.id) {
    loadRightPanelData('Road', layer, layer.elz_properties.id)
  }
  let listLayer = layer;
  if (layer.elz_parent_layer)
    listLayer = layer.elz_parent_layer;
  let reactArray = window.store.getState()["roadsReducer"]["reactArray"]
  let curPage = window.store.getState()["leftDrawerReducer"]["curTab"]
  if (reactArray.length && curPage === 'roads') {
    const workspaceID = listLayer.elz_parent_node.id;
    const wpIndex = reactArray.findIndex(item => item.id === workspaceID)
    if (wpIndex) {
      const wpNode = reactArray[wpIndex]
      if (!wpNode.collapsed) {
        collapseRoadNode(wpNode)
      }
      reactArray = window.store.getState()["roadsReducer"]["reactArray"]
      let ind = wpIndex + 1;
      while (ind < reactArray.length && listLayer !== reactArray[ind]) {
        ind++;
      }
      setTimeout(() => {
        const scroll = getScroll()
        if (scroll) {
          scroll({index: ind, align: 'center'})
        }
      }, 0)
    }
  }
  dispatch(changeRoadsAttr({selectedNode: listLayer}))
  const html = getRoadPopup(layer, interactive)
  showMapPopup(event.latlng, html, interactive)
}

/**
 * Создает ноду и слои (основной, шторка) по координатам и properties.
 * Добавляет созданные слои на карту.
 * @param coords {Array<L.LatLng>} - координаты слоя
 * @param properties {object} - свойства слоя
 * @param workspaceNode {object} - нода воркспейса
 * @returns {L.Polyline} - слой линии
 */
export function createRoadLine(coords, properties, workspaceNode) { //создает слой и добавляет в data
  const roadStatus = properties.status;
  const lay = L.polyline(coords, roadStatus === 'Идет вывозка' ? activeRoadsStyle : roadsStyle)
  lay.elz_properties = properties;
  lay.on('click', roadClickHandler)
  lay.elz_blind_layer = null;
  lay.elz_parent_node = workspaceNode;
  const blindEnabled = window.store.getState()["blindReducer"].enabled;
  if (blindEnabled)
    addRoadBlindLayer(lay)
  return lay;
}

/**
 * Создает новый полигон по слою.
 * @param road - слой
 * @param pane - панель
 * @returns L.Polyline
 */
export function cloneRoadPolyline(road, pane) {
  return L.polyline(road.getLatLngs(), {...road.options, pane: pane})
}

/**
 * Добавляет слой в ноду воркспейса. Не изменяет карту.
 *
 * @param workspaceId - id воркспейса
 * @param layer - слой
 * @returns {*} - нода
 */
export function addLayerToWorkspace(workspaceId, layer) {
  let wroads = roads.roadsByWorkspaces[workspaceId]
  wroads.data.unshift(layer)
  return wroads;
}

/**
 * Создает воркспейс, или возвращает уже существующий
 * @param workspaceId {number}
 * @param name {string}
 * @param is_mobile {boolean}
 * @param addToReact {boolean}
 * @returns {object}
 */
export function createRoadsWorkspaceIfNotExists(workspaceId, name, is_mobile, addToReact = false) {
  let wroads = roads.roadsByWorkspaces[workspaceId]
  if (!wroads) { //creating if not exists
    wroads = structuredClone(wRoadTemplate)
    wroads.id = workspaceId;
    wroads.name = name;
    wroads.is_mobile = is_mobile;
    wroads.group = L.layerGroup()
    wroads.blindGroup = L.layerGroup()
    roads.roadsByWorkspaces[workspaceId] = wroads;
    getMap1().elz_zoom.addLayer(wroads.group, 10, 20)
    getMap1().elz_zoom.addLayer(wroads.blindGroup, 10, 20)
    //Добавляем новый воркспейс в массив реакта, если нужно
    if (addToReact) {
      const arr = window.store.getState()["roadsReducer"]["reactArray"];
      arr.push(wroads)
      const dispatch = window.elz_dispatch;
      dispatch(refreshRoadsTree())
    }
  }
  return wroads;
}

/**
 * Начальная загрузка дорог с сервера
 * @returns {Promise<void>}
 */
export async function loadRoads() {
  if (!roads)
    roads = structuredClone(roadsTemplate)
  dispatch(changeRoadsAttr({loading: true, loaded: false, loadingError: false, reactArray: []}))
  await dataAPI.roads.getAll().then(res => {
    if (roads) {
      const arr = res.data.map(workspace => {
        const workspaceNode = createRoadsWorkspaceIfNotExists(workspace.id, workspace.name, workspace.is_mobile)
        workspace.data.forEach(feature => {
          const coords = feature.geometry.coordinates;
          if (Array.isArray(coords[0])) {
            const coordinates = coords[0].map(point => [point[1], point[0]])
            const lay = createRoadLine(coordinates,
              {...feature.properties, rental_contract: workspace.id}, workspaceNode)
            workspaceNode.data.push(lay)
            workspaceNode.group.addLayer(lay)
          }
        })
        return workspaceNode;
      })
      dispatch(changeRoadsAttr({loading: false, loaded: true, loadingError: false, reactArray: arr}))
    }
  }).catch(err => {
    console.error(err)
    dispatch(changeRoadsAttr({loading: false, loaded: false, loadingError: true, reactArray: []}))
  })
}

/**
 * Удаляет все дороги и ноды
 */
export function resetRoads() {
  const map = getMap1()
  removeAllRoadsBlindLayers()
  Object.values(roads.roadsByWorkspaces).map((value) => {
    value.data.map(road => {
      road.off('click')
      value.group.removeLayer(road)
    })
    map.elz_zoom.removeLayer(value.group)
    map.elz_zoom.removeLayer(value.blindGroup)
  })
  roads = null;
}

//Функции для шторки
/**
 * Создает и добавляет на карту в шторку слой дороги по основному слою
 * @param layer - основной слой (leaflet)
 */
export function addRoadBlindLayer(layer) {
  const workspaceNode = layer.elz_parent_node;
  if (workspaceNode) {
    const blind_lay = L.polyline(layer.getLatLngs(), {...layer.options, pane: 'blind_roads'})
    blind_lay.elz_properties = layer.elz_properties;
    blind_lay.on('click', roadClickHandler)
    layer.elz_blind_layer = blind_lay;
    blind_lay.elz_parent_layer = layer;
    if (!layer.elz_editor)
      workspaceNode.blindGroup.addLayer(blind_lay)
  }
}

/**
 * Удаляет слой из шторки карты по основному слою
 * @param layer - основной слой (leaflet)
 */
export function removeRoadBlindLayer(layer) {
  const blind_lay = layer.elz_blind_layer;
  const workspaceNode = layer.elz_parent_node;
  if (blind_lay) {
    workspaceNode.blindGroup.removeLayer(blind_lay)
    blind_lay.off('click')
    layer.elz_blind_layer = null;
  }
}

/**
 * Удаляет все слои всех воркспейсов из шторки карты
 */
export function removeAllRoadsBlindLayers() {
  Object.values(roads.roadsByWorkspaces).map(workspace => {
    workspace.data.map(layer => {
      removeRoadBlindLayer(layer, workspace)
    })
  })
}

/**
 * Добавляет все слои всех вокспесов на карту в шторку
 */
export function addAllRoadsBlindLayers() {
  Object.values(roads.roadsByWorkspaces).map(workspace => {
    workspace.data.map(layer => {
      addRoadBlindLayer(layer)
    })
  })
}

/**
 * Ищет дорогу по id во всех воркспейсах
 * @param id
 * @returns {null}
 */
export function searchRoadByID(id) {
  let result = null;
  Object.values(roads.roadsByWorkspaces).forEach(workspace => {
    const item = workspace.data.find(layer => layer.elz_properties.id === id)
    if (item)
      result = item;
  })
  return result;
}

/**
 * Перекрашивает дорогу по статусу. Асинхронная.
 * @param id - id дороги
 * @param roadStatus - новый статус
 * @returns {Promise<void>}
 */
export async function changeRoadStatusByID(id, roadStatus) {
  const layer = searchRoadByID(id)
  const newStyle = roadStatus === 'Идет вывозка' ? activeRoadsStyle : roadsStyle;
  if (layer) {
    resetMapHighlightedItem()
    setRoadLayerStyle(layer, newStyle)
  }
}

/**
 * Создает объект для отправки на сервер по слою
 * @param layer - слой leaflet
 * @returns {{rental_contract, name, geometry: *}}
 */
export function getLayerJSON(layer) {
  const geoma = structuredClone(layer.toGeoJSON(geoJSONdefaultPrecision).geometry)
  geoma.type = 'MultiLineString';
  geoma.coordinates = [[...geoma.coordinates]]

  return {
    name: layer.elz_properties.name,
    rental_contract: layer.elz_properties.rental_contract,
    geometry: geoma,
  }
}

/**
 * Сохраняет изменения дороги на сервере, включая её создание
 * @param layer - созраняемы слой
 */
export function saveRoadOnServer(layer) {
  const dispatch = window.elz_dispatch;

  async function save(layer) {
    const json = getLayerJSON(layer)
    delete layer.elz_saving_error;
    layer.elz_saving = true;
    if (!layer.elz_properties.id) { //создание
      return dataAPI.roads.create(json)
    } else { //изменение
      return dataAPI.roads.patch(layer.elz_properties.id, json)
    }
  }

  layer.elz_saving = true;
  dispatch(refreshRoadsTree())
  save(layer).then(res => {
    delete layer.elz_saving;
    delete layer.elz_saving_error;
    layer.elz_properties.id = res.data.properties.id; //необходмо при создании
    loadRightPanelData('Road', layer, layer.elz_properties.id)
    dispatch(refreshRoadsTree())
    dispatch(setSnack('success', 'Изменения сохранены'))
  }).catch(err => {
    layer.elz_saving_error = true;
    dispatch(refreshRoadsTree())
    handleErrors(dispatch, err)
  })
}

/**
 * Создает дорогу на карте и сервере
 * @param shapeLayer - слой leaflet без доп парамеров (из рисовалки)
 * @param workspaceNode - нода воркспейса, куда ложить дорогу из getRoads()
 */
export function createRoadLayer(shapeLayer, workspaceNode) {
  const properties = {
    name: `Дорога №${shapeLayer._leaflet_id}`,
    status: 'Готова к вывозке',
    rental_contract: workspaceNode.id,
  }
  const lay = createRoadLine(shapeLayer.getLatLngs(), properties, workspaceNode)
  const ws = addLayerToWorkspace(workspaceNode.id, lay)

  ws.group.addLayer(lay)
  updateReactWorkspace(workspaceNode)
  dispatch(changeRoadsAttr({selectedNode: lay}))
  saveRoadOnServer(lay)
}

export function resetRoadStyle(layer) {
  if (layer.elz_oldstyle)
    setRoadLayerStyle(layer, layer.elz_oldstyle)
  delete layer.elz_oldstyle;
}

export function addRoadsWorkspace(feature) {
  if (roads?.roadsByWorkspaces) {
    const reactArray = window.store.getState().roadsReducer["reactArray"]
    const workspaceNode = createRoadsWorkspaceIfNotExists(feature.id, feature.number, feature.is_mobile)
    reactArray.push(workspaceNode)
  }
}

export function renameRoadsWorkspace(feature) {
  if (roads) {
    const workspace = roads.roadsByWorkspaces[feature.id]
    if (workspace) {
      workspace.name = feature.number;
    }
    dispatch(refreshRoadsTree())
  }
}

/**
 * Полностью удаляет воркспейс и все его дороги. Не трогает сервер.
 * @param workspaceID
 */
export function deleteRoadsWorkSpaceComplete(workspaceID) {
  const workspace = roads?.roadsByWorkspaces[workspaceID]
  if (workspace) {
    console.log(workspace)
    workspace.group.clearLayers()
    workspace.blindGroup.clearLayers()
    deleteRoadsWorkspaceFromReactArray(workspaceID)
    delete roads.roadsByWorkspaces[workspaceID]
  }
}

