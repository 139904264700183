import {TitleCommonBlock} from "../Common/TitleCommonBlock";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Harvesting from "./ORSections/Harvesting/Harvesting";
import Removal from "./ORSections/Removal/Removal";
import Typography from "@mui/material/Typography";
import MachinesModal from "./MachinesModal/MachinesModal";
import {Modal} from "@mui/material";
import {useSelector} from "react-redux";
import {customTabPanel, customTabPanelContainer, mainContainer, tabTitle} from "./OperationalReportStyles";
import {forwardRef, useState} from "react";
import {cannotIDo} from "../Main/Map/Common/tariffs";
import {urlGenerator} from "../../utils/urlGenerator";

function CustomTabPanel({ children, value, index, ...other }) {

  return (
    <div style={customTabPanelContainer}
         role="tabpanel"
         hidden={value !== index}
         id={`operational-report-${index}`}
         {...other}
    >
      {value === index && (
        <Box sx={{ p: 3}} >
          <Typography component={"div"} sx={customTabPanel}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

const TabProps = (index) => ({id: `tab-${index}`,})

const OperationalReport = () => {
  const [value, setValue] = useState(0)
  const TransitionMachinesModal = forwardRef(() => <MachinesModal/>)
  const showMachinesModal = useSelector(state => state.operationalReportReducer.showMachinesModal)
  const iAmEditor = !cannotIDo.writeORModule() && !cannotIDo.editorAction()
  useSelector(state => state.userReducer) //для обновления при нажатии F5

  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  function goToBack(navigate) {
    navigate(urlGenerator().main)
  }

  if (cannotIDo.useORModule())
    return null;

  return (
    <div style={{height: '100%'}}>
      <TitleCommonBlock title={'Оперативный учет'} closeButtonHandler={goToBack}/>
      <Box sx={tabTitle}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Заготовка" {...TabProps(0)} />
          <Tab label="Вывозка" {...TabProps(1)} />
        </Tabs>
      </Box>
      <Box sx={mainContainer}>
        <CustomTabPanel value={value} index={0}>
          <Harvesting iAmEditor={iAmEditor}/>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Removal />
        </CustomTabPanel>
        <Modal open={showMachinesModal.status}>
          <TransitionMachinesModal/>
        </Modal>
      </Box>
    </div>
  )
}

export default OperationalReport;
