import {useSelector} from "react-redux";
import RPShapeData from "./RPShapeData/RPShapeData";
import Head from "./Head/Head";
import {Alert, Box, Stack, Typography, useTheme} from "@mui/material";
import RpPillarData from "./RPPillarData/RPPillarData";
import RpRoadData from "./RPRoadData/RPRoadData";
import RpPlotData from "./RPPlotData/RPPlotData";
import RpViolationData from "./RPViolationData/RPViolationData";
import RPWarehouseData from "./RPWarehouseData/RPWarehouseData";
import {isShapeNode} from "../LeftPanel/Context/Projects/ProjectsCommon";
import {Loading} from "../Map/Common/Loading";

export default function RightPanel() {
  const rightPanelVisible = useSelector(state => state.rightPanelReducer.rightPanelVisible)
  const selectedElement = useSelector(state => state.rightPanelReducer.selectedElement) || {}
  const loading = useSelector(state => state.rightPanelReducer.rightPanelDataLoading)
  const loadingError = useSelector(state => state.rightPanelReducer.rightPanelDataLoadingError)
  const name = selectedElement.name || 'Информация';
  const type = selectedElement?.type;
  const theme = useTheme()

  const panelStyle = {
    height: '100%',
    right: 0,
    width: '25vw',
    borderLeft: `${theme.palette.primary.main} 1px solid`,
    backgroundColor: 'white',
    pointerEvents: 'all',
    padding: '1vh',
    boxSizing: 'border-box',
    position: 'relative',
  }

  if (!rightPanelVisible) return '';

  return (
    <Stack direction={'column'} sx={panelStyle}>
      <Head name={name}/>
      <Box style={{flex: 1, position: 'relative'}} id={'lalal'}>
        <Box style={{position: 'absolute', inset: 0, overflowY: 'auto'}}>
          {loading
            ?
            <Loading/>
            :
            <>
              {loadingError
                ?
                <Alert severity={'error'} sx={{width: '90%'}}>
                  <Typography variant={'body2'}>Произошла ошибка, при загрузке информации. Попробуйте еще
                    раз.</Typography>
                </Alert>
                :
                <>
                  {isShapeNode(selectedElement) && <RPShapeData/>}
                  {type === 'Pillar' && <RpPillarData pillarId={selectedElement.id}/>}
                  {type === 'Road' && <RpRoadData/>}
                  {type === 'Plot' && <RpPlotData/>}
                  {type === 'Violation' && <RpViolationData violationId={selectedElement.id}/>}
                  {type === 'Warehouse' && <RPWarehouseData warehouseId={selectedElement.id}/>}
                  {!type &&
                    <Alert severity={'info'} sx={{width: '90%'}}>
                      <Typography variant={'body2'}>Выберите объект в левой панели или на карте.</Typography>
                    </Alert>
                  }
                </>
              }
            </>
          }
        </Box>
      </Box>
    </Stack>
  )
}
