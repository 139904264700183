import Box from "@mui/material/Box";
import {overlayButtonStyle} from "../../Map/Common/Styles";
import {Divider, IconButton, ListItemIcon, Menu, MenuItem} from "@mui/material";
import {useState} from "react";
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import {useDispatch, useSelector} from "react-redux";
import {insrastructureViewChangeData} from "./infrascructureViewReduces";
import {getProjects} from "../../LeftPanel/Context/Projects/ProjectsCommon";
import {getMap1} from "../../Map/GlobalObjects";
import {getRoads} from "../../LeftPanel/Context/Roads/roads";
import {getPillars} from "../../LeftPanel/Context/Infrastructure/Pillars/pillars";
import {getCuttingAreas, loadArchivedCuttingAreas} from "../../LeftPanel/Context/CAs/CuttingsAreas";
import {fireFilter, refresh} from "../../Map/Filters/FiltersReducer";
import {handleErrors} from "../../../../redux/commonReducerFunctions/ThunkErrorsHandler";
import {Loading} from "../../Map/Common/Loading";
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import {dispatch} from "../../../Common/misc_functions";
import {addCaStockPilesToMap, removeCaStockPilesFromMap} from "../../LeftPanel/Context/CAs/stockpiles";

/**
 * Элемент меню
 * @param visible {boolean} - true - глаз открыт, false - глаз закрыт, null - индикатор загрузки
 * @param text {string} - текст
 * @param value {number} - порядковый номер (идентификатор)
 * @param onClick {function} - обработчик клика
 * @returns {JSX.Element}
 */
function Item({visible, text, value, onClick}) {
  return (
    <>
      <ListItemIcon
        onClick={(event) => {
          event.stopPropagation()
          event.preventDefault()
          onClick(value)
        }}
      >
        {visible === null
          ?
          <Loading text={''}/>
          :
          visible
            ?
            <VisibilityOutlinedIcon size={'small'}/>
            :
            <VisibilityOffOutlinedIcon size={'small'}/>
        }
      </ListItemIcon>
      {text}
    </>
  )
}

function CASItem({visible, text, value, onClick, collapsed}) {
  return (
    <>
      <ListItemIcon
        onClick={(event) => {
          event.stopPropagation()
          event.preventDefault()
          onClick(value)
        }}
      >
        {visible
          ?
          <VisibilityOutlinedIcon size={'small'}/>
          :
          <VisibilityOffOutlinedIcon size={'small'}/>
        }
      </ListItemIcon>
      {text}
      <IconButton size={'small'} disableRipple>
        {collapsed
          ?
          <ArrowRightOutlinedIcon fontSize={'small'}/>
          :
          <ArrowDropDownOutlinedIcon fontSize={'small'}/>
        }
      </IconButton>
    </>
  )
}

function CasStatusesList() {
  const filtersList = getCuttingAreas().filters.status;
  const filters = useSelector(state => state.filterReducer.filters.ca.status)

  function onStatusClick(value) {
    let newFilters = [...filters]
    const filterName = filtersList[value]
    if (newFilters.length === 0) {
      newFilters = [...filtersList].filter(filter => filter !== filterName)
    } else {
      const index = newFilters.indexOf(filterName)
      if (index === -1) {
        newFilters.push(filterName)
      } else {
        newFilters.splice(index, 1)
      }
      /*if (newFilters.length === filtersList.length)
        newFilters = []*/
    }
    dispatch(fireFilter('status', newFilters))
  }

  return (
    <>
      <Divider/>
      {filtersList.map((name, index) => {
        let show = true;
        if (filters.length !== 0 && filters.indexOf(name) === -1) {
          show = false;
        }
        return (
          <MenuItem key={index} value={index} disableRipple onClick={() => onStatusClick(index)}
                    style={{paddingLeft: '32px'}}>
            <Item text={filtersList[index]} visible={show} value={index} onClick={onStatusClick}/>
          </MenuItem>
        )
      })}
      <Divider/>
    </>
  )
}

export function InfrastructureViewButton() {
  //const place = window.store.getState().blindReducer.left?'main':'blind';
  const place = 'main'; //TODO Шторка на данный момент отключена
  const [menuAnchor, setMenuAnchor] = useState(null)
  const state = useSelector(state => state.infrastructureViewReducer[place])
  const dispatch = useDispatch()
  const warehousesLayer = window.store.getState().warehousesReducer.layersGroup;
  const map = getMap1()
  const FILTER_NAME = 'global';
  const cas = getCuttingAreas()

  function buttonClickHandler(event) {
    setMenuAnchor(event.currentTarget)
  }

  function closeMenu(event) {
    event.stopPropagation()
    event.preventDefault()
    setMenuAnchor(null)
  }

  function showHideRentBorder(visible) {
    if (visible) {
      getProjects().data.forEach(workspace => {
        if (workspace.perimeter.layers)
          map.addLayer(workspace.perimeter.layers)
        if (workspace.perimeter.blindlayers)
          map.addLayer(workspace.perimeter.blindlayers)
      })
    } else {
      getProjects().data.forEach(workspace => {
        if (workspace.perimeter.layers)
          map.removeLayer(workspace.perimeter.layers)
        if (workspace.perimeter.blindlayers)
          map.removeLayer(workspace.perimeter.blindlayers)
      })
    }
  }

  function showHidePillars(visible) {
    if (visible) {
      Object.values(getPillars().pillarsByWorkspaces).forEach(wpillars => {
        map.addLayer(wpillars.group)
        map.addLayer(wpillars.blindGroup)
      })
    } else {
      Object.values(getPillars().pillarsByWorkspaces).forEach(wpillars => {
        map.removeLayer(wpillars.group)
        map.removeLayer(wpillars.blindGroup)
      })
    }
  }

  function showHideRoads(visible) {
    if (visible) {
      Object.values(getRoads().roadsByWorkspaces).forEach(roads => {
        map.elz_zoom.addLayer(roads.group)
        map.elz_zoom.addLayer(roads.blindGroup)
      })
    } else {
      Object.values(getRoads().roadsByWorkspaces).forEach(roads => {
        map.elz_zoom.removeLayer(roads.group)
        map.elz_zoom.removeLayer(roads.blindGroup)
      })
    }
  }

  function showHideWarehouses(visible) {
    if (visible) {
      map.addLayer(warehousesLayer)
    } else {
      map.removeLayer(warehousesLayer)
    }
  }

  function showHideCAs(visible) {
    if (!visible) {
      cas.layerArray.forEach(ca => {
        const ind = ca.elz_filter.indexOf(FILTER_NAME)
        if (ind === -1 && ca.elz_properties.status !== 'В архиве') {
          ca.elz_filter.push(FILTER_NAME)
        }
      })
    } else {
      cas.layerArray.forEach(ca => {
        const ind = ca.elz_filter.indexOf(FILTER_NAME)
        if (ind !== -1) {
          ca.elz_filter.splice(ind, 1)
        }
      })
    }
    dispatch(refresh())
  }

  function showHideViolations(visible) {
    const group = window.store.getState().violationsReducer.group;
    if (visible) {
      getMap1().addLayer(group)
    } else {
      getMap1().removeLayer(group)
    }
  }

  /**
   * Скрывает/показывает архивные деляны (везде)
   * Загружает их с бекенда, если не загружены
   * @param visible {boolean} - скрыть или показать деляны
   */
  function showHideArchivedCAs(visible) {

    if (state.archived_cas === visible)
      return;

    function showHide() {
      cas.layerArray.forEach(ca => {
        if (ca.elz_properties.status === 'В архиве') {
          if (visible)
            ca.elz_filter = []
          else
            ca.elz_filter.push('status')
        }
      })
      dispatch(refresh())
      dispatch(insrastructureViewChangeData({archived_cas: visible}, place))
    }

    if (cas.archCasLoaded) {
      showHide()
    } else {
      cas.archCasLoading = true;
      cas.archCasLoaded = false;
      dispatch(insrastructureViewChangeData({}, place)) //просто обновляем менюшку
      loadArchivedCuttingAreas().then(() => {
        cas.archCasLoading = false;
        cas.archCasLoaded = true;
        showHide()
      }).catch(err => {
        handleErrors(dispatch, err)
        cas.archCasLoading = cas.archCasLoaded = false;
        dispatch(insrastructureViewChangeData({archived_cas: false}, place))
      })
    }
  }

  function showHideStockpiles(visible) {
    if (visible) {
      cas.layerArray.forEach(ca => {
        addCaStockPilesToMap(ca)
      })
    } else {
      cas.layerArray.forEach(ca => {
        removeCaStockPilesFromMap(ca)
      })
    }
  }

  function onClick(event) {
    let value;
    if (typeof event !== 'number') {
      event.stopPropagation()
      event.preventDefault()
      value = event.target.value;
    } else {
      value = event;
    }

    switch (value) {
      case 0: //All
        const newState = !state.all;
        showHideRentBorder(newState)
        showHideRoads(newState)
        showHidePillars(newState)
        showHideWarehouses(newState)
        showHideCAs(newState)
        //showHideArchivedCAs(newState)
        showHideStockpiles(newState)
        showHideViolations(newState)
        dispatch(insrastructureViewChangeData({
          all: newState,
          rentBorders: newState,
          roads: newState,
          pillars: newState,
          warehouses: newState,
          cas: newState,
          violations: newState,
          stockpiles: newState,
          //archived_cas: newState,
        }, place))
        break;
      case 1: //rents borders|perimeters
        showHideRentBorder(!state.rentBorders)
        dispatch(insrastructureViewChangeData({rentBorders: !state.rentBorders}, place))
        break;
      case 2: //roads
        showHideRoads(!state.roads)
        dispatch(insrastructureViewChangeData({roads: !state.roads}, place))
        break;
      case 3: //pillars
        showHidePillars(!state.pillars)
        dispatch(insrastructureViewChangeData({pillars: !state.pillars}, place))
        break;
      case 4: //wirehouses
        showHideWarehouses(!state.warehouses)
        dispatch(insrastructureViewChangeData({warehouses: !state.warehouses}, place))
        break;
      case 5: //cas
        showHideCAs(!state.cas)
        dispatch(insrastructureViewChangeData({cas: !state.cas}, place))
        break;
      case 6: //arch cas
        if (!cas.archCasLoading) {
          showHideArchivedCAs(!state.archived_cas)
        }
        break;
      case 7: //stockpiles
        showHideStockpiles(!state.stockpiles)
        dispatch(insrastructureViewChangeData({stockpiles: !state.stockpiles}, place))
        break;
      case 8: //violations
        showHideViolations(!state.violations)
        dispatch(insrastructureViewChangeData({violations: !state.violations}, place))
        break;
    }
  }

  return (
    <>
      <Box style={overlayButtonStyle} id={'measuringButton'}>
        <IconButton
          disableRipple
          onClick={buttonClickHandler}
          style={{padding: 5}}
          title={'Отображение инфраструктуры'}
        >
          <BusinessOutlinedIcon fontSize={'small'}/>
        </IconButton>
      </Box>
      {Boolean(menuAnchor) &&
        <Menu open={true} anchorEl={menuAnchor} onClose={closeMenu}>
          <MenuItem value={0} disableRipple onClick={onClick}>
            <Item text={'Инфраструктура компании'} visible={state.all} value={0} onClick={onClick}/>
          </MenuItem>
          <Divider/>
          <MenuItem value={1} disableRipple onClick={onClick}>
            <Item text={'Границы аренды'} visible={state.rentBorders} value={1} onClick={onClick}/>
          </MenuItem>
          <MenuItem value={2} disableRipple onClick={onClick}>
            <Item text={'Дороги'} visible={state.roads} value={2} onClick={onClick}/>
          </MenuItem>
          <MenuItem value={3} disableRipple onClick={onClick}>
            <Item text={'Кв. столбы'} visible={state.pillars} value={3} onClick={onClick}/>
          </MenuItem>
          <MenuItem value={4} disableRipple onClick={onClick}>
            <Item text={'Склады'} visible={state.warehouses} value={4} onClick={onClick}/>
          </MenuItem>
          <MenuItem value={5} disableRipple onClick={() => {
            dispatch(insrastructureViewChangeData({casStatutesCollapsed: !state.casStatutesCollapsed}, 'main'))
          }}
          >
            <CASItem text={'Деляны'} visible={state.cas} value={5} onClick={onClick}
                     collapsed={state.casStatutesCollapsed}/>
          </MenuItem>
          {!state.casStatutesCollapsed && <CasStatusesList/>}
          <MenuItem value={7} disableRipple onClick={onClick}>
            <Item text={'Штабели'} visible={state.stockpiles} value={7} onClick={onClick}/>
          </MenuItem>
          <MenuItem value={8} disableRipple onClick={onClick}>
            <Item text={'Нарушения'} visible={state.violations} value={8} onClick={onClick}/>
          </MenuItem>
          <Divider/>
          <MenuItem value={6} disableRipple onClick={onClick}>
            <Item text={'Архивные деляны'} visible={cas.archCasLoading ? null : state.archived_cas} value={6}
                  onClick={onClick}/>
          </MenuItem>
        </Menu>
      }
    </>
  )
}
