import {useSelector} from "react-redux";
import {overlayButtonStyle} from "../Styles";
import {Divider, IconButton} from "@mui/material";
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import {cancelAllActions, dragString, editString, rotateString, saveChangeString, undoLastAction} from "../Strings_RU";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {dragSVG, editSVG, ReactSVG, rotateSVG} from "../svg/Svgs";

export function LayerEditorPanel() {
  const state = useSelector(state => state.layerEditingReducer)
  const editor = state.editor;

  if (!editor) {
    return null;
  }

  const history = editor.getHistory()
  const panelStyle = {
    position: 'absolute',
    bottom: '2vh',
    left: '50%',
    border: '1px solid gray',
    pointerEvents: 'all',
    transform: 'translateX(-50%)',
    background: 'white',
    display: 'flex',
  }

  function getButtonColor(mode) {
    if (state.editor.editMode !== mode) {
      return "primary";
    } else {
      return "info";
    }
  }

  return (
    <div style={{...overlayButtonStyle, ...panelStyle}}>
      {editor.editModes.indexOf('edit') !== -1 &&
        <IconButton
          disabled={editor.editMode === 'edit'}
          size={'large'}
          title={editString}
          onClick={() => editor.switchMode('edit')}
        >
          <ReactSVG color={getButtonColor('edit')} htmlString={editSVG()}/>
        </IconButton>
      }
      {editor.editModes.indexOf('rotate') !== -1 &&
        <IconButton
          disabled={editor.editMode === 'rotate'}
          size={'large'}
          title={rotateString}
          onClick={() => editor.switchMode('rotate')}
        >
          <ReactSVG color={getButtonColor('rotate')} htmlString={(rotateSVG())}/>
        </IconButton>
      }
      {editor.editModes.indexOf('move') !== -1 &&
        <IconButton
          disabled={editor.editMode === 'move'}
          size={'large'}
          title={dragString}
          onClick={() => editor.switchMode('move')}
        >
          <ReactSVG color={getButtonColor('move')} htmlString={(dragSVG())}/>
        </IconButton>
      }
      <Divider orientation={'vertical'} flexItem/>
      <IconButton
        size={'large'}
        onClick={() => editor.saveHandler(editor)}
        color={"warning"}
        title={saveChangeString}
      >
        <DoneOutlinedIcon/>
      </IconButton>
      {!editor.disableUndo &&
        <IconButton
          size={'large'}
          onClick={() => editor.undo()}
          color={"info"}
          disabled={!history.length}
          title={undoLastAction}
        >
          <UndoOutlinedIcon/>
        </IconButton>
      }
      {editor.cancelHandler &&
        <IconButton
          size={'large'}
          onClick={() => editor.cancelHandler(editor)}
          color={"error"}
          title={cancelAllActions}
        >
          <CloseOutlinedIcon/>
        </IconButton>
      }
    </div>
  )
}
