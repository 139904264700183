import {useEffect} from 'react';
import {Box, Stack, Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {getPlotsStockpilesThunk} from "../../../redux/reducers/stockpilesReducer";
import PlotsGrouping from "./PlotsGrouping/PlotsGrouping";
import PlotsStockpilesTree from "./PlotsStockpilesTree/PlotsStockpilesTree";
import PlotsList from "./PlotsStockpilesTree/PlotsStockpilesTreeItem/PlotsList/PlotsList";

function Leftbar({iAmEditor, borderString}) {
  const dispatch = useDispatch()
  const plotsStockpiles = useSelector(state => state.stockpilesReducer.plotsStockpiles)
  const plotsFilters = useSelector(state => state.stockpilesReducer.plotsFilters)
  const selectedFilters = Object.keys(plotsFilters).filter(filter => plotsFilters[filter].status)

  useEffect(() => {
    dispatch(getPlotsStockpilesThunk())
  }, [])

  const containerStyle = {
    height: '100%',
    paddingLeft: '1vh',
    paddingTop: '1vh',
    minWidth: '250px',
    width: '20%',
    borderTop: borderString,
    borderLeft: borderString,
    borderRight: borderString,
    boxSizing: 'border-box',
    position: 'relative',
  }

  return (
    <Box sx={containerStyle}>
      <Stack direction={'column'} style={{height: '100%'}}>
        <div style={{flex: 0}}>
          <Typography sx={{mb: 1, fontWeight: 500}}>Деляны</Typography>
          <PlotsGrouping/>
        </div>
        <div style={{flex: 1, position: 'relative'}}>
          <div style={{position: 'absolute', inset: 0, overflow: 'auto'}}>
            {selectedFilters.length
              ? <PlotsStockpilesTree itemsList={plotsStockpiles} fields={selectedFilters} iAmEditor={iAmEditor}/>
              : <PlotsList plotsList={plotsStockpiles} iAmEditor={iAmEditor}/>
            }
          </div>
        </div>
      </Stack>
    </Box>
  )
}

export default Leftbar;
